import React, {useEffect} from "react";
import { Link , Route, Redirect} from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../../actions";
import useCheckSessionApi from "../../SessionValidity";
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import PersonIcon from "@material-ui/icons/Person";
import ReceiptIcon from "@material-ui/icons/Receipt";
import DomainIcon from "@material-ui/icons//Domain";

const useStyles = makeStyles((theme) => ({
  cursorPointer: {
    cursor: "pointer"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    marginRight:"5%",
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  moreVertIcon:{
    textAlign: "right",
  },
  personIconColor: {
      color: 'blue',
  },
  userAddUpdateDelete: {
      marginTop: '4%',
      fontSize: 11,
      color: 'black',
      cursor: "pointer",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    height: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'4%',
    display:'block',
    overflowY:'scroll',
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'10%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
    width:'100%',
  },
  modalBodyBulk: {
    padding: theme.spacing(7, 4, 3,), 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  paperModalBulk: {
    position: 'absolute',
    width: 500,
    // height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  validationBulk: {
    marginTop: '2%',
    color: 'red',
    fontSize: 12,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },

}));


export default function Step(processListDropdown) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [stepDetails, setstepDetails] = React.useState({
        process_id: "",
        name: "",
        description: "",
        review: "",
        is_start: "",
        is_end: "",
        is_branch_step: "",
        no_of_branches: "",
        is_parallel: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
    });
    const [yesNo, setyesNo] = React.useState([
      { value: true , name: 'Yes'  },
      { value: false , name: 'No'  },
    ]);
    const [trueFalse, settrueFalse] = React.useState([
      { value: true , name: 'True'  },
      { value: false , name: 'False'  },
    ]);
    const [processIdErr, setprocessIdErr] = React.useState();
    const [nameErr, setnameErr] = React.useState();
    const [descriptionErr, setdescriptionErr] = React.useState();
    const [reviewErr, setreviewErr] = React.useState();
    const [startErr, setstartErr] = React.useState();
    const [endErr, setendErr] = React.useState();
    const [branchStepErr, setbranchStepErr] = React.useState();
    const [noOfBranchesErr, setnoOfBranchesErr] = React.useState();
    const [parallerErr, setparallerErr] = React.useState();

    const [openBulk, setopenBulk] = React.useState(false);
    const [ExcelFile, setExcelFile] = React.useState();
    const [ExcelName, setExcelName] = React.useState();
    const [fileErr, setfileErr] = React.useState();

    const [ ShowProcedure, setShowProcedure ] = React.useState(false);

    const [flag, setflag] = React.useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      setflag(true);
      // dispatch(userActions.checkSessionValidity());
      // readItemFromStorage();
    }, [flag, open, openBulk ]);
    
    // const readItemFromStorage = async() => {
    //   try {
    //     setDeviceToken(deviceTokenFromRedux)
    //     setSessionId(sessionIdFromRedux)
    //     setflag(false)    
    //   } catch (error) {
    //     console.log("ERROR:",error);        }
    // };

    const handelCreateStepOpen = () => {
        setOpen(true);
        dispatch(userActions.checkSessionValidity());
    }

    const handelCreateStepClose = () => {
        setOpen(false);
        setstepDetails({
          process_id: "",
          name: "",
          description: "",
          review: "",
          is_start: "",
          is_end: "",
          is_branch_step: "",
          no_of_branches: "",
          is_parallel: "",
          device_token: JSON.parse(localStorage.getItem('device_token')),
          session_id: JSON.parse(localStorage.getItem('session_id')),
        })
        setprocessIdErr();
        setnameErr();
        setdescriptionErr();
        setreviewErr();
        setstartErr();
        setendErr();
        setbranchStepErr();
        setnoOfBranchesErr();
        setparallerErr();
        dispatch(userActions.checkSessionValidity());
    }

    const handelCreateStepClear = async() =>{
      await setstepDetails({
        process_id: "",
        name: "",
        description: "",
        review: "",
        is_start: "",
        is_end: "",
        is_branch_step: "",
        no_of_branches: "",
        is_parallel: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      setOpen(false);
      setOpen(true);
      setprocessIdErr();
      setnameErr();
      setdescriptionErr();
      setreviewErr();
      setstartErr();
      setendErr();
      setbranchStepErr();
      setnoOfBranchesErr();
      setparallerErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateStepMinimize = () => {
      setOpen(false);
      dispatch(userActions.checkSessionValidity());
    }

    const validate = () => {
      let processIdErr = '';
      let nameErr = '';
      let descriptionErr = '';
      let reviewErr = '';
      let startErr = '';
      let endErr = '';
      let branchStepErr = '';
      let noOfBranchesErr = '';
      let parallerErr = '';

      if(!stepDetails.process_id)  {
          processIdErr = 'Please Select Process';
      }
      if(!stepDetails.name)  {
          nameErr = 'Please Enter Name';
      }

      if(!stepDetails.description)  {
          descriptionErr = 'Please Enter Description';
      }
      if(stepDetails.review == "" && stepDetails.review !== false && stepDetails.review !== true)  {
          reviewErr = 'Please Select Review';
      }
      if(stepDetails.is_start == "" && stepDetails.is_start !== false  && stepDetails.is_start !== true )  {
          startErr = 'Please Select is Start';
      }
      if(stepDetails.is_end == "" && stepDetails.is_end !== false && stepDetails.is_end !== true)  {
          endErr = 'Please Select is End';
      }
      if(stepDetails.is_branch_step == "" && stepDetails.is_branch_step !== false && stepDetails.is_branch_step !== true )  {
          branchStepErr = 'Please Enter is Branch Step';
      }
      if(!stepDetails.no_of_branches)  {
          noOfBranchesErr = 'Please Enter Number of Branches';
      }
      else if (typeof stepDetails.no_of_branches !== "undefined") {
        var pattern = new RegExp(/^[0-9\b]+$/)

        if (!pattern.test(stepDetails.no_of_branches)) {
          // isValid = false;
          noOfBranchesErr = "Please enter only numbers.";
        }
      }

      if(stepDetails.is_parallel == "" && stepDetails.is_parallel !== false && stepDetails.is_parallel !== true)  {
          parallerErr = 'Please Enter if is Parllel';
      }

      if(processIdErr || nameErr || descriptionErr || reviewErr || startErr || endErr || branchStepErr || 
            noOfBranchesErr || parallerErr ){
        setprocessIdErr(processIdErr);
        setnameErr(nameErr);
        setdescriptionErr(descriptionErr);
        setreviewErr(reviewErr);
        setstartErr(startErr);
        setendErr(endErr);
        setbranchStepErr(branchStepErr);
        setnoOfBranchesErr(noOfBranchesErr);
        setparallerErr(parallerErr);
        
        return false;
      }

      return true;

    }

    const handelSubmitCreateStep = () => {
      console.log("stepDetails",stepDetails);
      dispatch(userActions.checkSessionValidity());
      const isValid = validate();
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      if(isValid){
        setShowProcedure(true);
        setOpen(false);
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify({...stepDetails, business_id: business_id})
        };
        return fetch( process.env.REACT_APP_CREATE_STEP , requestOptions)
            .then((Response) => Response.json())
            .then(stepCreated => {
                setShowProcedure(false);
                console.log("stepCreated on same page",stepCreated.success);
                  console.log("stepCreated on same page",stepCreated);
                  if(stepCreated.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Step Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })                  
                    setstepDetails({
                      process_id: "",
                      name: "",
                      description: "",
                      review: "",
                      is_start: "",
                      is_end: "",
                      is_branch_step: "",
                      no_of_branches: "",
                      is_parallel: "",
                      device_token: JSON.parse(localStorage.getItem('device_token')),
                      session_id: JSON.parse(localStorage.getItem('session_id')),
                    })
                    setprocessIdErr();
                    setnameErr();
                    setdescriptionErr();
                    setreviewErr();
                    setstartErr();
                    setendErr();
                    setbranchStepErr();
                    setnoOfBranchesErr();
                    setparallerErr();
                  }
                  if(stepCreated.success == false || stepCreated.success == null ){
                    Swal.fire({
                      icon: 'error',
                      text: stepCreated.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      // timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setOpen(true);
                    })
                }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
          }
    }

    const body = (
        <div className={classes.paperModal}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'45%'}}>Create Step</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelCreateStepMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelCreateStepClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBody} style={{position:'absolute'}}>
            <FormControl className={classes.formControl}>
              {/* <InputLabel id="process_id">Process*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Process*</Grid>
              <Select
                native
                labelId="process_id"
                id="process_id"
                value={stepDetails.process_id}
                onChange={(e) => setstepDetails({...stepDetails, process_id:e.target.value }) }
              >
                {/* <MenuItem value={''}>Select Process</MenuItem>
                {processListDropdown.processListDropdown.map(item =>
                  <MenuItem value={item.process_id}>{item.name}</MenuItem>
                )} */}
                <option value="" style={{color:'grey'}}>Select Process</option>
                  {processListDropdown.processListDropdown.map(item =>
                    <option value={item.process_id}>{item.name}</option>
                )}
              </Select>
            </FormControl>
            <div className={classes.validation}>{(stepDetails.process_id)?(<div></div>):(processIdErr)}</div>

            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              value={stepDetails.name}
              onChange={(e) => setstepDetails({...stepDetails, name:e.target.value }) }
            />
            <div className={classes.validation}>{(stepDetails.name)?(<div></div>):(nameErr)}</div>

            <TextField
              margin="normal"
              required
              fullWidth
              id="description"
              label="Description"
              name="description"
              autoComplete="description"
              value={stepDetails.description}
              onChange={(e) => setstepDetails({...stepDetails, description:e.target.value }) }
            />
            <div className={classes.validation}>{(stepDetails.description)?(<div></div>):(descriptionErr)}</div>

            {/* <TextField
              margin="normal"
              required
              fullWidth
              id="review"
              label="Review"
              name="review"
              autoComplete="review"
              value={stepDetails.review}
              onChange={(e) => setstepDetails({...stepDetails, review:e.target.value }) }
            /> */}
            <FormControl className={classes.formControl}>
              {/* <InputLabel id="review">Review*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Review*</Grid>
              <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="review"
                  aria-label="review"
                  name="review"
                  defaultValue={JSON.stringify(stepDetails.review)}
                  onChange={ (e) => setstepDetails({...stepDetails, review: JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>
              {/* <Select
                labelId="review"
                id="review"
                value={stepDetails.review}
                onChange={(e) => setstepDetails({...stepDetails, review:e.target.value }) }
              > */}
                {/* <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
                {yesNo.map(item =>
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                )}
              </Select> */}
            </FormControl>
            <div className={classes.validation}>{(stepDetails.review == "" && stepDetails.review !== false && stepDetails.review !== true)?(reviewErr):(<div></div>)}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="is_start">Is Start*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Is Start*</Grid>
              <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="is_start"
                  aria-label="is_start"
                  name="is_start"
                  defaultValue={JSON.stringify(stepDetails.is_start)}
                  onChange={ (e) => setstepDetails({...stepDetails, is_start: JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>
              {/* <Select
                labelId="is_start"
                id="is_start"
                value={stepDetails.is_start}
                onChange={(e) => setstepDetails({...stepDetails, is_start:e.target.value }) }
              >
                {yesNo.map(item =>
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                )}
              </Select> */}
            </FormControl>
            <div className={classes.validation}>{(stepDetails.is_start == "" && stepDetails.is_start !== false  && stepDetails.is_start !== true)?(startErr):(<div></div>)}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="is_end">Is End*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Is End*</Grid>
              <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="is_end"
                  aria-label="is_end"
                  name="is_end"
                  defaultValue={JSON.stringify(stepDetails.is_end)}
                  onChange={ (e) => setstepDetails({...stepDetails, is_end: JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>
              {/* <Select
                labelId="is_end"
                id="is_end"
                value={stepDetails.is_end}
                onChange={(e) => setstepDetails({...stepDetails, is_end:e.target.value }) }
              >
                {yesNo.map(item =>
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                )}
              </Select> */}
            </FormControl>
            <div className={classes.validation}>{(stepDetails.is_end == "" && stepDetails.is_end !== false && stepDetails.is_end !== true)?(endErr):(<div></div>)}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="is_branch_step">Is Branch Step*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Is Branch Step*</Grid>
              <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="is_branch_step"
                  aria-label="is_branch_step"
                  name="is_branch_step"
                  defaultValue={JSON.stringify(stepDetails.is_branch_step)}
                  onChange={ (e) => setstepDetails({...stepDetails, is_branch_step: JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>

              {/* <Select
                labelId="is_branch_step"
                id="is_branch_step"
                value={stepDetails.is_branch_step}
                onChange={(e) => setstepDetails({...stepDetails, is_branch_step:e.target.value }) }
              >
                {trueFalse.map(item =>
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                )}
              </Select> */}
            </FormControl>
            <div className={classes.validation}>{(stepDetails.is_branch_step == "" && stepDetails.is_branch_step !== false  && stepDetails.is_branch_step !== true)?(branchStepErr):(<div></div>)}</div>

            <TextField
              margin="normal"
              required
              fullWidth
              id="no_of_branches"
              label="No Of Branches"
              name="no_of_branches"
              autoComplete="no_of_branches"
              value={stepDetails.no_of_branches}
              onChange={(e) => setstepDetails({...stepDetails, no_of_branches:e.target.value }) }
            />
            <div className={classes.validation}>{noOfBranchesErr}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="is_parallel">Is Parallel*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Is Parallel*</Grid>
              <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="is_parallel"
                  aria-label="is_parallel"
                  name="is_parallel"
                  defaultValue={JSON.stringify(stepDetails.is_parallel)}
                  onChange={ (e) => setstepDetails({...stepDetails, is_parallel: JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>
              {/* <Select
                labelId="is_parallel"
                id="is_parallel"
                value={stepDetails.is_parallel}
                onChange={(e) => setstepDetails({...stepDetails, is_parallel:e.target.value }) }
              >
                {yesNo.map(item =>
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                )}
              </Select> */}
            </FormControl>
            <div className={classes.validation}>{(stepDetails.is_parallel == "" && stepDetails.is_parallel !== false && stepDetails.is_parallel !== true)?(parallerErr):(<div></div>)}</div>

            <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
                <Button
                type="submit"
                //   fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handelSubmitCreateStep}
                >
                Create
                </Button>
                <div style={{marginLeft:'2%'}}></div>
                <Button
                    type="submit"
                    variant="contained"
                    // color="grey"
                    className={classes.submit}
                    onClick={handelCreateStepClear}
                >
                Clear
                </Button>
            </div>
        </div>
        </div>
    )

    const handelStepBulkOpen = () => {
      setopenBulk(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelStepBulkClose = () => {
      setopenBulk(false);
      setExcelFile();
      setExcelName();
      setfileErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelStepBulkClear = () => {
      setExcelFile();
      setExcelName();
      setfileErr();
    }

    const handelStepBulkMinimize = () => {
      setopenBulk(false);
    }

    const onUploadExcel = (event) => {
        console.log("event.target.files[0]",event.target.files[0])
        console.log("event.target.files[0] name",event.target.files[0].name)
        setExcelName(event.target.files[0].name);
        setExcelFile(event.target.files[0]);
        dispatch(userActions.checkSessionValidity());
    }

    const validateBulk = () => {
      let fileErr = '';

      if(!ExcelFile)  {
          fileErr = 'Please Select File';
      }

      if(fileErr ){
        setfileErr(fileErr);

        return false;
      }
      return true;
    }

    const handelSubmitStepBulk = () => {
      dispatch(userActions.checkSessionValidity());
      let users = JSON.parse(localStorage.getItem('users'));
      let username = users.data.user_details.username
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      // console.log("username in category bulk",username);
      // console.log("business_id in category bulk",business_id);
      // console.log("ExcelFile in category bulk",ExcelFile);

      const isValid = validateBulk();
      if(isValid){
        setShowProcedure(true);
        setopenBulk(false);
        let formdata = new FormData();
        formdata.append('business_id', business_id);
        formdata.append('username', username);
        formdata.append('excel_file', ExcelFile);
        let user = JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
          method: 'POST',
          headers:  {
            Authorization: "Token " + user.key,
          },
          body: formdata,
        };
        return fetch( process.env.REACT_APP_BULK_CREATE_STEP , requestOptions)
            .then((Response) => Response.json())
            .then( stepBulk => {
                setShowProcedure(false);
                console.log("stepBulk on same page",stepBulk.success);
                  console.log("stepBulk on same page",stepBulk);
                  if(stepBulk.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Step Bulk Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    setExcelFile();
                    setExcelName();
                    setfileErr();
                  }
                  if(stepBulk.success == false){                  
                    Swal.fire({
                      icon: 'error',
                      text: stepBulk.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setopenBulk(true);
                    })
                }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
    }

    const bulkBody = (
        <div className={classes.paperModalBulk}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'47%'}}>Step Bulk</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelStepBulkMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelStepBulkClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBodyBulk}>
          {/* <input type='file' 
            accept=".xlsx"
            id="myBulk"
            onChange={ (e) => onUploadExcel(e)} 
          /> */}
          <Grid container>
            <Grid item xs={3}>
              <input type='file' 
                // id="files"
                // accept=".xlsx"
                id="myBulk"
                onChange={ (e) => onUploadExcel(e)}  
                hidden
              />
              <label for="myBulk" style={{backgroundColor: '#cbd4cd', height:8, width:40, padding:10, borderRadius:5,
                cursor: "pointer"}}>Select file</label>
            </Grid>
            <Grid item xs={6} style={{marginLeft:'-2%'}}>
              {
                ( ExcelName)?
                (
                  <div >
                    {/* <a 
                      href={this.state.BiographyResumeFileName} target = "_blank"> */}
                      <text>{ExcelName}</text>
                    {/* </a> */}
                  </div>
                ):
                (
                  <div>
                    No file Choosen 
                  </div>
                )
              }
            </Grid>         
          </Grid>
        <div className={classes.validationBulk}>{(ExcelFile)?(<div></div>):(fileErr)}</div>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitStepBulk}
          >
            Create Bulk
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handelStepBulkClear}
          >
            Clear
          </Button>
        </div>
        </div>
        </div>
    )

  return(
    <Grid item xs={4}>
        <div>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        </div>
        <Paper className={classes.paper}>
            <Grid container>
                <Grid item xs={1} className={classes.moreVertIcon}>
                    <PersonIcon className={classes.personIconColor}/>
                </Grid>
                <Grid item xs={6} style={{marginLeft:'2%', color:'black'}}>
                    Steps List
                </Grid>
            </Grid>
            <hr/>
            <Grid container>
                <Grid item xs={12} className={classes.userAddUpdateDelete}>
                  <Link to="StepList" style={{ textDecoration: 'none', color:'black' }}>
                      Step List
                  </Link> 
                </Grid>
                <Grid item xs={12} onClick={handelCreateStepOpen} className={classes.userAddUpdateDelete}>
                    Create Step
                </Grid>
                <Modal
                    open={open}
                    onClose={handelCreateStepClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>
                <Grid item xs={12} onClick={handelStepBulkOpen} className={classes.userAddUpdateDelete}>
                    Bulk create Steps
                </Grid>
                <Modal
                    open={openBulk}
                    onClose={handelStepBulkClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {bulkBody}
                </Modal>
                </Grid>
        </Paper>
    </Grid>
  )
}
