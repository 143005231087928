// AddNewContactForClient

import React, { useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import TaxManagementRecentCases from "../TaxManagementRecentCases";
import SearchView from "../SearchView";
import { Link, Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { userActions } from "../actions";
import Grid from "@material-ui/core/Grid";
import TaxManagementCaseDetails from "../TaxManagementCaseDetails";
import TaxManagementRecentClient from "../TaxManagementRecentClient";
import TaxManagementClientDetails from "../TaxManagementClientDetails";
import useCheckSessionApi from "../SessionValidity";
import { authHeader } from '../helpers';
import { useSelector, useDispatch } from 'react-redux';
import Paper from "@material-ui/core/Paper";
import { set } from "lodash";
import { PropagateLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import TextField from "@material-ui/core/TextField";
import { Multiselect } from 'react-widgets';
import "react-widgets/styles.css";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "react-phone-input-2/lib/material.css";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    //   display: "flex",
        // 
        // background: "#ffffff",
    },
    wrapper: {
      margin: "10%",
      marginRight: "5%",
      marginTop: "7%",
      marginBottom: "3%",
    },
  
    pageHeaderLabel: {
      fontFamily: [
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "20px",
      lineHeight: "20px",
      color: "rgba(0, 0, 0, 0.64)",
      textAlign: "left",
    },
    pageHeaderContainer: {
      marginBottom: "5%",
    },
    showProcedureStyle: {
      position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
    },
    paper: {
      padding: theme.spacing(2),
      height: '100%',
      textAlign: "left",
      color: theme.palette.text.secondary,
    },
    validation: {
      color: 'red',
      fontSize: 12,
    },
    backButton: {
        padding: 0,
        lineHeight: 0,
        textTransform: "none",
        color: "rgba(0, 0, 0, 0.64)",
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: [
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
        ].join(","),
    },
    backArrow: {
        marginRight: "10%",
        marginLeft: "-10%",
    },
    subheadingTitle: {
        fontFamily: [
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
        ].join(","),
        // fontStyle: "normal",
        fontWeight: "400",
        fontSize: "15px",
        lineHeight: "20px",
        color: "black",
        textAlign: "left",
    },
    ProcessLabel: {
        fontFamily: [
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
        ].join(","),
        fontStyle: "normal",
        // fontWeight: "500",
        fontSize: "15px",
        lineHeight: "20px",
        color: "black",
        textAlign: "left",
        // marginLeft: "5%",
    },
    subHeadingLabel: {
        fontFamily: [
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
        ].join(","),
        fontStyle: "normal",
        // fontWeight: "500",
        fontSize: "12px",
        color: "rgba(0, 0, 0, 0.96)",
        marginTop: "25px",
        // marginBottom: "10px",
        textAlign: "left",
    },
    createButtonContainer: {
        marginTop: "2%",
    },
  
  }));

  export default function AddNewContactForClient(props) {
    const classes = useStyles();
    const [ShowProcedure, setShowProcedure ] = React.useState(false);
    const [navigatedFrom, setNavigatedFrom] = React.useState(JSON.parse(localStorage.getItem('navigated_to_add_contact_from')));
    const [ClientDetailsToAddContact, setClientDetailsToAddContact] = React.useState(JSON.parse(localStorage.getItem('client_details_to_add_contact')));
    const [onlyCountryArray, setonlyCountryArray] = React.useState([
        'in','us', 'gb', 'ca', 'ae', 'sg','np', 'jp', 'de', 'bt', 'au'
    ]);
    let selected_Client_Showin_UiH = JSON.parse(localStorage.getItem('selected_Client_Showin_Ui'));

    const[selected_Client_Showin_Ui, setselected_Client_Showin_Ui] = React.useState();
    const [AnotherContact, setAnotherContact] = React.useState({
        assesse_id: "",
        name: "",
        email: "",
        phone: "",
        country_code: "",
        pan: "",
        is_active: true,
        client_id: selected_Client_Showin_UiH,
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')), 
    })
    const [CountryCode, setCountryCode] = React.useState();
    const [showPhoneIp, setshowPhoneIp] = React.useState(true);

    const [nameErr, setnameErr] = React.useState("");
    const [emailErr, setemailErr] = React.useState("");
    const [phoneErr, setphoneErr] = React.useState("");
    const [panErr, setpanErr] = React.useState("");

    const [RedirectToClientDetailsPage, setRedirectToClientDetailsPage] = React.useState(false);

    // }
    // redux
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userActions.checkSessionValidity());
        setShowProcedure(true);
        let selected_Client_Showin_Ui = JSON.parse(localStorage.getItem('selected_Client_Showin_Ui'));

        setselected_Client_Showin_Ui(selected_Client_Showin_Ui)

        setShowProcedure(false);

    }, []);

    const phoneNumberChange = (clientNumber,e,formattedValue,value) => {
        console.log("phoneNumberChange clientNumber",clientNumber)
        console.log("phoneNumberChange e",e)
        console.log("phoneNumberChange value",value)
        let countryCodeIs = "+" + e.dialCode
        let splitNoIs = ""
        if(e.countryCode == "in"){
          splitNoIs = value.split(" ")[1];
        }
        if(e.countryCode == "us"){
          // splitNoIs = value.split(" ")[1];
          // splitNoIs = value.substr(value.indexOf(' ')+1);
  
          splitNoIs = value.split(" ")[1];
        }
        if(e.countryCode == "gb"){
          splitNoIs = value.split(" ")[1];
        }
        if(e.countryCode == "ca"){
          splitNoIs = value.split(" ")[1];
        }
        if(e.countryCode == "ae"){
          splitNoIs = value.split(" ")[1];
        }
        if(e.countryCode == "sg"){
          splitNoIs = value.split(" ")[1];
        }
        if(e.countryCode == "np"){
          splitNoIs = value.split(" ")[1];
        }
        if(e.countryCode == "jp"){
          splitNoIs = value.split(" ")[1];
        }
        if(e.countryCode == "de"){
          splitNoIs = value.split(" ")[1];
        }
        if(e.countryCode == "bt"){
          splitNoIs = value.split(" ")[1];
        }
        if(e.countryCode == "au"){
          splitNoIs = value.split(" ")[1];
        }
        console.log("splitNoIs",splitNoIs)
        let noHereIs = "+"+e.dialCode+"-"+splitNoIs;
        console.log("noHereIs",noHereIs)
        setCountryCode(e.countryCode)
        // setAnotherContact({...AnotherContact, phone:noHereIs })
        setAnotherContact({...AnotherContact, phone: splitNoIs , country_code: countryCodeIs  })
    }

    const validate = () => {
        let nameErr  = '';
        let panErr = '';
        let emailErr = '';
        let phoneErr = '';

        if(!AnotherContact.name)  {
            nameErr = 'Please Enter Name';
        }

        if(!AnotherContact.pan)  {
            panErr = 'Please Enter PAN';
        }
        else if (typeof AnotherContact.pan !== "undefined") {
            var pattern = new RegExp(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/)
            if(AnotherContact.pan.length !== 10){
              console.log("in != 10")
               panErr = "PAN Number is Not Valid";
            }
            else if (!pattern.test(AnotherContact.pan)) {
              console.log("in string check")
              panErr = "PAN Number is Not Valid";
            } 
            else{
              setpanErr();
            }
        }
        else{
          setpanErr();
        }

        if(!AnotherContact.email)  {
            emailErr = 'Please Enter Email';
        }
        else if(typeof AnotherContact.email !== "undefined"){
            let lastAtPos = AnotherContact.email.lastIndexOf('@');
            let lastDotPos = AnotherContact.email.lastIndexOf('.');
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 )) {
                emailErr = "Email is not valid";
                if(emailErr) {
                  setemailErr(emailErr);
                }
            }
          else {
              setemailErr();    
            }
        }
        else{
                setemailErr();
        }

        if(!AnotherContact.phone)  {
            phoneErr = 'Please Enter Phone';
        }
        else if (typeof AnotherContact.phone !== "undefined") {
            var pattern = new RegExp(/^[0-9\b]+$/)
            if (CountryCode == "in"){
                if(AnotherContact.phone.length !== 10){
                  phoneErr = "Please enter valid phone number.";
                }
            }
            else if(CountryCode == "us"){
                if(AnotherContact.phone.length !== 10){
                  phoneErr = "Please enter valid phone number.";
                }
            }
            else if(CountryCode == "gb"){
              console.log("AnotherContact.phone.length gb",AnotherContact.phone.length)
              if(AnotherContact.phone.length !== 10){
                phoneErr = "Please enter valid phone number.";
              }
            }
            else if(CountryCode == "ca"){
              console.log("AnotherContact.phone.length gb",AnotherContact.phone.length)
              if(AnotherContact.phone.length !== 10){
                phoneErr = "Please enter valid phone number.";
              }
            }
            else if(CountryCode == "ae"){
              console.log("AnotherContact.phone.length gb",AnotherContact.phone.length)
              if(AnotherContact.phone.length !== 12){
                phoneErr = "Please enter valid phone number.";
              }
            }
            else if(CountryCode == "sg"){
              console.log("AnotherContact.phone.length gb",AnotherContact.phone.length)
              if(AnotherContact.phone.length !== 8){
                phoneErr = "Please enter valid phone number.";
              }
            }
            else if(CountryCode == "np"){
              console.log("AnotherContact.phone.length gb",AnotherContact.phone.length)
              if(AnotherContact.phone.length !== 12){
                phoneErr = "Please enter valid phone number.";
              }
            }
            else if(CountryCode == "jp"){
              console.log("AnotherContact.phone.length gb",AnotherContact.phone.length)
              if(AnotherContact.phone.length !== 10){
                phoneErr = "Please enter valid phone number.";
              }
            }
            else if(CountryCode == "de"){
              console.log("AnotherContact.phone.length gb",AnotherContact.phone.length)
              if(AnotherContact.phone.length !== 12){
                phoneErr = "Please enter valid phone number.";
              }
            }
            else if(CountryCode == "bt"){
              console.log("AnotherContact.phone.length gb",AnotherContact.phone.length)
              if(AnotherContact.phone.length !== 12){
                phoneErr = "Please enter valid phone number.";
              }
            }
            else if(CountryCode == "au"){
              console.log("AnotherContact.phone.length gb",AnotherContact.phone.length)
              if(AnotherContact.phone.length !== 13){
                phoneErr = "Please enter valid phone number.";
              }
            }
        }

        if(nameErr || panErr || emailErr || phoneErr){
            setnameErr(nameErr);
            setpanErr(panErr);
            setemailErr(emailErr);
            setphoneErr(phoneErr);
            // setselfAssErr(selfAssErr);
    
          return false;
        }
        return true;

    }

    const handleAddButton = async() => {
        const isValid = validate();
        if(isValid){
            console.log("handleAddButton",AnotherContact);
            setShowProcedure(true);

            const requestOptions = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify(AnotherContact)
            };

            fetch( process.env.REACT_APP_UPDATE_CLIENT_CONTACT , requestOptions)
            .then((Response) => Response.json())
            .then( async(anotherContactAdded) => {
              setShowProcedure(false);
              if(anotherContactAdded.success == true)
              {
                Swal.fire({
                  icon: 'success',
                  text: "Client Anothet Contact Added Succesfully",
                  confirmButtonColor: 'primary',
                  confirmButtonText: 'OK'
                })
                await setshowPhoneIp(false);
                await setAnotherContact({
                    assesse_id: "",
                    name: "",
                    email: "",
                    phone: "",
                    country_code: "",
                    pan: "",
                    is_active: true,
                    client_id:selected_Client_Showin_Ui,
                    device_token: JSON.parse(localStorage.getItem('device_token')),
                    session_id: JSON.parse(localStorage.getItem('session_id')),
                });
                await setshowPhoneIp(true);
                setnameErr("");
                setpanErr("");
                setemailErr("");
                setphoneErr("");
                setRedirectToClientDetailsPage(true);
              }
              else{
                Swal.fire({
                  icon: 'error',
                  text: anotherContactAdded.errors,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              }

            })
        }
    }

    const handleReset = async() => {
        await setshowPhoneIp(false);
        console.log("rset",AnotherContact);
        await setAnotherContact({
            assesse_id: "",
            name: "",
            email: "",
            phone: "",
            country_code: "",
            pan: "",
            is_active: true,
            device_token: JSON.parse(localStorage.getItem('device_token')),
            session_id: JSON.parse(localStorage.getItem('session_id')),
        });
        setnameErr("");
        setpanErr("");
        setemailErr("");
        setphoneErr("");
        await setshowPhoneIp(true);
    }

    if( RedirectToClientDetailsPage == true){
      return <Redirect to={`/TaxManagementClientDetailsUI`} />
    }
    else{
    return (
        console.log("AnotherContact in reurn",AnotherContact),
        <div className={classes.root}>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        <div className={classes.wrapper}>
            <Grid container>
                <Grid>
                    {
                        (navigatedFrom && navigatedFrom == "ClientDetailsUi")?
                        (
                            <Button href="/TaxManagementClientDetailsUI" className={classes.backButton}>
                                <span className={classes.backArrow}>
                                    <ArrowBackIcon fontSize="small" />
                                </span>
                                <FormattedMessage id="backLabel" />
                            </Button>
                        ):
                        (<Grid></Grid>)
                    }
                </Grid>
                <Grid container className={classes.pageHeaderContainer} style={{marginTop:'1%'}}>
                    <Grid item xs={12} className={classes.pageHeaderLabel}>
                        Add Another Contact Details
                    </Grid>
                </Grid>
            </Grid>
            <Paper className={classes.paper} style={{marginTop:'-3%'}}>
                <Grid>
                    <Grid className={classes.subheadingTitle}>
                        Client Name : {ClientDetailsToAddContact.name}
                    </Grid>

                    <Grid className={classes.ProcessLabel} style={{marginTop:'2%'}}>
                        Name*
                    </Grid>
                    <TextField
                        autoFocus
                        // margin="normal"
                        required
                        fullWidth
                        id="outlined-basic"
                        variant="outlined"
                        // label="Step Name"
                        name="name"
                        value={AnotherContact.name}
                        inputProps={{ maxLength: 200 }}
                        onChange={(e) => setAnotherContact({...AnotherContact, name:e.target.value}) }
                        // onChange={(e) => handleChangeEditStep(e, index) }
                    />
                    <div className={classes.validation}>{(AnotherContact.name)?(<div></div>):(nameErr)}</div>

                    <Grid className={classes.ProcessLabel} style={{marginTop:'2%'}}>
                        Email ID*
                    </Grid>
                    <TextField
                        // margin="normal"
                        required
                        fullWidth
                        id="outlined-basic"
                        variant="outlined"
                        // label="Step Name"
                        name="email"
                        inputProps={{ style: { textTransform: "lowercase" } }}
                        value={AnotherContact.email}
                        onChange={(e) => setAnotherContact({...AnotherContact, email:e.target.value}) }
                    />
                    <div className={classes.validation}>{emailErr}</div>                

                    <Grid className={classes.ProcessLabel} style={{marginTop:'2%'}}>
                        PAN*
                    </Grid>
                    <TextField
                        // margin="normal"
                        required
                        fullWidth
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="XXXXX0000X"
                        // label="Step Name"
                        name="pan"
                        inputProps={{ maxLength: 10, style: { textTransform: "uppercase" } }}
                        value={AnotherContact.pan}
                        onChange={(e) => setAnotherContact({...AnotherContact, pan:e.target.value.toUpperCase()}) }
                    />
                    <div className={classes.validation}>{panErr}</div>

                    <Grid item xs={12} className={classes.ProcessLabel} style={{marginTop:'2%'}}>
                        Phone Number*
                    </Grid>
                    <Grid item xs={12} >
                        {
                            
                            (showPhoneIp == true)?
                            (
                                <PhoneInput
                                    inputStyle={{
                                    width: "100%",
                                    height: "50px",
                                    fontSize: "15px",
                                    }}
                                    countryCodeEditable={false}
                                    onlyCountries={onlyCountryArray}
                                    masks={{in: '..........', us: '..........', gb: '..........', ca: '..........', ae:'............',
                                            sg: '........', np:'............', jp:'..........', de:'............', bt:'............',
                                            au: '.............'  }}
                                    specialLabel=""
                                    country={'in'}
                                    // value={AnotherContact.phone}
                                    value={AnotherContact.country_code + AnotherContact.phone}
                                    onChange={(usersNumber,e,formattedValue,value) => phoneNumberChange(usersNumber,e,formattedValue,value)}
                                />
                            ):
                            (<Grid></Grid>)
                        }                       
                    </Grid>
                    <div className={classes.validation}>{phoneErr}</div>
                    
                    <Grid container item xs={12} style={{marginLeft:'35%'}} className={classes.createButtonContainer}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleAddButton}
                        >
                            Add
                        </Button>
                        <div style={{marginLeft:'2%'}}></div>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleReset}
                        >
                            Reset
                        </Button>
                        <div style={{marginLeft:'2%'}}></div>
                        <Button
                            variant="contained"
                            // color="primary"
                            className={classes.submit}
                            href="/TaxManagementClientDetailsUI"
                            // onClick={handleReset}
                        >
                            Cancel
                        </Button>
                    </Grid>

                </Grid>
                
            </Paper>

        </div>
        </div>
    )
    }

  }