import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import Avatar from "@material-ui/core/Avatar";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FilterListIcon from "@material-ui/icons/FilterList";
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../../actions";
import { Link, Redirect } from 'react-router-dom';
import { Multiselect } from 'react-widgets';
import "react-widgets/styles.css";

import TableHead from '@material-ui/core/TableHead';
import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import TableSortLabel from '@material-ui/core/TableSortLabel';
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(searchListCases, comparator) {
  const stabilizedThis = searchListCases && searchListCases.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, label: 'Name', },
  // { id: 'process', numeric: false, label: 'Process', },
  // { id: 'service', numeric: false, label: 'Service', },
  { id: 'description', numeric: false, label: 'Description', },
  // { id: 'client_name', numeric: false, label: 'Client Name', },
  // { id: 'client_pan', numeric: false, label: 'Client PAN', },
  // { id: 'priority', numeric: false, label: 'Priority', },
  { id: 'case_curr_state', numeric: false, label: 'Case Status', },
  { id: 'created_date', numeric: false, label: 'Created Date', },
  { id: 'client_name', numeric: false, label: 'Client Name', },
  { id: 'client_pan', numeric: false, label: 'Client Pan', },
  { id: 'actions', numeric: false, label: 'Actions', },
];
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'center' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            align="center"
            style={{fontWeight: 'bold', fontSize: 17,  width: "10%"}}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))} */}
        {headCells.map((headCell) => (
          (headCell.id === "actions") ?
            (
              <TableCell
                key={headCell.id}
                align="center"
                style={{ fontWeight: 'bold', fontSize: 17, width: "1%" }}
              >
                {headCell.label}
              </TableCell>
            )
            : (
              <TableCell
                key={headCell.id}
                // align={headCell.numeric ? 'center' : 'center'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                align="center"
                style={{ fontWeight: 'bold', fontSize: 17, width: "10%" }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  rootrp: {
    flexGrow: 1,
    // backgroundColor: 'white',
  },
  cursorPointer: {
    cursor: "pointer"
  },
  wrapper: {
    margin: "10%",
    marginRight: "10%",
    marginTop: "8%",
    marginBottom: "3%",
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    boxShadow: 'none',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: '0px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  filterLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "21px",
    color: "rgba(0, 0, 0, 0.96)",
  },
  customFilterLabel: {
    textTransform: "none",
    background: "#FFFFFF",
    marginTop: "-200%",
    marginLeft: "-400%"
  },
  customClearFilterLabel: {
    textTransform: "none",
    background: "#FFFFFF",
    marginTop: "-140%",
    marginLeft: "-250%"
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
  },
  paperModalFilter: {
    position: 'absolute',
    width: 600,
    height: 610,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'5%',
    display: 'block',
    overflowY: 'scroll',
  },
  modalBody: {
    padding: theme.spacing(1, 4, 3),
    width: '100%',
  },
  modalBodyFilters: {
    padding: theme.spacing(1, 4, 3),
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  subHeadingLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    color: "black",
    // marginBottom: "10px",
  },
  subHeadingLabeltwo: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    color: "black",
    marginTop: "20px",
    marginBottom: "10px",
  },
  textTransformNone: {
    textTransform: "none",
    color: 'black'
  },
  marginRight: {
    marginRight: "4%",
  },
  tagButtonStyle: {
    textTransform: "none",
    // background: "#FDD600",
  },
  hover: {
    cursor: "pointer",
  },
  linkBackToCase: {
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: "600",
    textTransform: "none",
    marginTop: "5%",
    marginBottom: "2%",
    textAlign: "right"
  },

  linkNoUnderline: {
    textDecoration: "none"
  },

}));

function SearchViewCases({ intl, handleChange }) {
  const classes = useStyles();
  const [flag, setflag] = React.useState(false);
  const [searchResult, setSearchResult] = React.useState(JSON.parse(localStorage.getItem('searchStringIs')));
  const [searchListCases, setsearchListCases] = React.useState([])
  const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [paginationDetails, setpaginationDetails] = React.useState({ total_cases: 0 });

  const [RedirectToTaxManagementCaseDetails, setRedirectToTaxManagementCaseDetails] = React.useState(false);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('selected_business_id')));

  // Filters
  const [OpenFilter, setOpenFilter] = React.useState(false);
  const [allTagDetails, setallTagDetails] = React.useState([]);
  const [allUsersList, setallUsersList] = React.useState([]);
  const [AssignUserFilter, setAssignUserFilter] = React.useState([]);
  const [AssignUserFilterNames, setAssignUserFilterNames] = React.useState([]);
  const [TagFilter, setTagFilter] = React.useState([]);
  const [TagFilterNames, setTagFilterNames] = React.useState([]);
  const [MultiselectRefresh, setMultiselectRefresh] = React.useState(true);
  const [FilterIsApplied, setFilterIsApplied] = React.useState(false);
  const [allServicesDetails, setallServicesDetails] = React.useState([]);
  const [ServiceFilter, setServiceFilter] = React.useState([]);
  const [ServiceFilterNames, setServiceFilterNames] = React.useState([]);
  const [allRolesDetails, setallRolesDetails] = React.useState([]);
  const [RoleFilter, setRoleFilter] = React.useState([]);
  const [RoleFilterNames, setRoleFilterNames] = React.useState([]);
  const [allClientGroupDetails, setallClientGroupDetails] = React.useState([]);
  const [ClientGroupsFilter, setClientGroupsFilter] = React.useState([]);
  const [ClientGroupsFilterNames, setClientGroupsFilterNames] = React.useState([]);
  const [ClientDetails, setClientDetails] = React.useState([]);
  const [ClientsFilter, setClientsFilter] = React.useState([]);
  const [ClientsFilterNames, setClientsFilterNames] = React.useState([]);

  // sort 
  const [SortByArray, setSortByArray] = React.useState([
    { name: 'Recent', value: 'recent', selected: false },
    { name: 'Priority', value: 'priority', selected: false },
    { name: 'Status', value: 'status', selected: false },
  ]);
  // priority
  const [PriorityArray, setPriorityArray] = React.useState([
    // {name: 'Any', value: 'any', selected: false},
    { name: 'Low', value: 'low', selected: false },
    { name: 'Medium', value: 'medium', selected: false },
    { name: 'High', value: 'high', selected: false },
  ]);

  const dispatch = useDispatch();

  // sort table
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, searchListCases && searchListCases.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    let nuberOfRowsPerPage = rowsPerPage
    setPage(newPage);

    getNextCasesAfterPagination(newPage, nuberOfRowsPerPage)
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    let valueHere = parseInt(event.target.value, 10)
    let valueToCompare = JSON.stringify(valueHere)
    if( valueToCompare === "null" ){
      setRowsPerPage(0);
    }
    else{
      setRowsPerPage(parseInt(event.target.value, 10));
    }
    setPage(0);

    let Rows_per_page_here = parseInt(event.target.value, 10);
    let page_here = 0;

    getNextCasesAfterPagination(page_here, Rows_per_page_here)
  };

  const getNextCasesAfterPagination = (newPage, nuberOfRowsPerPage) => {

    console.log("new page number is", newPage, newPage + 1);
    console.log("nuberOfRowsPerPage in next", nuberOfRowsPerPage);

    // sort
    let SortBySelectedHere = "";
    SortByArray.map((f) => {
      if (f.selected == true) {
        console.log("true f is", f)
        SortBySelectedHere = f.value;
      }
    })
    console.log("SortBySelectedHere", SortBySelectedHere);
    //priority
    let PrioritySelectedHere = "";
    PriorityArray.map((f) => {
      if (f.selected == true) {
        console.log("true f is", f)
        PrioritySelectedHere = f.value;
      }
    })
    console.log("PrioritySelectedHere", PrioritySelectedHere);
    // tag
    const tagsForFilter = [];
    allTagDetails.map((f) => {
      if (f.selected == true) {
        console.log("true f is", f)
        tagsForFilter.push(f.tag_id);
      }
    })
    console.log("tagsForFilter is", tagsForFilter)
    // assigned people allUsersList
    const assignedPeopleForFilter = [];
    allUsersList.map((f) => {
      if (f.selected == true) {
        console.log("true f is", f)
        assignedPeopleForFilter.push(f.user_id);
      }
    })
    console.log("assignedPeopleForFilter is", assignedPeopleForFilter)

    let search_string = JSON.parse(localStorage.getItem('searchStringIs'));

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'search_str': search_string, 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id,
        'sort_by': SortBySelectedHere, 'priority': PrioritySelectedHere, 'tags': TagFilter,
        'services': ServiceFilter,  'clients':ClientsFilter,
        // 'roles': RoleFilter, 'client_groups': ClientGroupsFilter,
        'assigned_users': AssignUserFilter, "client_type": "ALL",
        'page_number': newPage + 1, 'page_size': nuberOfRowsPerPage
      })
    };
    fetch(process.env.REACT_APP_SEARCH_CASES, requestOptions)
      .then((Response) => Response.json())
      .then(SearchDetailsFilter => {

        console.log("SearchDetailsFilter after pagination", SearchDetailsFilter);
        // if (casesDetails.data.cases) {
        //   setcasesList(casesDetails.data.cases)
        //   setShowTableFlag(true)
        // }
        if (SearchDetailsFilter.data.search_results) {
          setsearchListCases(SearchDetailsFilter.data.search_results.cases);
        }
        if (SearchDetailsFilter.data.pagination) {
          setpaginationDetails(SearchDetailsFilter.data.pagination)

          // setRowsPerPage(casesDetails.data.cases.length)
          setRowsPerPage(nuberOfRowsPerPage)

        }
        if (SearchDetailsFilter.success == false) {
          setsearchListCases([])
          let total_cases_here = 0
          setpaginationDetails({ ...paginationDetails, total_cases: total_cases_here })
          Swal.fire({
            icon: 'error',
            // text: SearchDetailsFilter.errors,
            text: "No Result For Your Search Criteria",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

  }

  useEffect(() => {
    dispatch(userActions.checkSessionValidity());
    let search_string = JSON.parse(localStorage.getItem('searchStringIs'));
    // let search_string = search_string_array.name
    console.log("search_string in search result", search_string)

    let per_page_here = 100;

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'search_str': search_string, 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id,
        'sort_by': '', 'priority': '', 'tags': [], 'assigned_users': [], "client_type": "ALL",
        'page_number': 1, 'page_size': per_page_here
      })
    };


    fetch(process.env.REACT_APP_SEARCH_CASES, requestOptions)
      .then((Response) => Response.json())
      .then(async (SearchDetails) => {
        console.log("SearchDetails in search", SearchDetails.data);
        console.log("SearchDetails in search search_results", SearchDetails.data.search_results);
        if (SearchDetails.data.search_results) {
          setPage(0)
          setRowsPerPage(per_page_here)
          setsearchListCases(SearchDetails.data.search_results.cases);

          setpaginationDetails(SearchDetails.data.pagination)
        }
        if (SearchDetails.success == false) {
          setsearchListCases([])
          let total_cases_here = 0
          setpaginationDetails({ ...paginationDetails, total_cases: total_cases_here })
          Swal.fire({
            icon: 'error',
            text: SearchDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    const requestOptionsAllTags = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'business_id': business_id,
        'device_token': device_token, 'session_id': session_id
      })
    };
    fetch(process.env.REACT_APP_GET_BUSINESS_TAGS, requestOptionsAllTags)
      .then((Response) => Response.json())
      .then(async (AllTagDetails) => {
        console.log("AllTagDetails on show case table", AllTagDetails);
        if (AllTagDetails.success == true) {
          console.log("AllTagDetails", AllTagDetails)
          await setallTagDetails(AllTagDetails.data.business_tags_info)
        }
        else if (AllTagDetails.success == false) {
          setallTagDetails([]);
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    const requestOptionsUsers = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'business_id': business_id,
        'device_token': device_token, 'session_id': session_id
      })
    };
    fetch(process.env.REACT_APP_GET_BUSINESS_TEAM, requestOptionsUsers)
      .then((Response) => Response.json())
      .then(async (allUsersDetails) => {
        console.log("allUsersDetails", allUsersDetails);
        if (allUsersDetails.success == true) {
          setallUsersList(allUsersDetails.data.business_team);
        }
        else if (allUsersDetails.success == false) {
          setallUsersList([]);
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    // To get all services
    const requestOptionsServices = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'business_id': business_id, 'device_token': device_token,
        'session_id': session_id, 'category_type': "service"
      })
    };
    fetch(process.env.REACT_APP_GET_CATEGORIES, requestOptionsServices)
      .then((Response) => Response.json())
      .then(allServiceDetails => {
        console.log("allServiceDetails on same page", allServiceDetails.data.categories);
        if (allServiceDetails.data.categories) {
          setallServicesDetails(allServiceDetails.data.categories)
        }
        if (allServiceDetails.success == false) {
          setallServicesDetails([])
          Swal.fire({
            icon: 'error',
            text: allServiceDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    // To get all Roles
    const requestOptionsRoles = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'business_id': business_id, 'device_token': device_token,
        'session_id': session_id
      })
    };
    fetch(process.env.REACT_APP_GET_BUSINESS_ROLES, requestOptionsRoles)
      .then((Response) => Response.json())
      .then(allRoleDetails => {
        console.log("allRoleDetails on same page", allRoleDetails.data.roles);
        if (allRoleDetails.data.roles) {
          setallRolesDetails(allRoleDetails.data.roles)
        }
        if (allRoleDetails.success == false) {
          setallRolesDetails([])
          Swal.fire({
            icon: 'error',
            text: allRoleDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    // To get all Client Groups
    const requestOptionsClientGroups = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'business_id': business_id, 'device_token': device_token,
        'session_id': session_id
      })
    };
    fetch(process.env.REACT_APP_GET_BUSINESS_CLIENT_GROUPS, requestOptionsClientGroups)
      .then((Response) => Response.json())
      .then(allClientGroupsDetails => {
        console.log("allClientGroupsDetails on same page", allClientGroupsDetails.data.client_groups);
        if (allClientGroupsDetails.data.client_groups) {
          setallClientGroupDetails(allClientGroupsDetails.data.client_groups)
        }
        if (allClientGroupsDetails.success == false) {
          setallClientGroupDetails([])
          Swal.fire({
            icon: 'error',
            text: allClientGroupsDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    // to get all clients
    const requestOptionsClients = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'business_id': business_id, 'device_token': device_token,
        'session_id': session_id
      })
    };
    fetch(process.env.REACT_APP_GET_CLIENTS, requestOptionsClients)
      .then((Response) => Response.json())
      .then(clientDetails => {
        console.log("clientDetails on same page",clientDetails.data.clients);
        if (clientDetails.data.clients) {
          setClientDetails(
            clientDetails.data.clients.map((f) => {
              console.log("f is",f)
              if (f.client_type === 'person' ) 
              {
                f.fullNameOrOrg = f.first_name + ' ' + f.middle_name + ' ' + f.surname;
              }
              else if( f.client_type === 'business' )
              {
                f.fullNameOrOrg = f.org_name
              }
              return f;
            })
          );
        }
        if (clientDetails.success == false) {
          setClientDetails([])
          Swal.fire({
            icon: 'error',
            text: clientDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

  }, []);

  // case filter
  const handleFilterModalOpen = () => {
    setOpenFilter(true);
    dispatch(userActions.checkSessionValidity());
  };

  const handleFilterModalClose = () => {
    setOpenFilter(false);
    dispatch(userActions.checkSessionValidity());
  };

  const handleFilterModalReset = async () => {
    setMultiselectRefresh(false);
    setFilterIsApplied(false);
    setAssignUserFilter([]);

    setTagFilter([]);
    setServiceFilter([]);
    setClientsFilter([]);
    setRoleFilter([]);
    setClientGroupsFilter([]);

    setAssignUserFilterNames([]);
    setTagFilterNames([]);
    setServiceFilterNames([]);
    setClientsFilterNames([]);
    setRoleFilterNames([]);
    setClientGroupsFilterNames([]);
    // sort by
    await setSortByArray(
      SortByArray.map((f) => {
        f.selected = false;
        return f;
      })
    )
    // priority
    await setPriorityArray(
      PriorityArray.map((f) => {
        f.selected = false;
        return f;
      })
    )
    // tag
    await setallTagDetails(
      allTagDetails.map((f) => {
        f.selected = false;
        return f;
      })
    )
    // assigned people
    await setallUsersList(
      allUsersList.map((f) => {
        f.selected = false;
        return f;
      })
    )
    setMultiselectRefresh(true);
    dispatch(userActions.checkSessionValidity());
  }

  const handelSubmitApplyFilter = () => {
    dispatch(userActions.checkSessionValidity());
    setFilterIsApplied(true);
    setOpenFilter(false);
    // sort
    let SortBySelectedHere = "";
    SortByArray.map((f) => {
      if (f.selected == true) {
        console.log("true f is", f)
        SortBySelectedHere = f.value;
      }
    })
    console.log("SortBySelectedHere", SortBySelectedHere);
    //priority
    let PrioritySelectedHere = "";
    PriorityArray.map((f) => {
      if (f.selected == true) {
        console.log("true f is", f)
        PrioritySelectedHere = f.value;
      }
    })
    console.log("PrioritySelectedHere", PrioritySelectedHere);
    // tag
    const tagsForFilter = [];
    allTagDetails.map((f) => {
      if (f.selected == true) {
        console.log("true f is", f)
        tagsForFilter.push(f.tag_id);
      }
    })
    console.log("tagsForFilter is", tagsForFilter)
    // assigned people allUsersList
    const assignedPeopleForFilter = [];
    allUsersList.map((f) => {
      if (f.selected == true) {
        console.log("true f is", f)
        assignedPeopleForFilter.push(f.user_id);
      }
    })
    console.log("assignedPeopleForFilter is", assignedPeopleForFilter)

    let search_string = JSON.parse(localStorage.getItem('searchStringIs'));

    let per_page_here = 100;
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      // body: JSON.stringify({'search_str': searchResult, 'business_id': business_id, 
      // 'device_token': device_token, 'session_id': session_id,
      // 'sort_by': SortBySelectedHere, 'priority': PrioritySelectedHere, 'tags': tagsForFilter, 
      // 'assigned_users': assignedPeopleForFilter })
      body: JSON.stringify({
        'search_str': search_string, 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id,
        'sort_by': SortBySelectedHere, 'priority': PrioritySelectedHere, 'tags': TagFilter,
        'services': ServiceFilter, 'clients':ClientsFilter,
        // 'roles': RoleFilter, 'client_groups': ClientGroupsFilter,
        'assigned_users': AssignUserFilter, "client_type": "ALL",
        'page_number': 1, 'page_size': per_page_here
      })
    };

    fetch(process.env.REACT_APP_SEARCH_CASES, requestOptions)
      .then((Response) => Response.json())
      .then(async (SearchDetailsFilter) => {
        console.log("SearchDetailsFilter", SearchDetailsFilter.data);
        if (SearchDetailsFilter.data.search_results) {
          setPage(0)
          setRowsPerPage(per_page_here)
          console.log("SearchDetailsFilter", SearchDetailsFilter.data.search_results);
          setsearchListCases(SearchDetailsFilter.data.search_results.cases);

          setpaginationDetails(SearchDetailsFilter.data.pagination)


          // sort by
          await setSortByArray(
            SortByArray.map((f) => {
              f.selected = false;
              return f;
            })
          )
          // priority
          await setPriorityArray(
            PriorityArray.map((f) => {
              f.selected = false;
              return f;
            })
          )
          // tag
          await setallTagDetails(
            allTagDetails.map((f) => {
              f.selected = false;
              return f;
            })
          )
          // assigned people
          await setallUsersList(
            allUsersList.map((f) => {
              f.selected = false;
              return f;
            })
          )
        }
        if (SearchDetailsFilter.success == false) {
          setsearchListCases([])
          let total_cases_here = 0
          setpaginationDetails({ ...paginationDetails, total_cases: total_cases_here })
          Swal.fire({
            icon: 'error',
            text: SearchDetailsFilter.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        // setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  // sort
  const handleSortClick = async (item) => {
    console.log(item)
    // sort
    await setSortByArray(
      SortByArray.map((f) => {
        if (f.value === item.value) {
          f.selected = !f.selected;
        }
        else {
          f.selected = false;
        }
        return f;
      })
    )
  }
  // priority
  const handlePriorityClick = async (item) => {
    console.log(item)
    await setPriorityArray(
      PriorityArray.map((f) => {
        if (f.value === item.value) {
          f.selected = !f.selected;
        }
        else {
          f.selected = false;
        }
        return f;
      })
    )
  }
  // tag
  const handleFilterTagClick = async (item) => {
    // console.log("items in tag filter",item);
    await setallTagDetails(
      allTagDetails.map((f) => {
        if (f.tag_id === item.tag_id) {
          f.selected = !f.selected;
        }
        return f;
      })
    )
  }
  // assigned people
  const handleFilterAssignedPeopleClick = async (item) => {
    console.log("items in assigned people filter", item);
    await setallUsersList(
      allUsersList.map((f) => {
        if (f.user_id === item.user_id) {
          f.selected = !f.selected;
        }
        return f;
      })
    )
  }

  const bodyFilter = (
    <div className={classes.paperModalFilter}>
      <div >
        <Grid container item xs={12}>
          <Grid item xs={10} style={{ marginLeft: '5%' }}>
            <h2>Filter</h2>
          </Grid>
          <Grid item xs={1} style={{ marginTop: '3%', marginLeft: '1%' }}>
            <CloseIcon onClick={() => handleFilterModalClose()} style={{ cursor: 'pointer' }} />
          </Grid>
        </Grid>
      </div>
      <div className={classes.modalBodyFilters} style={{ position: 'absolute' }}>
        <Grid>
          {/* sort by */}
          {/* Sort by (can choose only one) */}
          <Grid container item xs={12}>
            <Grid container item xs={6}>
              <Grid item xs={12} className={classes.subHeadingLabel}>
                Sort by
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  {
                    SortByArray.map((item, index) => (
                      (item.selected == true) ?
                        (
                          <Grid item className={classes.marginRight}>
                            <Button
                              className={classes.textTransformNone}
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={() => handleSortClick(item)}
                            >
                              {item.name}
                            </Button>
                          </Grid>
                        ) :
                        (
                          <Grid item className={classes.marginRight}>
                            <Button
                              className={classes.textTransformNone}
                              size="small"
                              variant="outlined"
                              color="black"
                              onClick={() => handleSortClick(item)}
                            >
                              {item.name}
                            </Button>
                          </Grid>
                        )
                    ))
                  }
                </Grid>
              </Grid>
            </Grid>

            {/* Priority */}
            <Grid container item xs={6}>
              <Grid item xs={12} className={classes.subHeadingLabel}>
                {/* Priority (can choose only one) */}
                Priority
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  {
                    PriorityArray.map((item, index) => (
                      (item.selected == true) ?
                        (
                          <Grid item className={classes.marginRight}>
                            <Button
                              className={classes.textTransformNone}
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={() => handlePriorityClick(item)}
                            >
                              {item.name}
                            </Button>
                          </Grid>
                        ) :
                        (
                          <Grid item className={classes.marginRight}>
                            <Button
                              className={classes.textTransformNone}
                              size="small"
                              variant="outlined"
                              color="black"
                              onClick={() => handlePriorityClick(item)}
                            >
                              {item.name}
                            </Button>
                          </Grid>
                        )
                    ))
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>


          {/* Assigned people */}
          <Grid item xs={12} className={classes.subHeadingLabeltwo}>
            {/* Assigned people (can choose multiple) */}
            Assigned To
          </Grid>
          <Grid>
            {
              (MultiselectRefresh == true) ?
                (
                  <Multiselect
                    data={allUsersList}
                    defaultValue={AssignUserFilterNames}
                    placeholder="Select User"
                    valueField='user_id'
                    textField='name'
                    onChange={async (event) => {
                      console.log("event of multiselect", event);
                      var joined = [];
                      var joinedNames = [];
                      console.log("length : " + event.length);
                      if (event.length === 0) {
                        await setAssignUserFilter([])
                      }
                      event.map(async (data) => {
                        console.log("data in eve map : " + JSON.stringify(data));
                        joined = joined.concat(data.user_id);
                        // joinedNames = joined.concat(data.name);
                        joinedNames = joinedNames.concat(data);
                        console.log("data join : ", joined);
                        await setAssignUserFilter(joined)
                        await setAssignUserFilterNames(joinedNames)
                        console.log("check this AssignUserFilter", AssignUserFilter)
                      })
                    }}
                  />
                ) :
                (
                  <Grid></Grid>
                )
            }
          </Grid>

          {/* Tags */}
          <Grid item xs={12} className={classes.subHeadingLabeltwo}>
            {/* Tags (can choose multiple) */}
            Tags
          </Grid>
          <Grid>
            {
              (MultiselectRefresh == true) ?
                (
                  <Multiselect
                    data={allTagDetails}
                    defaultValue={TagFilterNames}
                    // defaultValue={TagFilter}
                    placeholder="Select Tags"
                    valueField='tag_id'
                    textField='name'
                    onChange={async (event) => {
                      console.log("event of multiselect", event);
                      var joined = [];
                      var joinedNames = [];
                      console.log("length : " + event.length);
                      if (event.length === 0) {
                        await setTagFilter([])
                      }
                      event.map(async (data) => {
                        console.log("data in eve map : " + JSON.stringify(data));
                        joined = joined.concat(data.tag_id);
                        joinedNames = joinedNames.concat(data);
                        console.log("data join : ", joined);
                        await setTagFilter(joined)
                        await setTagFilterNames(joinedNames)
                        console.log("check this TagFilter", TagFilter)
                      })
                    }}
                  />
                ) :
                (
                  <Grid></Grid>
                )
            }

            {/* {allTagDetails && allTagDetails.map((item, index) =>
            <Grid item className={classes.marginRight}>
              <Button
                className={classes.tagButtonStyle}
                size="small"
                variant="outlined"
                style={{backgroundColor: item.color, color: '#FFFFFF'}}
                endIcon={ item.selected == true ?  <CheckIcon/> : null }
                onClick={()=> handleFilterTagClick(item)}
              >
                {item.name}
              </Button>
            </Grid>
          )} */}
          </Grid>

          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} >
              {/* Priority */}
              <Grid item xs={12} className={classes.subHeadingLabeltwo}>
                {/* Priority (can choose only one) */}
                Services
              </Grid>
              <Grid item xs={12}>
                <Grid >
                  {
                    (MultiselectRefresh == true) ?
                      (
                        <Multiselect
                          data={allServicesDetails}
                          defaultValue={ServiceFilterNames}
                          placeholder="Select Services"
                          valueField='category_id'
                          textField='name'
                          onChange={async (event) => {
                            // console.log("event of multiselect",event);
                            var joined = [];
                            var joinedNames = [];
                            // console.log("length : "+event.length);
                            if (event.length === 0) {
                              await setServiceFilter([])
                            }
                            event.map(async (data) => {
                              // console.log("data in eve map : "+JSON.stringify(data));
                              joined = joined.concat(data.category_id);
                              joinedNames = joinedNames.concat(data);
                              // console.log("data join : ",joined);
                              await setServiceFilter(joined)
                              await setServiceFilterNames(joinedNames)
                              // console.log("check this TagFilter",TagFilter)
                            })
                          }}
                        />
                      ) :
                      (
                        <Grid></Grid>
                      )
                  }
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid item xs={6}>
              <Grid item xs={12} className={classes.subHeadingLabeltwo}>
                Roles
              </Grid>
              <Grid item xs={12}>
                <Grid >
                {
                  (MultiselectRefresh == true) ?
                    (
                      <Multiselect
                        data={allRolesDetails}
                        defaultValue={RoleFilterNames}
                        placeholder="Select Roles"
                        valueField='role_id'
                        textField='name'
                        onChange={async (event) => {
                          // console.log("event of multiselect",event);
                          var joined = [];
                          var joinedNames = [];
                          // console.log("length : "+event.length);
                          if (event.length === 0) {
                            await setRoleFilter([])
                          }
                          event.map(async (data) => {
                            // console.log("data in eve map : "+JSON.stringify(data));
                            joined = joined.concat(data.tag_id);
                            joinedNames = joinedNames.concat(data);
                            // console.log("data join : ",joined);
                            await setRoleFilter(joined)
                            await setRoleFilterNames(joinedNames)
                            // console.log("check this TagFilter",TagFilter)
                          })
                        }}
                      />
                    ) :
                    (
                      <Grid></Grid>
                    )
                }
                </Grid>
              </Grid>

            </Grid> */}

          </Grid>

          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} >
              <Grid item xs={12} className={classes.subHeadingLabeltwo}>
                Clients
              </Grid>
              <Grid item xs={12}>
                <Grid >
                {
                  (MultiselectRefresh == true) ?
                    (
                      <Multiselect
                        data={ClientDetails}
                        defaultValue={ClientsFilterNames}
                        placeholder="Select Clients"
                        valueField='client_id'
                        textField='fullNameOrOrg'
                        onChange={async (event) => {
                          var joined = [];
                          var joinedNames = [];
                          if (event.length === 0) {
                            await setClientsFilter([])
                          }
                          event.map(async (data) => {
                            joined = joined.concat(data.client_id);
                            joinedNames = joinedNames.concat(data);
                            await setClientsFilter(joined)
                            await setClientsFilterNames(joinedNames)
                          })
                        }}
                      />
                    ) :
                    (
                      <Grid></Grid>
                    )
                }
                </Grid>
              </Grid>
            </Grid>
        
          </Grid>

          {/* <Grid container item xs={12} spacing={1}>
            <Grid item xs={6}>
              <Grid item xs={12} className={classes.subHeadingLabeltwo}>
                Client Groups
              </Grid>
              <Grid item xs={12}>
                <Grid >
                {
                  (MultiselectRefresh == true) ?
                    (
                      <Multiselect
                        data={allClientGroupDetails}
                        defaultValue={ClientGroupsFilterNames}
                        placeholder="Select Groups"
                        valueField='client_group_id'
                        textField='name'
                        onChange={async (event) => {
                          // console.log("event of multiselect",event);
                          var joined = [];
                          var joinedNames = [];
                          // console.log("length : "+event.length);
                          if (event.length === 0) {
                            await setClientGroupsFilter([])
                          }
                          event.map(async (data) => {
                            // console.log("data in eve map : "+JSON.stringify(data));
                            joined = joined.concat(data.tag_id);
                            joinedNames = joinedNames.concat(data);
                            // console.log("data join : ",joined);
                            await setClientGroupsFilter(joined)
                            await setClientGroupsFilterNames(joinedNames)
                            // console.log("check this TagFilter",TagFilter)
                          })
                        }}
                      />
                    ) :
                    (
                      <Grid></Grid>
                    )
                }
                </Grid>
          </Grid>
            </Grid>

            <Grid item xs={6}>
            </Grid>
            
          </Grid> */}



          {/* Case Id Pattern */}
          {/* <Grid item xs={12} className={classes.subHeadingLabeltwo}>
          Case Id Pattern
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid> */}

          {/* Assigned people */}
          {/* <Grid item xs={12} className={classes.subHeadingLabeltwo}> */}
          {/* Assigned people (can choose multiple) */}
          {/* Assigned people
        </Grid>
        <Grid container style={{alignItems:'center', alignContent:'center',}}>
          {allUsersList && allUsersList.map((item, index) =>
            <Grid item className={classes.marginRight} style={{cursor:"pointer", marginTop:'2%',
            justifyContent: 'center',}}
              onClick={()=> handleFilterAssignedPeopleClick(item)}
            >
              <Avatar src="/static/images/avatar/1.jpg" />
              {
                  ( item.selected == true)?
                  (
                    <Grid style={{fontSize:15,backgroundColor:'#3765ad'}}>{item.name}</Grid>
                  ):
                  (
                    <Grid style={{fontSize:15, }}>{item.name}</Grid>
                  )
                }
            </Grid>
          )}
        </Grid> */}


        </Grid>

        <div style={{ display: 'flex', marginTop: '4%' }}>
          <div style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
            <Button
              type="submit"
              variant="contained"
              className={classes.submit}
              onClick={handleFilterModalReset}
            >
              Reset
            </Button>
          </div>
          <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', marginLeft: '39%' }}>
            <Button
              type="submit"
              variant="contained"
              className={classes.submit}
              onClick={handleFilterModalClose}
            >
              Cancel
            </Button>
          </div>
          {/* <div style={{marginRigthe:'-1%'}}></div> */}
          <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', marginLeft: '2%' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handelSubmitApplyFilter}
            >
              Apply Filters
            </Button>
          </div>
        </div>

      </div>
    </div>
  )
  const handleClickShowCaseDetailsForFilter = async (item) => {
    console.log("show case clicked is", item);
    await localStorage.setItem('navigated_toshow_case_from', JSON.stringify("SearchResult"));
    await localStorage.setItem('selected_case_for_details', JSON.stringify(item));
  }

  const handleRowClickOfTable = async (item) => {
    console.log("show case clicked is", item);
    await localStorage.setItem('navigated_toshow_case_from', JSON.stringify("SearchResult"));
    await localStorage.setItem('selected_case_for_details', JSON.stringify(item));
    // if( item.case_curr_state == "CONFIRMED"){
    if (item.case_curr_state == "IN-PROGRESS" || item.case_curr_state == "PENDING" ||
      item.case_curr_state == "PAYMENT-PENDING" || item.case_curr_state == "COMPLETED") {
      await setRedirectToTaxManagementCaseDetails(true);
    }
    else {
      Swal.fire({
        // icon: 'error',
        text: "Case is not Confirmed yet",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    }
  }

  useEffect(() => {
    setflag(true);
  }, [flag]);

  const handleClearFilter = async () => {
    setflag(false);
    setMultiselectRefresh(false);
    setFilterIsApplied(false);
    setAssignUserFilter([]);
    setTagFilter([]);
    setServiceFilter([]);
    setClientsFilter([]);
    setRoleFilter([]);
    setClientGroupsFilter([]);

    setAssignUserFilterNames([]);
    setTagFilterNames([]);
    setServiceFilterNames([]);
    setClientsFilterNames([]);
    setRoleFilterNames([]);
    setClientGroupsFilterNames([]);


    // sort by
    await setSortByArray(
      SortByArray.map((f) => {
        f.selected = false;
        return f;
      })
    )
    // priority
    await setPriorityArray(
      PriorityArray.map((f) => {
        f.selected = false;
        return f;
      })
    )
    // tag
    await setallTagDetails(
      allTagDetails.map((f) => {
        f.selected = false;
        return f;
      })
    )
    // assigned people
    await setallUsersList(
      allUsersList.map((f) => {
        f.selected = false;
        return f;
      })
    )
    setMultiselectRefresh(true);
    dispatch(userActions.checkSessionValidity());

    let search_string = JSON.parse(localStorage.getItem('searchStringIs'));

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      // body: JSON.stringify({'search_str': searchResult, 'business_id': business_id, 
      // 'device_token': device_token, 'session_id': session_id,
      // 'sort_by': SortBySelectedHere, 'priority': PrioritySelectedHere, 'tags': tagsForFilter, 
      // 'assigned_users': assignedPeopleForFilter })
      body: JSON.stringify({
        'search_str': search_string, 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id,
        'sort_by': "", 'priority': "", 'tags': "",
        'assigned_users': "", "client_type": "ALL"
      })
    };

    fetch(process.env.REACT_APP_SEARCH_CASES, requestOptions)
      .then((Response) => Response.json())
      .then(async (SearchDetailsFilter) => {
        // setShowProcedure(false);
        console.log("SearchDetailsFilter", SearchDetailsFilter.data);
        if (SearchDetailsFilter.data.search_results) {
          console.log("SearchDetailsFilter", SearchDetailsFilter.data.search_results);
          setsearchListCases(SearchDetailsFilter.data.search_results.cases);

          // sort by
          await setSortByArray(
            SortByArray.map((f) => {
              f.selected = false;
              return f;
            })
          )
          // priority
          await setPriorityArray(
            PriorityArray.map((f) => {
              f.selected = false;
              return f;
            })
          )
          // tag
          await setallTagDetails(
            allTagDetails.map((f) => {
              f.selected = false;
              return f;
            })
          )
          // assigned people
          await setallUsersList(
            allUsersList.map((f) => {
              f.selected = false;
              return f;
            })
          )
        }
        if (SearchDetailsFilter.success == false) {
          setsearchListCases([])
          Swal.fire({
            icon: 'error',
            text: SearchDetailsFilter.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        // setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));

  if (RedirectToTaxManagementCaseDetails == true) {
    return <Redirect to={`/TaxManagementCaseDetails`} />
  }
  else {
    return (
      console.log("searchResult in case details tab", searchResult),
      console.log("searchListCases", searchListCases),
      <div>

        <Grid>
          <Grid container justify="flex-end">
            <Grid >
              <Button
                variant="outlined"
                className={classes.customFilterLabel}
                startIcon={<FilterListIcon />}
                onClick={() => handleFilterModalOpen()}
              >
                {/* <FormattedMessage id="filtersLabel" /> */}
                Filter
              </Button>
            </Grid>
            <Grid>
              {
                (FilterIsApplied == true) ?
                  (
                    <Button
                      variant="outlined"
                      className={classes.customClearFilterLabel}
                      startIcon={<CloseIcon />}
                      onClick={() => handleClearFilter()}
                    >
                      Clear Filter
                    </Button>
                  ) :
                  (<Grid></Grid>)
              }
            </Grid>
          </Grid>
        </Grid>
        <Modal
          open={OpenFilter}
          onClose={handleFilterModalClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyFilter}
        </Modal>
        <Grid style={{ marginTop: '-2%' }}>
          <TableContainer className={classes.tableWrapper} component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                // rowCount={searchListCases && searchListCases.length}
                rowCount={paginationDetails && paginationDetails.total_cases}
              />
              <TableBody>
                {stableSort(searchListCases && searchListCases, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => {
                    console.log("row in table map", item)
                    // if(role_of_user == "client"){
                    return (
                      <TableRow
                        key={item.document_type_id}
                        hover={true} classes={{ hover: classes.hover }}
                        tabIndex={-1}
                        align="center"
                      >
                        <TableCell onClick={() => handleRowClickOfTable(item)} width="10%" align="center">{item.name}</TableCell>
                        {/* <TableCell width="10%" align="center">{item.process}</TableCell> 
                      <TableCell width="10%" align="center">{item.service}</TableCell>  */}
                        <TableCell onClick={() => handleRowClickOfTable(item)} width="10%" align="center">{item.description}</TableCell>
                        {/* <TableCell width="10%" align="center">{item.client_name}</TableCell> 
                      <TableCell width="10%" align="center">{item.client_pan}</TableCell> 
                      <TableCell width="10%" align="center">{item.priority}</TableCell>  */}
                        <TableCell onClick={() => handleRowClickOfTable(item)} width="10%" align="center">{item.case_curr_state}</TableCell>
                        <TableCell onClick={() => handleRowClickOfTable(item)} width="10%" align="center">{item.created_date}</TableCell>
                        <TableCell onClick={() => handleRowClickOfTable(item)} width="10%" align="center">{item.client_name}</TableCell>
                        <TableCell onClick={() => handleRowClickOfTable(item)} width="10%" align="center">{item.client_pan}</TableCell>

                        {
                          // ( item.case_curr_state == "CONFIRMED")?
                          (item.case_curr_state == "IN-PROGRESS" || item.case_curr_state == "PENDING" ||
                            item.case_curr_state == "PAYMENT-PENDING" || item.case_curr_state == "COMPLETED") ?
                            (
                              <TableCell width="5%" align="center" >
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  className={classes.button}
                                  href="/TaxManagementCaseDetails"
                                  onClick={() => handleClickShowCaseDetailsForFilter(item)}
                                >
                                  Show Case
                                </Button>
                              </TableCell>
                            )
                            : (
                              <TableCell width="5%" align="center" ></TableCell>
                            )
                        }
                      </TableRow>
                    );
                    // }
                    // else if(role_of_user == "trpadmin" || role_of_user == "trp" || role_of_user == "trpdoc"  ){
                    //   return (

                    //     <TableRow 
                    //       key={item.document_type_id}
                    //       hover={true} classes={{hover: classes.hover}}
                    //       hover
                    //       tabIndex={-1}
                    //       align="center"
                    //     >
                    //       <TableCell width="10%" align="center">{item.name}</TableCell>
                    //       <TableCell width="10%" align="center">{item.process}</TableCell> 
                    //       <TableCell width="10%" align="center">{item.service}</TableCell> 
                    //       <TableCell width="10%" align="center">{item.description}</TableCell>  
                    //       <TableCell width="10%" align="center">{item.client_name}</TableCell> 
                    //       <TableCell width="10%" align="center">{item.client_pan}</TableCell> 
                    //       <TableCell width="10%" align="center">{item.priority}</TableCell> 
                    //       <TableCell width="10%" align="center">{item.case_curr_state}</TableCell> 
                    //       <TableCell width="10%" align="center">{item.created_date}</TableCell> 

                    //     </TableRow>
                    //   );
                    // }
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[100, 50, 25, 10, { label: 'All', value: paginationDetails && paginationDetails.total_cases }]}
                    colSpan={8}
                    // count={searchListCases && searchListCases.length}
                    count={paginationDetails && paginationDetails.total_cases}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </div>
    );
  }
}

export default injectIntl(SearchViewCases);
