import React, {useParams, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ConfirmMessageNewClient from "../ConfirmMessageNewClient";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Link, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../actions";
import useCheckSessionApi from "../SessionValidity";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "react-phone-input-2/lib/material.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
rootOut: {
    flexGrow: 1,
  },
  wrapperOut: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },

  root: {
    display: "flex",
    justifyContent: "flex-end",
    background: "#ffffff",
  },
  wrapper: {
    width: "100%",
    marginLeft: "3%",
    marginRight: "3%",
    marginBottom: "1%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    backgroundColor: "#FFFFFF",
    boxShadow: theme.shadows[5],
    borderRadius: "5px",
    width: "300px",
    maxHeight: "480px",
    padding: theme.spacing(2, 4, 3),
    outline: "none",
  },
  subHeadingLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.96)",
    marginTop: "25px",
    marginBottom: "10px",
    textAlign: "left",
  },
  requestPANLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    color: "#4D47DD",
    marginTop: "5px",
    marginBottom: "35px",
    textAlign: "left",
    cursor: "pointer",
  },
  dividerStyle: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  checkboxCustomStyle: {
    textAlign: "right",
  },
  contactDetailsContainer: {
    alignItems: "center",
  },
  createButtonContainer: {
    marginTop: "2%",
  },
  messageBlock: {
    marginTop: "5%",
    marginBottom: "5%",
  },
  submit: {
    margin: "2%"
  },
  formControl: {
    // margin: theme.spacing(1),
    // marginTop: '4%',
    width: '100%',
    textAlign: "left",
  },
  validation: {
    marginTop: '0.5%',
    color: 'red',
    fontSize: 12,
    textAlign: "left",
  },
  navToAddCaseText: {
     color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: "600",
    textTransform: "none",
    marginTop: "5%",
    marginBottom: "2%",
    textAlign: "right", 
  },
  linkBackToCase: {
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: "600",
    textTransform: "none",
    marginTop: "5%",
    marginBottom: "2%",
    textAlign: "right",
  },
  linkNoUnderline: {
    textDecoration: "none",
  },
  backButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  backArrow: {
    marginRight: "10%",
  },
}));

export default function AddNewClient() {
  // const {oldpage} = useParams({});
  const classes = useStyles();
  const [clientDetails, setclientDetails] = React.useState({
    name: "",
    client_pan: "",
    email: "",
    phone: "",
    self_assessment: true,
    username: JSON.parse(localStorage.getItem("username")),
    business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  });
  const [yesNo, setyesNo] = React.useState([
    { value: true , name: 'Yes'  },
    { value: false , name: 'No'  },
  ]);
  const [nameErr, setnameErr] = React.useState();
  const [panErr, setpanErr] = React.useState();
  const [emailErr, setemailErr] = React.useState();
  const [phoneErr, setphoneErr] = React.useState();
  // const [selfAssErr, setselfAssErr] = React.useState();
  const [onlyCountryArray, setonlyCountryArray] = React.useState([
    'in','us', 'gb', 'ca', 'ae', 'sg','np', 'jp', 'de', 'bt', 'au'
  ]);
  const [CountryCode, setCountryCode] = React.useState();
  const [showPhoneIp, setshowPhoneIp] = React.useState(true);

  const [modalTagOpen, setModalTagOpen] = React.useState(false);
  const [RedirectToNewCase, setRedirectToNewCase] = React.useState(false);

  const [flag , setflag] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setflag(false);
    dispatch(userActions.checkSessionValidity());
    // readItemFromStorage();
  }, [flag]);

  // const readItemFromStorage = async() => {
  //     try {
  //     setDeviceToken(deviceTokenFromRedux)
  //     setSessionId(sessionIdFromRedux)
  //     setflag(false)    
  //     } catch (error) {
  //         console.log("ERROR:",error);
  //     }
  // };


  const handleModalOpen = () => {
    setModalTagOpen(true);
  };

  const handleModalClose = () => {
    setModalTagOpen(false);
  };

  const validate = () => {
    let nameErr  = '';
    let panErr = '';
    let emailErr = '';
    let phoneErr = '';
    // let selfAssErr = '';

    if(!clientDetails.name)  {
      nameErr = 'Please Enter Name';
    }

    if(!clientDetails.client_pan)  {
      panErr = 'Please Enter PAN';
    }
    else if (typeof clientDetails.client_pan !== "undefined") {
        var pattern = new RegExp(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/)
        if(clientDetails.client_pan.length !== 10){
          console.log("in != 10")
          // if(clientDetails.client_pan.length < 10){
          //   panErr = "PAN Number Contains Less than 10 Letters";
          // }
          // else if(clientDetails.client_pan.length > 10){
          //   panErr = "PAN Number Contains More than 10 Letters";
          // }
           panErr = "PAN Number is Not Valid";
        }
        else if (!pattern.test(clientDetails.client_pan)) {
          console.log("in string check")
          panErr = "PAN Number is Not Valid";
        } 
      }


    if(!clientDetails.email)  {
      emailErr = 'Please Enter Email';
    }
      else if(typeof clientDetails.email !== "undefined"){
            let lastAtPos = clientDetails.email.lastIndexOf('@');
            let lastDotPos = clientDetails.email.lastIndexOf('.');
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 )) {
                emailErr = "Email is not valid";
                if(emailErr) {
                  setemailErr(emailErr);
                }
            }
          else {
              setemailErr();    
            }
        }
      else{
            setemailErr();
      }

    if(!clientDetails.phone)  {
      phoneErr = 'Please Enter Phone';
    }
      else if (typeof clientDetails.phone !== "undefined") {
        var pattern = new RegExp(/^[0-9\b]+$/)

        // if (!pattern.test(clientDetails.phone)) {
        //   // isValid = false;
        //   phoneErr = "Please enter only number.";
        // }else if(clientDetails.phone.length != 10){
        //   // isValid = false;
        //   phoneErr = "Please enter valid phone number.";
        // }
        if (CountryCode == "in"){
          // if(clientDetails.phone.length !== 15){
            if(clientDetails.phone.length !== 14){
              phoneErr = "Please enter valid phone number.";
            }
        }
        else if(CountryCode == "us"){
          // if(clientDetails.phone.length !== 17){
            if(clientDetails.phone.length !== 13){
              phoneErr = "Please enter valid phone number.";
            }
        }
        else if(CountryCode == "gb"){
          console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(clientDetails.phone.length !== 14){
            phoneErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "ca"){
          console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(clientDetails.phone.length !== 13){
            phoneErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "ae"){
          console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(clientDetails.phone.length !== 17){
            phoneErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "sg"){
          console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(clientDetails.phone.length !== 12){
            phoneErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "np"){
          console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(clientDetails.phone.length !== 17){
            phoneErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "jp"){
          console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(clientDetails.phone.length !== 14){
            phoneErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "de"){
          console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(clientDetails.phone.length !== 16){
            phoneErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "bt"){
          console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(clientDetails.phone.length !== 17){
            phoneErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "au"){
          console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(clientDetails.phone.length !== 17){
            phoneErr = "Please enter valid phone number.";
          }
        }
      }

    // if(clientDetails.self_assessment  == "" && clientDetails.self_assessment !== false)  {
    //   selfAssErr = 'Please Select Self Assessment';
    // }


    if(nameErr || panErr || emailErr || phoneErr){
        setnameErr(nameErr);
        setpanErr(panErr);
        setemailErr(emailErr);
        setphoneErr(phoneErr);
        // setselfAssErr(selfAssErr);

      return false;
    }
    return true;
  }

  const handleCreateButton = () => {
    console.log("clientDetails", clientDetails);
    // let usersData = JSON.parse(localStorage.getItem("username"));
    // // let business_id = "1";
    // let business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));
    //   console.log("business_id",business_id)
    // let device_token = JSON.parse(localStorage.getItem('device_token'));
    // let session_id = JSON.parse(localStorage.getItem('session_id'));

    const isValid = validate();
      if(isValid){
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(clientDetails)
        };
        return fetch( process.env.REACT_APP_CREATE_CLIENT , requestOptions)
          .then((Response) => Response.json())
          .then(clientCreated => {
            console.log("clientCreated on same page",clientCreated.success);
            console.log("clientCreated on same page",clientCreated);
            if(clientCreated.success == true){
              setshowPhoneIp(false);
              Swal.fire({
                icon: 'success',
                text: "Client Created Succesfully",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK'
              })
              setclientDetails({
                name: "",
                client_pan: "",
                email: "",
                phone: "",
                self_assessment: true,
                username: JSON.parse(localStorage.getItem("username")),
                business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
                device_token: JSON.parse(localStorage.getItem('device_token')),
                session_id: JSON.parse(localStorage.getItem('session_id')),
              })
              setshowPhoneIp(true);
              setnameErr();
              setpanErr();
              setemailErr();
              setphoneErr();
              // setselfAssErr();

              setRedirectToNewCase(true);
            }
            if(clientCreated.success == false || clientCreated.success == null ){
              Swal.fire({
                icon: 'error',
                text: clientCreated.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
              setnameErr();
              setpanErr();
              setemailErr();
              setphoneErr();
              // setselfAssErr();
            }
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });
      }
  }

  const handleReset = async() => {
    await setshowPhoneIp(false);
    await setclientDetails({
      name: "",
      client_pan: "",
      email: "",
      phone: "",
      self_assessment: true,
      username: JSON.parse(localStorage.getItem("username")),
      business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    });
    setnameErr();
    setpanErr();
    setemailErr();
    setphoneErr();
    await setshowPhoneIp(true);
    // setselfAssErr();
  }

  const NavToNewCaseFunc = () =>{
    setRedirectToNewCase(true);
  }

  // const phoneNumberChange = (clientNumber,e) => {
  //   console.log("phoneNumberChange clientNumber",clientNumber)
  //   console.log("phoneNumberChange e",e.countryCode)
  //   setCountryCode(e.countryCode)
  //   setclientDetails({...clientDetails, phone:clientNumber })
  // }
  const phoneNumberChange = (clientNumber,e,formattedValue,value) => {
    console.log("phoneNumberChange clientNumber",clientNumber)
    console.log("phoneNumberChange e",e)
    console.log("phoneNumberChange value",value)
    let splitNoIs = ""
    if(e.countryCode == "in"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "us"){
      // splitNoIs = value.split("(")[1];
      splitNoIs = value.substr(value.indexOf(' ')+1);
    }
    if(e.countryCode == "gb"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "ca"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "ae"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "sg"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "np"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "jp"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "de"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "bt"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "au"){
      splitNoIs = value.split(" ")[1];
    }

    console.log("splitNoIs",splitNoIs)
    // if(formattedValue !== null){
    // console.log("formattedValue",formattedValue.target.value)
    // }
    let noHereIs = "+"+e.dialCode+"-"+splitNoIs;
    console.log("noHereIs",noHereIs)
    setCountryCode(e.countryCode)
    // setclientDetails({...clientDetails, phone:clientNumber })
    setclientDetails({...clientDetails, phone:noHereIs })
  }

  if( RedirectToNewCase == true){
      return <Redirect to={`/newcase`} />
  }
  else{
  return(
    // console.log("userparam is". useParams(),),
    <div className={classes.rootOut}>
    <div className={classes.wrapperOut}>
    <div className={classes.navToAddCaseText} > 
        {/* <Grid onClick={NavToNewCaseFunc}>
         Navigate to Add New Case
        </Grid> */}
        <Grid container style={{marginLeft:'-2.5%'}}>
          <Grid item xs={1} className={classes.pageHeaderLabel} >
            <Button href="/newcase" className={classes.backButton}>
                <span className={classes.backArrow}>
                <ArrowBackIcon fontSize="small" />
              </span>
              Back
            </Button>
          </Grid>
        </Grid>
        {/* <Grid container className={classes.linkBackToCase}>
            <Grid item xs={12}>
              <Link to="/newcase" className={classes.linkNoUnderline}>
                Navigate to Add New Case
              </Link>
            </Grid>
          </Grid> */}
    </div>
    <div className={classes.root} >
    <div className={classes.wrapper}>
      <Grid container>
        <Grid item xs={12} className={classes.subHeadingLabel}>
          Client Details
        </Grid>
        <Grid item xs={12} className={classes.dividerStyle}>
          <Divider />
        </Grid>
        {/* <Grid item xs={12} className={classes.subHeadingLabel}>
          Client Name *
        </Grid> */}
        <Grid item xs={12} style={{marginTop:'1%'}}>
          <TextField
            id="name"
            name="name"
            variant="outlined"
            autoFocus
            fullWidth
            required
            label="Client Name"
            value={clientDetails.name}
            onChange={(e) => setclientDetails({...clientDetails, name:e.target.value }) }
          />
          <div className={classes.validation}>{(clientDetails.name)?(<div></div>):(nameErr)}</div>
        </Grid>

        {/* <Grid item xs={12} className={classes.subHeadingLabel}>
          Pan Number *
        </Grid> */}
        <Grid item xs={12} style={{marginTop:'2%'}}>
          <TextField
            id="client_pan"
            name="client_pan"
            variant="outlined"
            // size="small"
            fullWidth
            required
            label="Pan Number"
            placeholder="XXXXX0000X"
            inputProps={{ maxLength: 10, style: { textTransform: "uppercase" } }}
            value={clientDetails.client_pan}
            onChange={(e) => setclientDetails({...clientDetails, client_pan:e.target.value.toUpperCase() }) }
          />
          {/* <div className={classes.validation}>{(clientDetails.client_pan)?(<div></div>):(panErr)}</div> */}
          <div className={classes.validation}>{panErr}</div>
        </Grid>
        {/* <Grid
          item
          xs={12}
          className={classes.requestPANLabel}
          onClick={handleModalOpen}
        >
          Request PAN?
        </Grid> */}

        {/* <Grid item xs={12} className={classes.subHeadingLabel}>
          <Grid container className={classes.contactDetailsContainer}>
            <Grid item xs={11}>
              Contact Details
            </Grid>
          </Grid>
        </Grid> */}
        {/* <Grid item xs={12} className={classes.dividerStyle}>
          <Divider />
        </Grid> */}

        {/* <Grid item xs={12} className={classes.subHeadingLabel}>
          Email Id *
        </Grid> */}
        <Grid item xs={12} style={{marginTop:'2%'}}>
          <TextField
            id="email"
            name="email"
            variant="outlined"
            // size="small"
            fullWidth
            required
            label="Email Id"
            inputProps={{ style: { textTransform: "lowercase" } }}
            value={clientDetails.email}
            onChange={(e) => setclientDetails({...clientDetails, email:e.target.value }) }
          />
          {/* <div className={classes.validation}>{(clientDetails.email)?(<div></div>):(emailErr)}</div> */}
          <div className={classes.validation}>{emailErr}</div>
        </Grid>

        {/* <Grid item xs={12} className={classes.subHeadingLabel}>
          Phone Number *
        </Grid> */}
        <Grid item xs={12} style={{marginTop:'2.5%'}}>
          {/* <TextField
            id="phone"
            name="phone"
            variant="outlined"
            size="small"
            fullWidth
            required
            value={clientDetails.phone}
            onChange={(e) => setclientDetails({...clientDetails, phone:e.target.value }) }
          /> */}
          {/* <div className={classes.validation}>{(clientDetails.phone)?(<div></div>):(phoneErr)}</div> */}
         {
           (showPhoneIp == true)?
           (
            <PhoneInput
              inputStyle={{
                width: "100%",
                height: "50px",
                fontSize: "15px",
              }}
              countryCodeEditable={false}
              onlyCountries={onlyCountryArray}
              specialLabel="Mobile Number"
              country={'in'}
              value={clientDetails.phone}
              masks={{in: '..........', us: '..........', gb: '..........', ca: '..........', ae:'............',
                          sg: '........', np:'............', jp:'..........', de:'............', bt:'............',
                          au: '.............'  }}
              // onChange={(clientNumber) => setclientDetails({...clientDetails, phone:clientNumber })}
              onChange={(clientNumber,e,formattedValue,value) => phoneNumberChange(clientNumber,e,formattedValue,value)}
            />
           )
           :(
             <Grid></Grid>
           )
         } 
          
          <div className={classes.validation}>{phoneErr}</div>
        </Grid>

        {/* <Grid item xs={12} className={classes.subHeadingLabel}>
          Self Assessment *
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
              <Select
                id="review"
                value={clientDetails.self_assessment}
                onChange={(e) => setclientDetails({...clientDetails, self_assessment:e.target.value }) }
              >
                {/* <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem> */}
                {/* {yesNo.map(item =>
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                )}
              </Select>
            </FormControl>
            <div className={classes.validation}>{(clientDetails.self_assessment == "" && clientDetails.self_assessment !== false)?(selfAssErr):(<div></div>)}</div>
        </Grid> */}

        <Grid item xs={12} className={classes.createButtonContainer}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleCreateButton}
          >
            Create
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            // color="primary"
            className={classes.submit}
            href="/newcase"
            // onClick={handleReset}
          >
            Cancel
          </Button>
        </Grid>

      </Grid>
    </div>
      {/* modal for pan card */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalTagOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableBackdropClick={true}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalTagOpen}>
          <div className={classes.paperModal}>
            <ConfirmMessageNewClient handleModalClose={handleModalClose} />
          </div>
        </Fade>
      </Modal>
    </div>
    </div>
    </div>
  )
  }
}