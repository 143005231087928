import React, {useEffect} from "react";
import Slide from "@material-ui/core/Slide";
import fetch from "cross-fetch";
import { injectIntl } from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage } from "react-intl";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import TaxManagementCaseDetails from "../TaxManagementCaseDetails";
import { modal } from 'reactstrap';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { PropagateLoader } from 'react-spinners';
import { userActions } from "../actions";
import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import Steps, { Step } from "rc-steps";
import "rc-steps/assets/index.css";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AntSwitch from '@material-ui/core/Switch';
import {updateProcessDetailsAPI, getServiceProcessesAPI, makeCategoryLiveAPI} from '../apis';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  pageHeaderContainer: {
    marginBottom: "5%",
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  CategoryLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
    marginLeft: "5%",
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  paperSteps: {
    padding: theme.spacing(2),
    height: '90%',
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginLeft:"2%",
    marginRight:"2%",
    overflowY:'scroll',
  },
  stepContainer: {
    textAlign: "left",
    color: "#4D47DD",
  },
  stateLableStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
    marginBottom:'2%',
  },
  TaskContainer: {
    textAlign: "left",
  },
  stepNameHeadingStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    marginTop:'4%',
  },
  TaskDetailsHeadingStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    marginTop:'1%',
    marginLeft: "2%",
  },
  TaskLabelStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    marginLeft: "4%",
    // marginTop:'1%',
  },
  questionContainer: {
    textAlign: "left",
  },
  questionsTypeHeadingStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    marginTop:'4%',
  },
  questionsTitleStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    marginTop:'2%',
  },
  questionsOptionStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    marginTop:'1%',
    marginLeft: '3%',
  },
  DocumentNameTitleStyleLable: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    marginTop: '2%',
  },
  DocumentDescriptionTitleStyleLable : {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    // marginTop: '2%',
  },
  personalBusinessDocHeadingStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    marginTop: '4%',
  },
  paperStepsHeading: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginLeft:"2%",
    marginRight:"2%",
  },
  paperStepsHeadingLabelStyle:{ 
    fontSize:18, 
    fontWeight:'600', 
    color:'black',
    marginTop:'-2%',
  },
  formControl: {
    // margin: theme.spacing(1),
    marginLeft: "5%",
    marginTop: '2%',
    width: '90%',
  },
  rootDescription: {
    display: "flex",
    justifyContent: "flex-end",
    minWidth: "500px",
    marginTop: "2px",
    marginBottom: "10px",
  },
  table: {
    minWidth: 400,
  },
  ServiceActionsLableStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    // marginTop: '4%',
  }
}));

function TaxManagementExplorer({ intl , props}) {
  const classes = useStyles();
  const[ShowProcedure, setShowProcedure ] = React.useState(false);
  const[business_id, setbusiness_id ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const[device_token, setdevice_token ] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const[session_id, setsession_id ] = React.useState(JSON.parse(localStorage.getItem('session_id')));

  const[superCategoryList, setsuperCategoryList] = React.useState([]);
  const[selectedSuperCategory, setselectedSuperCategory] = React.useState("");
  const[subCategoryList, setsubCategoryList] = React.useState([]);
  const[selectedSubCategory, setselectedSubCategory] = React.useState("");
  const[SubCtegoryFetched, setSubCtegoryFetched] = React.useState(false);
  const[CategorySelected, setCategorySelected] = React.useState(false);
  const[ServiceSelected, setServiceSelected] = React.useState(false);
  const[SelectedServicesId, setSelectedServicesId] = React.useState("");
  const[subCategoryListForSelectedCategory,setsubCategoryListForSelectedCategory] = React.useState([]);
  const[selectedServiceFromCategory, setselectedServiceFromCategory] = React.useState("");
  const[ProcessListForSelectedService,setProcessListForSelectedService] = React.useState([]);
  const[selectedProcess, setselectedProcess] = React.useState("");
  const[selectedProcessDetails, setselectedProcessDetails] = React.useState("");
  const[selectedServiceDetails, setselectedServiceDetails] = React.useState("");
  const[ProcessSelected, setProcessSelected] = React.useState(false);
  const[StepList,setStepList] = React.useState([]);
  const[CurrentIndexForSteps, setCurrentIndexForSteps] = React.useState();
  const[StateStaticList, setStateStaticList] = React.useState([
    {id: '1', name: 'NOT STARTED'},
    {id: '2', name: 'STARTED WORKING'},
    {id: '3', name: 'ON HOLD'},
    {id: '4', name: 'COMPLETED'},
  ]);
  const[QuestionList, setQuestionList] = React.useState([]);
  const[TaskList,setTaskList] = React.useState([]);
  const[DocTypeList, setDocTypeList] = React.useState([]);

  const [showShortcodeAndDescription, setshowShortcodeAndDescription] = React.useState(false);
  const [SelectedSuperCategoriesShortcode, setSelectedSuperCategoriesShortcode] = React.useState("");

  // redux
  const dispatch = useDispatch();

  let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
  // console.log("user_permissions from local storage on header page::::::",user_permissions)

  useEffect(()=>{
    console.log("Services list refresh");
  },[ProcessListForSelectedService]);

  useEffect(() => {
    dispatch(userActions.checkSessionValidity());

    localStorage.removeItem('view_clients_active_cases');
    
    let category_type = "supercategory"

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'category_type': category_type,'business_id':business_id,
      'device_token': device_token, 'session_id': session_id })
    };
    setShowProcedure(true);
    fetch( process.env.REACT_APP_GET_CATEGORIES , requestOptions)
      .then((Response) => Response.json())
      .then(superCategoryDetails => {
        setShowProcedure(false);
        console.log("super categoryDetails for category",superCategoryDetails.data.categories);
        if(superCategoryDetails.data.categories)
        {
          // setsuperCategoryList(superCategoryDetails.data.categories.filter(item => item.is_live == true ))
          setsuperCategoryList(superCategoryDetails.data.categories)
        }
        else
        {
          setsuperCategoryList([])
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

  }, []);

  const handleChangeSuprCategoryDropdown = (e) =>{

    // if(e.target.value){
      dispatch(userActions.checkSessionValidity());
    console.log("e in handleChangeSuprCategoryDropdown",e.target.value);
    setselectedSuperCategory(e.target.value);
    setshowShortcodeAndDescription(true)

    // if category changes
    setSubCtegoryFetched(false);
    setCategorySelected(false);
    setsubCategoryListForSelectedCategory([]);
    setServiceSelected(false);
    setSelectedServicesId("");
    setProcessListForSelectedService([]);
    setProcessSelected(false);
    setselectedProcess("");
    setselectedServiceFromCategory("");
    setselectedSubCategory("");
    setStepList([]);
    setQuestionList([]);
    setTaskList([]);

    // For shortcode and description
    superCategoryList && superCategoryList.map((item, index)=>{
      if(e.target.value === item.category_id){
        console.log("Same Category is found on select of super category",item)
        setSelectedSuperCategoriesShortcode(item.shortcode)
      }
    })
    // 
    
    

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'parent_category_id': e.target.value, 'business_id': business_id,
      'device_token': device_token, 'session_id': session_id })
    };
    setShowProcedure(true);
    fetch( process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY , requestOptions)
      .then((Response) => Response.json())
      .then( subCategoryDetails => {
        setShowProcedure(false);
        console.log("subCategoryDetails for list",subCategoryDetails.data.category_children);
        if(subCategoryDetails.data.category_children)
        {
          // setsubCategoryList(subCategoryDetails.data.category_children.filter(item => item.is_live == true ))
          setsubCategoryList(subCategoryDetails.data.category_children)
        }
        else
        {
          setsubCategoryList([])
        }
        setSubCtegoryFetched(true)
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
      setshowShortcodeAndDescription(true)
    // }
    // else{
    //   setshowShortcodeAndDescription(false)
    // }

     
  }

  const handleChangeCategoryOrServiceDropdown = (e) => {
    dispatch(userActions.checkSessionValidity());
    console.log("e",e.target.value);
    setselectedSubCategory(e.target.value)
    setsubCategoryListForSelectedCategory();
    setselectedServiceFromCategory("");
    setselectedProcess("");
    setServiceSelected(false);
    setSelectedServicesId("");
    setProcessSelected(false);
    setCategorySelected(false);
    setProcessSelected(false);
    setStepList([]);
    setQuestionList([]);
    setTaskList([]);
    

    let matchedCategory = "";
    subCategoryList.map((item, index) =>{
      if(e.target.value == item.category_id){
        // console.log("ok this is matched of selected item",item)
        matchedCategory = item;
      }
    })
    console.log("matchedCategory",matchedCategory)

    if(matchedCategory.category_type == "category"){
      setCategorySelected(true);
      setShowProcedure(true);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'parent_category_id': e.target.value, 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY , requestOptions)
      .then((Response) => Response.json())
      .then( subCategoryDetailsForSelectedCategory => {
        setShowProcedure(false);
        console.log("subCategoryDetailsForSelectedCategory for list",subCategoryDetailsForSelectedCategory.data.category_children);
        if(subCategoryDetailsForSelectedCategory.data.category_children)
        {
          // setsubCategoryListForSelectedCategory(subCategoryDetailsForSelectedCategory.data.category_children.filter(item => item.is_live == true ))
          setsubCategoryListForSelectedCategory(subCategoryDetailsForSelectedCategory.data.category_children)
        }
        else
        {
          setsubCategoryListForSelectedCategory([])
        }

      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    }

    if(matchedCategory.category_type == "service"){

      setselectedServiceDetails(matchedCategory);
      setServiceSelected(true);
      setSelectedServicesId(e.target.value)
      setShowProcedure(true);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            'service_id': e.target.value,
            'process_type': 'live',
            'business_id': business_id,
            'device_token': device_token, 
            'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_SERVICE_PROCESS_URL , requestOptions)
      .then((Response) => Response.json())
      .then( processDetails => {
        setShowProcedure(false);
        console.log("processDetails for list",processDetails.data);
        if(processDetails.data.processes)
        {
          setProcessListForSelectedService(processDetails.data.processes)
        }
        else
        {
          setProcessListForSelectedService([])
        }

      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    }
  }

  const handleChangeServiceDropdown = (e) => {
    console.log("e.target.value  in service change dropdown",e.target.value)
    dispatch(userActions.checkSessionValidity());
    console.log("e",e.target.value);
    setselectedServiceFromCategory(e.target.value)
    setselectedProcess("");
    setProcessSelected(false);
    setStepList([]);
    setQuestionList([]);
    setTaskList([]);
    let matchedService = "";
    subCategoryListForSelectedCategory.map((item, index) =>{
      if(e.target.value == item.category_id){
        console.log("ok this is matched of selected item",item)
        matchedService = item;
      }
    })
    console.log("matchedService",matchedService)
    setselectedServiceDetails(matchedService);

    if( e.target.value === ""){
      setServiceSelected(false);
      setSelectedServicesId("");
      
      setProcessSelected(false);
      setselectedProcess("");
      setStepList([]);
      setQuestionList([]);
      setTaskList([]);
    }



    if(matchedService.category_type == "service"){
      setServiceSelected(true);
      setSelectedServicesId(e.target.value)
      setShowProcedure(true);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            'service_id': e.target.value,
            'process_type': 'live' ,
            'business_id': business_id,
            'device_token': device_token, 
            'session_id': session_id 
        })
      };
      fetch( process.env.REACT_APP_GET_SERVICE_PROCESS_URL , requestOptions)
      .then((Response) => Response.json())
      .then( processDetails => {
        setShowProcedure(false);
        console.log("processDetails for list",processDetails.data);
        if(processDetails.data)
        {
          setProcessListForSelectedService(processDetails.data.processes)
        }
        else
        {
          setProcessListForSelectedService([])
        }

      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    }

  }

  const handleIsCurrentSwitch = async () => {
    dispatch(userActions.checkSessionValidity());
    console.log("in handleIsCurrentSwitch", selectedProcessDetails);
    if(selectedProcessDetails && !selectedProcessDetails.is_current) {

        const ProcessDetailsToSubmit = {
            service_id: selectedProcessDetails.service_id,
            shortcode: selectedProcessDetails.shortcode,
            name: selectedProcessDetails.name,
            description: selectedProcessDetails.description,
            status: selectedProcessDetails.status,
            is_current: true,
            version: selectedProcessDetails.version,
            // business_id: selectedProcessDetails.business_id,
            business_id: business_id,
            device_token: device_token,
            session_id: session_id,
            process_id : selectedProcessDetails.process_id,
        }

        const responseData = await updateProcessDetailsAPI(ProcessDetailsToSubmit).then((data) => {
            if(data.success == true){

                setselectedProcessDetails({...selectedProcessDetails, is_current: true});

                const serviceProcessData = {
                    'service_id': selectedProcessDetails.service_id,
                    'process_type': 'live',
                    'business_id': business_id,
                    'device_token': device_token, 
                    'session_id': session_id 
                }
                
                const serviceProcessesResponse = getServiceProcessesAPI(serviceProcessData).then((processData)=>{
                    if(processData.data.processes)
                    {
                      setProcessListForSelectedService(processData.data.processes)
                    }
                    else
                    {
                      setProcessListForSelectedService([])
                    }

                });

                Swal.fire({
                icon: 'success',
                text: "Successfully updated current process.",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK'
                })
            } else {
                Swal.fire({
                icon: 'error',
                text: data.errors.toString(),
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
                })
            }
            return true;
        });

    } else if(selectedProcessDetails && selectedProcessDetails.is_current) {
            Swal.fire({
            icon: 'error',
            text: "Process already current, pick another process and make it current.",
            confirmButtonColor: 'primary',
            confirmButtonText: 'OK'
            })
    }
  }

  const handleMakeServiceLive = async() => {
    dispatch(userActions.checkSessionValidity());
    console.log("in handleMakeServiceLive", selectedServiceDetails);
    if(selectedServiceDetails && !selectedServiceDetails.is_live){
        const serviceData =  {
            'category_id': selectedServiceDetails.category_id,
            'business_id': business_id,
            'device_token': device_token,
            'session_id': session_id,
        }
        const responseData = await makeCategoryLiveAPI(serviceData).then((data) => {
            if(data.success == true){
                setselectedServiceDetails({...selectedServiceDetails, is_live: true});
                Swal.fire({
                    icon: 'success',
                    text: "Successfully made the service live.",
                    confirmButtonColor: 'primary',
                    confirmButtonText: 'OK'
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    text: data.errors.toString(),
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                })
            }

        });

    } else {
            Swal.fire({
                icon: 'error',
                text: 'Service cannot be in inactive mode after being live.',
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })

    }

  }

  const handleChangeProcessDropdown = (e) => {
    dispatch(userActions.checkSessionValidity());
    if(e.target.value == ""){
      setselectedProcess("");
      setProcessSelected(false);
    }
    if(e.target.value !== ""){
      setselectedProcess(e.target.value);

    let selectedDropDownProcessDetails = ProcessListForSelectedService.filter((item)=>{
        return item.process_id == e.target.value;
        });
      if (selectedDropDownProcessDetails) {
        setselectedProcessDetails(selectedDropDownProcessDetails[0]);
      }
    setStepList([]);
    setQuestionList([]);
    setTaskList([]);
    let process_id_is = e.target.value;

    setProcessSelected(true);
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'process_id': parseInt(e.target.value),
        'business_id': business_id,
      'device_token': device_token, 'session_id': session_id })
    };
    setShowProcedure(true);
    fetch( process.env.REACT_APP_GET_PROCESS_STEPS , requestOptions)
      .then((Response) => Response.json())
      .then(stepDetails => {
        setShowProcedure(false);
        console.log("stepDetails for",stepDetails.data.steps_in_order);
        if(stepDetails.data.steps_in_order)
        {
          setStepList(stepDetails.data.steps_in_order)
          setCurrentIndexForSteps(stepDetails.data.steps_in_order.length)

          //to get questions and to do list
          functionGetTodoAndQuestions(process_id_is)

          //to get documents
          functionGetdocuments(process_id_is)
        }
        else
        {
          setStepList([])
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

  }

  const functionGetTodoAndQuestions = (process_id_from_func) => {
    dispatch(userActions.checkSessionValidity());
    console.log("in functionGetTodoAndQuestions ",process_id_from_func)
    // to get questions
    const requestOptionsQuestions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'service_id': SelectedServicesId,
      'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_QUESTIONS_FOR_CATEGORY , requestOptionsQuestions)
      .then((Response) => Response.json())
      .then(questionDetails => {
        // setShowProcedure(false);
        console.log("questionDetails for selected service",questionDetails.data.question_info);
        if(questionDetails.data.question_info)
        {
          setQuestionList(questionDetails.data.question_info)
        }
        else
        {
          setQuestionList([])
        }
      })
      .catch(err => {
        // setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    // to get todo
    const requestOptionstodo = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'process_id': parseInt(process_id_from_func), 'business_id': business_id,
      'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_PROCESS_TODO_LISTS , requestOptionstodo)
      .then((Response) => Response.json())
      .then(toDoDetails => {
        // setShowProcedure(false);
        console.log("toDoDetails for selected process",toDoDetails.data.process_step_todolist);
        if(toDoDetails.data.process_step_todolist)
        {
          setTaskList(toDoDetails.data.process_step_todolist)
        }
        else
        {
          setTaskList([])
        }
      })
      .catch(err => {
        // setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    }
  }

  const functionGetdocuments = (process_id_from_func) => {
    console.log("in functionGetTodoAndQuestions ",process_id_from_func)
    const requestOptionsDocTypes = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'service_id': SelectedServicesId,
        'business_id':business_id,
      'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_SERVICE_DOC_TYPES , requestOptionsDocTypes)
      .then((Response) => Response.json())
      .then(docTypeDetails => {
        // setShowProcedure(false);
        console.log("docTypeDetails for selected service",docTypeDetails.data.doc_types);
        if(docTypeDetails.data.doc_types)
        {
          // setDocTypeList(docTypeDetails.data.doc_types.filter(item => item.is_active == true))
          setDocTypeList(docTypeDetails.data.doc_types)
        }
        else
        {
          setDocTypeList([])
        }
      })
      .catch(err => {
        // setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  const showSuperCategoryDetails = () => {
    console.log("in showSuperCategoryDetails")
      return(
        <Grid>
          {
            superCategoryList && superCategoryList.map((item, index) => {
              console.log(" in map of superCategoryList for shortcode", item, selectedSuperCategory, JSON.parse(selectedSuperCategory))
              if (item.category_id === JSON.parse(selectedSuperCategory)) {

                <Grid>
                  {console.log("item after if is", item)}
                  {
                    (showShortcodeAndDescription === true) ?
                      (
                        <>
                        {console.log("item after if is second", item)}
                          <Grid>Shortcode: {item.shortcode}</Grid>
                          <Grid>Description: {item.description}</Grid>
                        </>
                      ) :
                      (<></>)
                  }

                </Grid>
              }
            })
          }

        </Grid>
      )
    
  }

  return (
    console.log("business_id,SelectedServicesId, selectedProcess",business_id,SelectedServicesId, selectedProcess),
    console.log("SelectedSuperCategoriesShortcode in return",SelectedSuperCategoriesShortcode),
    <div className={classes.root}>
      {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
        <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
      </modal>: null}
      <div className={classes.wrapper}>
          <Grid container className={classes.pageHeaderContainer} style={{marginTop:'1%'}}>
            <Grid item xs={12} className={classes.pageHeaderLabel}>
              Explorer
            </Grid>
          </Grid>

          <Paper className={classes.paper} style={{marginTop:'-2%'}}>
          <Grid container item xs={12}>

            {/* to select super category */}
            <Grid item xs={3}> 
              <Grid className={classes.CategoryLabel}>
                Super Category
              </Grid>
              <Grid>
                <FormControl variant="outlined" className={classes.formControl}>
                {/* <InputLabel id="demo-simple-select-filled-label">Category</InputLabel> */}
                <Select
                  native
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={selectedSuperCategory}
                  onChange = {(e) => handleChangeSuprCategoryDropdown(e)}
                >
                  <option value="" style={{color:'grey'}}>Select Category</option>
                    {superCategoryList && superCategoryList.map(item =>
                      <option value={item.category_id}>{item.name}</option>
                      )}
                </Select>
                </FormControl>
              </Grid>
              {/* <Grid>Shortcode: {SelectedSuperCategoriesShortcode && SelectedSuperCategoriesShortcode}</Grid> */}
              {/* {
                               (showShortcodeAndDescription === true)?
                             (
                                 <>
                                   <Grid>Shortcode: {SelectedSuperCategoriesShortcode && SelectedSuperCategoriesShortcode}</Grid>
                               </>
                               ):
                               (<></>)
                             } */}
              {
                (selectedSuperCategory)?
                (
                  // showSuperCategoryDetails()
                  <Grid>
                    {
                      superCategoryList && superCategoryList.map((item, index)=>{
                        console.log(" in map of superCategoryList for shortcode",item, selectedSuperCategory, JSON.parse(selectedSuperCategory))
                        if(item.category_id === JSON.parse(selectedSuperCategory)){
                          
                          <Grid>
                            {console.log("item after if is", item)}
                            {
                              (showShortcodeAndDescription === true)?
                              (
                                <>
                                  <Grid>Shortcode: {item.shortcode}</Grid>
                                  <Grid>Description: {item.description}</Grid>
                                </>
                              ):
                              (<></>)
                            }
                            
                          </Grid>
                        }
                      })
                    }
                    
                  </Grid>
                ):
                (
                  <></>
                )
              }
              
            </Grid>
            
            {
              ( SubCtegoryFetched == true )?
              (
                <Grid item xs={3}> 
                  <Grid className={classes.CategoryLabel}>
                    Category or Service
                  </Grid>  
                  <Grid>
                    <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      native
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={selectedSubCategory}
                      onChange = {(e) => handleChangeCategoryOrServiceDropdown(e)}
                    >
                      <option value="" style={{color:'grey'}}>Select Category or Service</option>
                        {subCategoryList && subCategoryList.map(item =>
                          <option value={item.category_id}>{item.name}</option>
                          )}
                    </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )
              :(<div></div>)
            }

            {
              (CategorySelected == true)? 
              (
                <Grid item xs={3}> 
                  <Grid className={classes.CategoryLabel}>
                    Service
                  </Grid>  
                  <Grid>
                    <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      native
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={selectedServiceFromCategory}
                      onChange = {(e) => handleChangeServiceDropdown(e)}
                    >
                      <option value="" style={{color:'grey'}}>Select Service</option>
                        {subCategoryListForSelectedCategory && subCategoryListForSelectedCategory.map(item =>
                          <option value={item.category_id}>{item.name}</option>
                          )}
                    </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )
              :(<div></div>)
            }

            {
              (ServiceSelected == true )?
              (
                <Grid item xs={3}>
                  <Grid className={classes.CategoryLabel}>
                    Process
                  </Grid>  
                  <Grid>
                    <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      native
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={selectedProcess}
                      onChange = {(e) => handleChangeProcessDropdown(e)}
                    >
                      <option value="" style={{color:'grey'}}>Select Process</option>
                        {ProcessListForSelectedService && ProcessListForSelectedService.map((item,index) =>
                          <option value={item.process_id}>{item.name} ({item.shortcode})</option>
                          )}
                    </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )
              :(<div></div>)
            }
            {/* <Grid item xs={3}> 4 </Grid> */}
          </Grid>
          </Paper>

          {/* show steps */}
          {
            (ProcessSelected == true)?
            (
              <Grid>
                  <Grid container spacing={3} item xs={12} style={{marginLeft:'1px'}}>
                    
                    {user_permissions && user_permissions.permissions.activate_service &&
                      <Grid item xs={6}  style={{marginTop: '1%'}}>
                        <Paper style={{padding: '2%', height:'95%',}}> 
                          <Grid container style={{alignItems:'center', marginLeft:'21%'}}>
                            <text className={classes.ServiceActionsLableStyle}>Service actions : </text>
                            <b> &nbsp; &nbsp; Live Status</b> &nbsp; &nbsp;
                            <AntSwitch
                            checked={selectedServiceDetails.is_live}
                            onChange={handleMakeServiceLive}
                            // onChange={(e) => setExistingProcessDetails({...ExistingProcessDetails, is_current: !ExistingProcessDetails.is_current }) }
                            size="small"
                            name="ExistingProcessDetails.is_current"
                            value="ExistingProcessDetails.is_current"
                            color="primary"
                            />
                          </Grid>
                        </Paper>
                    
                      </Grid>
                    }
                    
                    {user_permissions && user_permissions.permissions.activate_process &&
                      <Grid item xs={6} style={{marginTop: '1%',}}>
                        <Paper style={{padding: '2%', height:'95%'}}> 
                          <Grid container style={{alignItems:'center', marginLeft:'21%'}}>
                            <text className={classes.ServiceActionsLableStyle}>Process actions : </text>
                            {selectedProcessDetails && selectedProcessDetails.is_current && <h4 style={{color:'green'}}>Process is current.</h4>}
                            <span>
                                <b> &nbsp; &nbsp; Current</b> &nbsp; &nbsp;
                                <AntSwitch
                                checked={selectedProcessDetails.is_current}
                                onChange={handleIsCurrentSwitch}
                                // onChange={(e) => setExistingProcessDetails({...ExistingProcessDetails, is_current: !ExistingProcessDetails.is_current }) }
                                size="small"
                                name="selectedProcessDetails.is_current"
                                value="selectedProcessDetails.is_current"
                                color="primary"
                                /> 
                            </span>
                          </Grid>
                        </Paper>
                      </Grid>
                    }

                </Grid>

                <Grid container item xs={12} style={{marginTop:'1%'}}>
                  <Grid item xs={6}> 
                    <Grid>
                      <Paper className={classes.paperStepsHeading}>
                        <Grid className={classes.paperStepsHeadingLabelStyle}>Steps</Grid>
                      </Paper>
                    </Grid>
                    <Paper className={classes.paperSteps} >
                      <Grid  className={classes.stepContainer}>
                        <Steps direction="vertical" current={CurrentIndexForSteps && CurrentIndexForSteps} status="error" size="small">
                          {
                            StepList && StepList.map((i, index) =>(
                              <Step
                              title={
                                <div style={{ color: "#4D47DD" }}>
                                  {/* Step{index + 1}. {i.name} */}
                                  {index + 1}. {i.name}
                                </div>
                              }
                              subTitle={
                                <div style={{ marginLeft: '-8px', marginTop: '5px', color: "#000000", cursor: "pointer"}}
                                  id="stepIdDiv"
                                  key={i.step_id}
                                  value={i.step_id}
                                  // onClick={()=>StepClickedFunction(i.step_info.step_id, i)}
                                >
                                  {/* {i.description} */}
                                  <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                  <Typography className={classes.CategoryLabel}>Tasks</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                  {
                                    TaskList.map((item,index)=>(
                                      console.log("TaskList map item is",item),
                                      <Grid>
                                        {
                                          ( item.step_info.step_id == i.step_id)?
                                          (
                                            <Grid>
                                              {/* {
                                                item.step_todolist.map((itodo, indextodo)=>(
                                                  <Grid>
                                                    <Grid className={classes.TaskLabelStyle}>{indextodo + 1}. {itodo.item}</Grid>
                                                  </Grid>
                                                ))
                                              } */}
                                              {
                                                <Grid>
                                                  <Table className={classes.table} >
                                                      <TableHead>
                                                        <TableRow>
                                                          <TableCell align="center"
                                                            style={{fontWeight: 'bold', fontSize: 17}}
                                                          >
                                                            Name
                                                          </TableCell>
                                                          <TableCell align="center"
                                                            style={{fontWeight: 'bold', fontSize: 17}}
                                                          >
                                                            Required
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableHead>
                                                      <TableBody>
                                                       {item.step_todolist.filter(item => item.item_type == "business").map(row => (
                                                        // {item.step_todolist.map(row => (
                                                          console.log("row of task table",row),
                                                          <TableRow 
                                                            key={row.todolist_id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                            <TableCell align="center" component="th" scope="row">
                                                              {row.item}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                              {row.is_compulsory == true ? "Yes" : "No"}
                                                            </TableCell>
                                                          </TableRow>
                                                        ))}
                                                      </TableBody>
                                                    </Table>
                                                </Grid>
                                              }
                                            </Grid>
                                          ):
                                          (<div></div>)
                                        }
                                      </Grid>
                                    ))
                                  }
                                  </AccordionDetails>
                                  </Accordion>
                                  <Grid style={{marginTop:'4%'}}></Grid>
                                  <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                  <Typography className={classes.CategoryLabel}>User Defined Tasks</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                  {
                                    TaskList.map((item,index)=>(
                                      <Grid>
                                        {
                                          ( item.step_info.step_id == i.step_id)?
                                          (
                                            <Grid>
                                              {/* {
                                                item.step_todolist.map((itodo, indextodo)=>(
                                                  <Grid>
                                                    <Grid className={classes.TaskLabelStyle}>{indextodo + 1}. {itodo.item}</Grid>
                                                  </Grid>
                                                ))
                                              } */}
                                              {
                                                <Grid>
                                                  <Table className={classes.table} >
                                                      <TableHead>
                                                        <TableRow>
                                                          <TableCell align="center"
                                                            style={{fontWeight: 'bold', fontSize: 17}}
                                                          >
                                                            Name
                                                          </TableCell>
                                                          <TableCell align="center"
                                                            style={{fontWeight: 'bold', fontSize: 17}}
                                                          >
                                                            Required
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableHead>
                                                      <TableBody>
                                                        {/* {item.step_todolist.filter(item => item.item_type == "user").map(row => ( */}
                                                        {item.step_todolist.filter(item => item.item_type == "user").map(row => (
                                                        // {item.step_todolist.map(row => (
                                                          console.log("row of task table",row),
                                                          <TableRow 
                                                            key={row.todolist_id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                            <TableCell align="center" component="th" scope="row">
                                                              {row.item}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                              {row.is_compulsory == true ? "Yes" : "No"}
                                                            </TableCell>
                                                          </TableRow>
                                                        ))}
                                                      </TableBody>
                                                    </Table>
                                                </Grid>
                                              }
                                            </Grid>
                                          ):
                                          (<div></div>)
                                        }
                                      </Grid>
                                    ))
                                  }
                                  </AccordionDetails>
                                  </Accordion>
                                </div>
                              }
                              description={
                                <div className={classes.rootDescription}>

                                </div>
                              }
                            />
                            ))
                          }
                        </Steps>
                      </Grid>
                    </Paper>
                  </Grid>

                  <Grid item xs={6}> 
                    <Grid>
                      <Paper className={classes.paperStepsHeading}>
                        <Grid className={classes.paperStepsHeadingLabelStyle}>States</Grid>
                      </Paper>
                    </Grid>
                    <Paper className={classes.paperSteps}>
                      {/* <Grid>
                        {
                          StateStaticList.map((i,index)=>(
                            <Grid className={classes.stateLableStyle}>{i.id}. {i.name}</Grid>
                          ))
                        }
                      </Grid> */}
                      <Grid  className={classes.TaskContainer} style={{marginTop:'-5%'}}>
                        {
                          // TaskList.map((item,index)=>(
                            StepList.map((item,index)=>(
                            <Grid>
                              <Grid className={classes.stepNameHeadingStyle}>Step {index + 1}. {item.name}</Grid>
                              <Grid className={classes.TaskDetailsHeadingStyle}> State Details: </Grid>
                              {
                                StateStaticList.map((i,index)=>(
                                  <Grid className={classes.TaskLabelStyle}>{i.id}. {i.name}</Grid>
                                ))
                              }
                            </Grid>
                          ))
                        }
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>

                <Grid style={{marginTop:'5%'}}></Grid>

                <Grid container item xs={12} >
                  {/* <Grid item xs={6}> 
                    <Grid>
                      <Paper className={classes.paperStepsHeading}>
                        <Grid className={classes.paperStepsHeadingLabelStyle}>Tasks</Grid>
                      </Paper>
                    </Grid>
                    <Paper className={classes.paperSteps}>
                      <Grid  className={classes.TaskContainer} style={{marginTop:'-5%'}}>
                        {
                          TaskList.map((item,index)=>(
                            <Grid>
                              <Grid className={classes.stepNameHeadingStyle}>Step {index + 1}. {item.step_info.name}</Grid>
                              <Grid className={classes.TaskDetailsHeadingStyle}> Task Details: </Grid>
                              {
                                item.step_todolist.map((itodo, indextodo)=>(
                                  <Grid className={classes.TaskLabelStyle}>{indextodo + 1}. {itodo.item}</Grid>
                                ))
                              }
                            </Grid>
                          ))
                        }
                      </Grid>
                    </Paper>
                  </Grid> */}

                  <Grid item xs={6}> 
                    <Grid>
                      <Paper className={classes.paperStepsHeading}>
                        <Grid className={classes.paperStepsHeadingLabelStyle}>Questions</Grid>
                      </Paper>
                    </Grid>
                    <Paper className={classes.paperSteps}>
                      <Grid className={classes.questionContainer} style={{marginTop:'-5%'}}>

                        <Grid className={classes.questionsTypeHeadingStyle} >Descriptive Answers</Grid>
                        {
                          QuestionList.filter(item => item.question_type == "TEXT-ANSWER").map((item,index)=>(
                            <Grid className={classes.questionsTitleStyle}>{index + 1}. {item.title} {item.is_optional === false ? '*' : null }</Grid>
                          ))
                        }
                        <hr/>
                        <Grid className={classes.questionsTypeHeadingStyle} >Single Answers</Grid>
                        {
                          QuestionList.filter(item => item.question_type == "SINGLE-ANSWER").map((item,index)=>(
                            // console.log("SINGLE-ANSWER item",item),
                            <Grid>
                              <Grid className={classes.questionsTitleStyle}>{index + 1}. {item.title} {item.is_optional === false ? '*' : null }</Grid>
                              {
                                item.options.map((oi, oindex)=>(
                                  <Grid className={classes.questionsOptionStyle}>{oindex + 1}. {oi.option}</Grid>
                                ))
                              }
                            </Grid>
                          ))
                        }
                        <hr/>
                        <Grid className={classes.questionsTypeHeadingStyle} >Yes / No Type Answers</Grid>
                        {
                          QuestionList.filter(item => item.question_type == "BOOLEAN").map((item,index)=>(
                            <Grid className={classes.questionsTitleStyle}>{index + 1}. {item.title} {item.is_optional === false ? '*' : null }</Grid>
                          ))
                        }
                        <hr/>
                        <Grid className={classes.questionsTypeHeadingStyle} >Multiple Answers</Grid>
                        {
                          QuestionList.filter(item => item.question_type == "MULTIPLE-ANSWER").map((item,index)=>(
                            // console.log("MULTIPLE-ANSWER item",item),
                            <Grid>
                              <Grid className={classes.questionsTitleStyle}>{index + 1}. {item.title} {item.is_optional === false ? '*' : null }</Grid>
                              {
                                item.options.map((oi, oindex)=>(
                                  <Grid className={classes.questionsOptionStyle}>{oindex + 1}. {oi.option}</Grid>
                                ))
                              }
                            </Grid>
                          ))
                        }

                      </Grid>
                    </Paper>
                  </Grid>

                  <Grid item xs={6}> 
                    <Grid>
                      <Paper className={classes.paperStepsHeading}>
                        <Grid className={classes.paperStepsHeadingLabelStyle}>Documents</Grid>
                      </Paper>
                    </Grid>
                    <Paper className={classes.paperSteps}>
                      <Grid  className={classes.TaskContainer} style={{marginTop:'-5%'}}>
                        <Grid className={classes.personalBusinessDocHeadingStyle} >Personal Documents: </Grid>
                        {
                          DocTypeList.filter(item => item.doc_type_tag == "PERSONAL").map((item,index)=>(
                            <Grid>
                              <Grid container item xs={12} style={{height:'10%'}}>
                                {/* <Grid item xs={10} className={classes.questionsTitleStyle}>{index + 1}. {item.name}</Grid> */}
                                <Grid item xs={1} className={classes.DocumentNameTitleStyleLable}>Name:</Grid>
                                <Grid item xs={9} className={classes.questionsTitleStyle}>&nbsp;{item.name}{item.is_optional === false ? '*' : null }</Grid>
                              </Grid>
                              <Grid container item xs={12} style={{ height: '10%' }}>
                                <Grid item xs={2} className={classes.DocumentDescriptionTitleStyleLable}>Description:</Grid>
                                <Grid item xs={9} className={classes.DocumentDescriptionTitleStyle}>{item.description}</Grid>
                              </Grid>
                              <hr />
                            </Grid>
                          ))
                        }
                        {/* <hr/> */}
                        <Grid className={classes.personalBusinessDocHeadingStyle} >Business Documents: </Grid>
                        {
                          DocTypeList.filter(item => item.doc_type_tag == "BUSINESS").map((item,index)=>(
                            <Grid>
                              <Grid container item xs={12} style={{height:'10%'}}>
                                {/* <Grid item xs={10} className={classes.questionsTitleStyle}>{index + 1}. {item.name}</Grid> */}
                                <Grid item xs={1} className={classes.DocumentNameTitleStyleLable}>Name:</Grid>
                                <Grid item xs={9} className={classes.questionsTitleStyle}>&nbsp;{item.name}{item.is_optional === false ? '*' : null }</Grid>
                              </Grid>
                              <Grid container item xs={12} style={{ height: '10%' }}>
                                <Grid item xs={2} className={classes.DocumentDescriptionTitleStyleLable}>Description:</Grid>
                                <Grid item xs={9} className={classes.DocumentDescriptionTitleStyle}>{item.description}</Grid>
                              </Grid>
                              <hr />
                            </Grid>
                          ))
                        }
                      </Grid>
                    </Paper>
                  </Grid>

                </Grid>
                <div style={{marginTop:'10%'}}></div>
              </Grid>
            )
            :(<div></div>)
          }
          

      </div>
    </div>
  );
}

export default injectIntl(TaxManagementExplorer);
