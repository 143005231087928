import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
// import useCheckSessionApi from "../../SessionValidity";
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../../actions";
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import PersonIcon from "@material-ui/icons/Person";

const useStyles = makeStyles((theme) => ({
  cursorPointer: {
    cursor: "pointer"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    marginRight:"5%",
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  moreVertIcon:{
    textAlign: "right",
  },
  personIconColor: {
      color: 'blue',
  },
  userAddUpdateDelete: {
      marginTop: '4%',
      fontSize: 11,
      color: 'black',
      cursor: "pointer",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    // height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'15%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
  },
  modalBodyBulk: {
    padding: theme.spacing(7, 4, 3,), 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  paperModalBulk: {
    position: 'absolute',
    width: 500,
    // height: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  validationBulk: {
    marginTop: '2%',
    color: 'red',
    fontSize: 12,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },

}));


export default function AnswerDocumentLink(questionListDropdown ) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [answerDocumentLinkDetails, setanswerDocumentLinkDetails] = React.useState({
        document_type_id: "",
        bool_question_id: "",
        bool_answer: "",
        option_document_link_id: "",
        business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
    });
    const [documentTypesDropdown, setdocumentTypesDropdown ] = React.useState([]);
    const [selectedQueType, setselectedQueType] = React.useState();
    const [OptionDetails, setOptionDetails] = React.useState([]);
    const [trueFalse, settrueFalse] = React.useState([
      { value: true , name: 'True'  },
      { value: false , name: 'False'  },
    ]);

    const [docTypeErr, setdocTypeErr] = React.useState();
    const [questionIdErr, setquestionIdErr] = React.useState();
    const [boolAnsErr, setboolAnsErr] = React.useState();
    const [optionIdErr, setoptionIdErr] = React.useState();

    const [openBulk, setopenBulk] = React.useState(false);
    const [ExcelFile, setExcelFile] = React.useState();
    const [ExcelName, setExcelName] = React.useState();
    const [fileErr, setfileErr] = React.useState();

    const [ ShowProcedure, setShowProcedure ] = React.useState(false);

    const [flag, setflag] = React.useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      setflag(true);
      // dispatch(userActions.checkSessionValidity());
      // readItemFromStorage();
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      let device_token = JSON.parse(localStorage.getItem('device_token'));
      let session_id = JSON.parse(localStorage.getItem('session_id'));

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'business_id':business_id,
          'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_ALL_DOC_TYPES , requestOptions)
        .then((Response) => Response.json())
        .then(documentTypesDetails => {
            console.log("documentTypesDetails on same page",documentTypesDetails.data.doc_types);
            if(documentTypesDetails.data.doc_types)
            {
              setdocumentTypesDropdown(documentTypesDetails.data.doc_types)
            }
            if(documentTypesDetails.success == false){
              setdocumentTypesDropdown([])
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }); 

    }, [flag, open, openBulk]);

    // const readItemFromStorage = async() => {
    //   try {
    //     setDeviceToken(deviceTokenFromRedux)
    //     setSessionId(sessionIdFromRedux)
    //     setflag(false)    
    //   } catch (error) {
    //     console.log("ERROR:",error);
    //   }
    // };

    const handelCreateAnsweDocumentLinkOpen = () => {
      setOpen(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateAnsweDocumentLinkClose = () => {
      setOpen(false);
      setanswerDocumentLinkDetails({
        document_type_id: "",
        bool_question_id: "",
        bool_answer: "",
        option_document_link_id: "",
        business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      setdocTypeErr();
      setquestionIdErr();
      setboolAnsErr();
      setoptionIdErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateAnsweDocumentLinkClear = async() => {
      await setanswerDocumentLinkDetails({
        document_type_id: "",
        bool_question_id: "",
        bool_answer: "",
        option_document_link_id: "",
        business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      setOpen(false);
      setOpen(true);
      setdocTypeErr();
      setquestionIdErr();
      setboolAnsErr();
      setoptionIdErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateAnsweDocumentLinkMinimize = () => {
      setOpen(false);
      dispatch(userActions.checkSessionValidity());
    }

    const validate = () => {
      let docTypeErr = '';
      let questionIdErr = '';
      let boolAnsErr = '';
      let optionIdErr = '';

      if(!answerDocumentLinkDetails.document_type_id)  {
        docTypeErr = 'Please Select Document Type';
      }
      if(!answerDocumentLinkDetails.bool_question_id)  {
        questionIdErr = 'Please Select Document Type';
      }

      if(selectedQueType == "BOOLEAN"){
        if(answerDocumentLinkDetails.bool_answer == "" && answerDocumentLinkDetails.bool_answer !== false && answerDocumentLinkDetails.bool_answer !== true)  {
            boolAnsErr = 'Please Select Answer';
        }
      }

      if(selectedQueType == "SINGLE-ANSWER" || selectedQueType == "MULTIPLE-ANSWER"){
        if(!answerDocumentLinkDetails.option_document_link_id)  {
            optionIdErr = 'Please Select Option';
        }
      }

      if(docTypeErr || questionIdErr || boolAnsErr || optionIdErr ){
        setdocTypeErr(docTypeErr);
        setquestionIdErr(questionIdErr);
        setboolAnsErr(boolAnsErr);
        setoptionIdErr(optionIdErr);

        return false;
      }
      return true;
    }

    const handelSubmitCreateAnserDocumentLink = () => {
      console.log("answerDocumentLinkDetails",answerDocumentLinkDetails);
      dispatch(userActions.checkSessionValidity());
      const isValid = validate();
      if(isValid){
        setShowProcedure(true);
        setOpen(false);
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(answerDocumentLinkDetails)
          };
          return fetch('https://api.trpflow.com/documents/createanswerdocumentlink/', requestOptions)
              .then((Response) => Response.json())
              .then(answerDocumentLinkCreated => { 
                  setShowProcedure(false);                
                  console.log("answerDocumentLinkCreated on same page",answerDocumentLinkCreated.success);
                  console.log("answerDocumentLinkCreated on same page",answerDocumentLinkCreated);
                  if(answerDocumentLinkCreated.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Answer Document Link Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    
                    setanswerDocumentLinkDetails({
                      document_type_id: "",
                      bool_question_id: "",
                      bool_answer: "",
                      option_document_link_id: "",
                      business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
                      device_token: JSON.parse(localStorage.getItem('device_token')),
                      session_id: JSON.parse(localStorage.getItem('session_id')),
                    })
                    setdocTypeErr();
                    setquestionIdErr();
                    setboolAnsErr();
                    setoptionIdErr();
                  }
                  if(answerDocumentLinkCreated.success == false || answerDocumentLinkCreated.success == null ){
                    Swal.fire({
                      icon: 'error',
                      text: answerDocumentLinkCreated.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setOpen(true);
                    })
                }
              })
              .catch(err => {
                setShowProcedure(false);
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Server Error. Please try again.",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              });
      }
    }

    const handelChangeQuestion = (e) => {
      console.log("e",e)
      setanswerDocumentLinkDetails({...answerDocumentLinkDetails, bool_question_id:e.target.value })
      questionListDropdown.questionListDropdown.map( async(item) =>{
        if( item.id == e.target.value){
          console.log("id is matched",item)
          console.log("qtype",item.qtype)
          return(
            await setselectedQueType(item.qtype),
            await setOptionDetails(item.options)
          )
          
        }
      })
    }

    const body = (
      <div className={classes.paperModal}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'25%'}}>Create Answer Document</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelCreateAnsweDocumentLinkMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelCreateAnsweDocumentLinkClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBody}>           
            <FormControl className={classes.formControl}>
              {/* <InputLabel id="document_type_id">Document Type*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Document Type*</Grid>
              <Select
                native
                labelId="document_type_id"
                id="document_type_id"
                value={answerDocumentLinkDetails.document_type_id}
                onChange={(e) =>setanswerDocumentLinkDetails({...answerDocumentLinkDetails, document_type_id:e.target.value }) }
              >
                {/* <MenuItem value={''}>Select Document Type</MenuItem>
                {documentTypesDropdown.map(item =>
                  <MenuItem value={item.document_type_id} >{item.name}</MenuItem>
                )} */}
                <option value="" style={{color:'grey'}}>Select Category or Super Category</option>
                  {documentTypesDropdown.map(item =>
                    <option value={item.document_type_id}>{item.name}</option>
                )}
              </Select>
            </FormControl>
            <div className={classes.validation}>{(answerDocumentLinkDetails.document_type_id)?(<div></div>):(docTypeErr)}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="bool_question_id">Question*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Question*</Grid>
              <Select
                native
                labelId="bool_question_id"
                id="bool_question_id"
                value={answerDocumentLinkDetails.bool_question_id}
                onChange={handelChangeQuestion}
              >
                {/* <MenuItem value={''}>Select Question</MenuItem>
                {questionListDropdown.questionListDropdown.filter(item => item.qtype !== "TEXT-ANSWER").map(item =>
                  <MenuItem value={item.id} >{item.question}</MenuItem>
                )} */}
                <option value="" style={{color:'grey'}}>Select Question</option>
                  {questionListDropdown.questionListDropdown.filter(item => item.qtype !== "TEXT-ANSWER").map(item =>
                    <option value={item.id}>{item.question}</option>
                )}
              </Select>
            </FormControl>
            <div className={classes.validation}>{(answerDocumentLinkDetails.bool_question_id)?(<div></div>):(questionIdErr)}</div>

            {
              ( selectedQueType == "BOOLEAN" ) ? 
              (
                // <div>selected question type is boolean</div>
                <div>                
                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="bool_answer">Boolean Answer*</InputLabel> */}
                    <Grid style={{marginTop:"2%", color:"grey"}}>Boolean Answer*</Grid>
                    <Grid container item xs={12} style={{marginTop:"1%"}}>
                      <RadioGroup
                        row
                        id ="bool_answer"
                        aria-label="bool_answer"
                        name="bool_answer"
                        defaultValue={JSON.stringify(answerDocumentLinkDetails.bool_answer)}
                        onChange={ (e) => setanswerDocumentLinkDetails({...answerDocumentLinkDetails, bool_answer: JSON.parse(e.target.value) })}
                      >                  
                        <div>
                          <FormControlLabel
                            value="true"
                            classes={{ label: classes.label }}
                            control={<Radio size="small" color="primary" />}
                            label= "Yes"
                          />
                        </div>
                        <div>
                          <FormControlLabel
                            value="false"
                            classes={{ label: classes.label }}
                            control={<Radio size="small" color="primary" />}
                            label= "No"
                          />
                        </div>
                      </RadioGroup>
                    </Grid>
                    {/* <Select
                      labelId="bool_answer"
                      id="bool_answer"
                      value={answerDocumentLinkDetails.bool_answer}
                      onChange={(e) => setanswerDocumentLinkDetails({...answerDocumentLinkDetails, bool_answer:e.target.value }) }
                    >
                      {trueFalse.map(item =>
                        <MenuItem value={item.value}>{item.name}</MenuItem>
                      )}
                    </Select> */}
                  </FormControl>
                  <div className={classes.validation}>{(answerDocumentLinkDetails.bool_answer == "" && answerDocumentLinkDetails.bool_answer !== false && answerDocumentLinkDetails.bool_answer !== true)?(boolAnsErr):(<div></div>)}</div>
                  
                </div>
              ): 
              (
                ( selectedQueType == "SINGLE-ANSWER" || selectedQueType == "MULTIPLE-ANSWER" ) ? 
                (
                  // <div>selected question type is SINGLE-ANSWER</div>
                  <div>
                    <FormControl className={classes.formControl}>
                      {/* <InputLabel id="option_document_link_id">Question Option Id*</InputLabel> */}
                      <Grid style={{marginTop:"2%", color:"grey"}}>Question Option Id*</Grid>
                      <Select
                        native
                        labelId="option_document_link_id"
                        id="option_document_link_id"
                        value={answerDocumentLinkDetails.option_document_link_id}
                        onChange={(e) => setanswerDocumentLinkDetails({...answerDocumentLinkDetails, option_document_link_id:e.target.value }) }
                      >
                        {/* <MenuItem value={''}>Select Option</MenuItem>
                        {OptionDetails && OptionDetails.map(item =>
                          <MenuItem value={item.option_id}>{item.option}</MenuItem>
                        )} */}
                        <option value="" style={{color:'grey'}}>Select Option</option>
                          {OptionDetails && OptionDetails.map(item =>
                            <option value={item.option_id}>{item.option}</option>
                        )}
                      </Select>
                    </FormControl>
                  <div className={classes.validation}>{(answerDocumentLinkDetails.option_document_link_id)?(<div></div>):(optionIdErr)}</div>
                    
                  </div>
                ):
                (<div></div>)
              )
            }

            <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
                <Button
                type="submit"
                //   fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handelSubmitCreateAnserDocumentLink}
                >
                Create
                </Button>
                <div style={{marginLeft:'2%'}}></div>
                <Button
                    type="submit"
                    variant="contained"
                    // color="grey"
                    className={classes.submit}
                    onClick={handelCreateAnsweDocumentLinkClear}
                >
                Clear
                </Button>
            </div>
          </div>
      </div>
    )

    const handelAnswerDocumentBulkOpen = () => {
      setopenBulk(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelAnswerDocumentBulkClose = () => {
      setopenBulk(false);
      setfileErr();
      setExcelFile();
      setExcelName();
      dispatch(userActions.checkSessionValidity());
    }

    const handelAnswerDocumentBulkClear = () => {
      setfileErr();
      setExcelFile();
      setExcelName();
      dispatch(userActions.checkSessionValidity());
    }

    const handelAnswerDocumentBulkMinimize = () => {
      setopenBulk(false);
      dispatch(userActions.checkSessionValidity());
    }

    const onUploadExcel= (event) => {
        console.log("event.target.files[0]",event.target.files[0])
        console.log("event.target.files[0] name",event.target.files[0].name)
        setExcelName(event.target.files[0].name);
        setExcelFile(event.target.files[0]);
        dispatch(userActions.checkSessionValidity());
    }

    const validateBulk = () => {
      let fileErr = '';

      if(!ExcelFile)  {
          fileErr = 'Please Select File';
      }

      if(fileErr ){
        setfileErr(fileErr);

        return false;
      }
      return true;
    }

    const handelSubmitAnswerDocumentBulk = () => {
      dispatch(userActions.checkSessionValidity());
      let users = JSON.parse(localStorage.getItem('users'));
      let username = users.data.user_details.username
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      // console.log("username in category bulk",username);
      // console.log("business_id in category bulk",business_id);
      // console.log("ExcelFile in category bulk",ExcelFile);

      const isValid = validateBulk();
      if(isValid){
        setShowProcedure(true);
        setopenBulk(false);
        let formdata = new FormData();
        formdata.append('business_id', business_id);
        formdata.append('username', username);
        formdata.append('excel_file', ExcelFile);
        // const requestOptions = {
        // method: 'POST',
        // headers: authHeader(),
        // body: formdata,
        // };
        let user = JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
          method: 'POST',
          headers:  {
            Authorization: "Token " + user.key,
          },
          body: formdata,
        };
        return fetch('https://api.trpflow.com/documents/bulkcreateanswersdocumentslink/', requestOptions)
            .then((Response) => Response.json())
            .then( answerDocumentBulk => {
                setShowProcedure(false);
                console.log("answerDocumentBulk on same page",answerDocumentBulk.success);
                  console.log("answerDocumentBulk on same page",answerDocumentBulk);
                  if(answerDocumentBulk.success == true){                   
                    Swal.fire({
                      icon: 'success',
                      text: "Category Bulk Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    setExcelFile();
                    setExcelName();
                    setfileErr();
                  }
                  if(answerDocumentBulk.success == false){                   
                    Swal.fire({
                      icon: 'error',
                      text: answerDocumentBulk.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setopenBulk(true);
                    })
                }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
    }

    const bulkBody = (
        <div className={classes.paperModalBulk}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'28%'}}>Answer Documnet Bulk</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelAnswerDocumentBulkMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelAnswerDocumentBulkClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBodyBulk}>
          {/* <div>
            <input type='file' 
              accept=".xlsx"
              id="myBulk"
              onChange={ (e) => onUploadExcel(e)} 
            />
          </div> */}
          <Grid container>
            <Grid item xs={3}>
              <input type='file' 
                // id="files"
                // accept=".xlsx"
                id="myBulk"
                onChange={ (e) => onUploadExcel(e)}  
                hidden
              />
              <label for="myBulk" style={{backgroundColor: '#cbd4cd', height:8, width:40, padding:10, borderRadius:5,
                cursor: "pointer"}}>Select file</label>
            </Grid>
            <Grid item xs={6} style={{marginLeft:'-2%'}}>
              {
                ( ExcelName)?
                (
                  <div >
                    {/* <a 
                      href={this.state.BiographyResumeFileName} target = "_blank"> */}
                      <text>{ExcelName}</text>
                    {/* </a> */}
                  </div>
                ):
                (
                  <div>
                    No file Choosen 
                  </div>
                )
              }
            </Grid>         
          </Grid>
          <div className={classes.validationBulk}>{(ExcelFile)?(<div></div>):(fileErr)}</div>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitAnswerDocumentBulk}
          >
            Create Bulk
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handelAnswerDocumentBulkClear}
          >
            Clear
          </Button>
        </div>
        </div>
        </div>
    )

  return(
    <Grid item xs={4}>
        <div>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        </div>
        <Paper className={classes.paper}>
            <Grid container>
                <Grid item xs={1} className={classes.moreVertIcon}>
                    <PersonIcon className={classes.personIconColor}/>
                </Grid>
                <Grid item xs={6} style={{marginLeft:'2%', color:'black'}}>
                     Answer document Link List
                </Grid>
            </Grid>
            <hr/>
            <Grid container>
                <Grid item xs={12} className={classes.userAddUpdateDelete}>                   
                  <Link to="AnswerDocumentLinkList" style={{ textDecoration: 'none', color:'black' }}>
                    Answer Document Link List
                  </Link>
                </Grid>
                <Grid item xs={12} onClick={handelCreateAnsweDocumentLinkOpen} className={classes.userAddUpdateDelete}>
                    Create Answer Document Link
                </Grid>
                <Modal
                    open={open}
                    onClose={handelCreateAnsweDocumentLinkClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>
                <Grid item xs={12} onClick={handelAnswerDocumentBulkOpen} className={classes.userAddUpdateDelete}>
                    Bulk Create Answer Documents Link
                </Grid>
                <Modal
                    open={openBulk}
                    onClose={handelAnswerDocumentBulkClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {bulkBody}
                </Modal>
            </Grid>
        </Paper>
    </Grid>
  )
}
