// RolesRoleBased
import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../actions";
import useCheckSessionApi from "../SessionValidity";
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import Button from "@material-ui/core/Button";
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import AddIcon from "@material-ui/icons/Add";
import MinimizeIcon from '@material-ui/icons/Minimize';
import TextField from "@material-ui/core/TextField";

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TableSortLabel from '@material-ui/core/TableSortLabel';

moment.defaultFormat = "DD/MM/YYYY HH:mm"

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(rolesList, comparator) {
  const stabilizedThis = rolesList && rolesList.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, label: 'Name', },
  { id: 'role', numeric: false, label: 'Role', },
  { id: 'email', numeric: false, label: 'EmailID' ,},
  { id: 'username', numeric: false, label: 'User Name' , },
  { id: 'actions', numeric: false, label: 'Actions(Edit)', },
];
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
            ( headCell.id === "actions")?
            (
              <TableCell
                key={headCell.id}
                align="center"
                style={{fontWeight: 'bold', fontSize: 17,  width: "1%"}}
              >
                {headCell.label}
              </TableCell>
            )
            :(
              <TableCell
                key={headCell.id}
                // align={headCell.numeric ? 'center' : 'center'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                align="center"
                style={{fontWeight: 'bold', fontSize: 17,  width: "10%"}}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
           )
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
//   intl: PropTypes.shape.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridContianer: {
    padding: theme.spacing(2),
  },
  leftAlign: {
    color: 'rgba(0, 0, 0, 0.32)',
    textAlign: 'left'
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  userLabelStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    padding: 2,
    lineHeight: "24px",
    marginTop: "1%",
    marginBottom: "3%",
    textTransform: "none",
    color: "black",
    fontSize: "16px",
    fontWeight: 500,
    cursor:'pointer',
    textAlign:"left",
    hover: true,
    "&:hover": {
      color: "#4D47DD",
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paperModal: {
    // position: 'absolute',
    // width: 500,
    // // height: 620,
    // backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    // // top:'2%',
    // display:'block'
    position: 'absolute',
    width: 500,
    height: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block',
    overflowY:'scroll',
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    // backgroundColor: '#23c1f7',
    height:'12%',
  },
  addNewBusinessHeaderBackgroudNewrole: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    // backgroundColor: '#23c1f7',
    height:'12%',
    marginTop:'3%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
    width:'100%',
  },
  paperModalAddRole: {
    position: 'absolute',
    width: 500,
    height: 370,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'5%',
    display:'block'
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '2%',
    width: '100%',
  },
}));

export default function RolesRoleBased() {
  const classes = useStyles();
  const [ ShowProcedure, setShowProcedure ] = React.useState(false);
  const [flag, setflag] = React.useState(false);
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id , setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('selected_business_id')));
  const [rolesList, setrolesList] = React.useState([]);
  const [RoleSelectedForPermission, setRoleSelectedForPermission] = React.useState();
  const [RolePermissionDetailsList, setRolePermissionDetailsList] = React.useState([]);
  const [openSetRolePermissions, setopenSetRolePermissions] = React.useState(false);

  // to add new role
  const [AddNewRoleOpen, setAddNewRoleOpen] = React.useState(false);
  const [roleDetails, setroleDetails] = React.useState({
    shortcode: "",
    name: "",
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  });
  const [roleShortCodeErr, setroleShortCodeErr] = React.useState();
  const [roleNameErr, setroleNameErr] = React.useState();

  // to edit role
  const [EditRoleOpen, setEditRoleOpen] = React.useState(false);

  const dispatch = useDispatch();

  const [userListForNow, setuserListForNow] = React.useState([
    {
      "name": "Trp Admin",
      "email": "testma@gmail.com",
      "username": "test-ma",
      "role": "trpadmin",
      "is_active": true,
      "user_id": 9
    },
    {
      "name": "Trp",
      "email": "testma@gmail.com",
      "username": "test-ma",
      "role": "trpadmin",
      "is_active": true,
      "user_id": 9
    },
  ])

  const [RolePermissions, setRolePermissions]  = React.useState([
    {
      "action_id": 1,
      "action_name": "Action One",
      "has_permission": true,
    },
    {
      "action_id": 2,
      "action_name": "Action Two",
      "has_permission": true,
    },
    {
      "action_id": 3,
      "action_name": "Action Three",
      "has_permission": true,
    },
    {
      "action_id": 4,
      "action_name": "Action Four",
      "has_permission": true,
    }
  ]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(false);
    // sort
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // pagination
    const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rolesList && rolesList.length - page * rowsPerPage);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        // setRowsPerPage(parseInt(event.target.value, 10));
        let valueHere = parseInt(event.target.value, 10)
        let valueToCompare = JSON.stringify(valueHere)
        if( valueToCompare === "null" ){
          setRowsPerPage(0);
        }
        else{
          setRowsPerPage(parseInt(event.target.value, 10));
        }
        setPage(0);
    };

  React.useEffect(() => {
    setShowProcedure(true);
    setflag(true);
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id,'device_token': device_token, 
        'session_id': session_id })
    };
    // chnage this api to get business roles 
    fetch( process.env.REACT_APP_GET_BUSINESS_TEAM , requestOptions)
      .then((Response) => Response.json())
      .then(allUsersDetails => {
        setShowProcedure(false);
        console.log("allUsersDetails on user roled based access",allUsersDetails.data.business_team);
        if(allUsersDetails.data.business_team)
        {
          setrolesList(allUsersDetails.data.business_team)
        }
        if(allUsersDetails.success == false){
          setrolesList([])
          Swal.fire({
            icon: 'error',
            text: allUsersDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

  }, [flag]);

  const handleRoleClick = (item) => {
    console.log("user name clicked", item)
    setRoleSelectedForPermission(item.user_id)
    const requestOptionUserPermission = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'user_id': item.user_id,
        'device_token': device_token, 'session_id': session_id })
    };
    // fetch( process.env.REACT_APP_GET_BUSINESS_TEAM , requestOptionsUsers)
    fetch( process.env.REACT_APP_GET_ROLE_PERMISSION , requestOptionUserPermission)
      .then((Response) => Response.json())
      .then( async(RolePermissionDetails) => {
        console.log("RolePermissionDetails======",RolePermissionDetails);
        if(RolePermissionDetails.success == true)
        {
          setRolePermissionDetailsList(RolePermissionDetails.data.role_permissions);
        }
        else if(RolePermissionDetails.success == false){
          setRolePermissionDetailsList([]);
          Swal.fire({
            icon: 'error',
            text: RolePermissionDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    handleSetRolePermissionsOpen()
  }

  // Role permission modal
  const handleSetRolePermissionsOpen = () => {
    setopenSetRolePermissions(true)
  }

  const handelSetRolePermissionsClose = () => {
    setopenSetRolePermissions(false)
  }

  const handleRolePermissionCheckboxClick = (item) => {
    console.log("role pesrmission set clicked",item)

    setRolePermissions(
      RolePermissions.map(( itemHere , index ) =>{
        if(item.action_id == itemHere.action_id){
          itemHere.has_permission = !itemHere.has_permission
        }
        return itemHere
      })
    )
    console.log("RolePermissions", RolePermissions)
  }

  const handelSetRolePermissionsSubmit = () => {
    console.log("Submit role permissions")
    setopenSetRolePermissions(false)
    Swal.fire({
            // icon: 'error',
            text: "api integration is not done",
            confirmButtonColor: 'primary',
            confirmButtonText: 'OK'
          })

    // this is old call for single click of check. Change this for bulk
    // let roleSetPermissionObj = { 
    //   // "role_permission_id": 2,
    //   "action_id": item.action_id,
    //   "role": RoleSelectedForPermission,
    //   "has_permission": !item.has_permission,
    //   "device_token": device_token, 
    //   "session_id": session_id,
    // }
    // const requestOptions = {
    //   method: 'POST',
    //   headers: authHeader(),
    //   body: JSON.stringify(roleSetPermissionObj)
    // };
    // fetch( 'https://apitest.trpflow.com/permission/createrolepermission/', requestOptions)
    //   .then((Response) => Response.json())
    //   .then( async(PermissionChangeForRole) => {
    //     console.log("PermissionChangeForRole",PermissionChangeForRole)
    //   })
    //   .catch(err => {
    //     Swal.fire({
    //       icon: 'error',
    //       text: "Server Error. Please try again.",
    //       confirmButtonColor: '#d33',
    //       confirmButtonText: 'OK'
    //     })
    //   });
  }

  const bodySetRolePermissions = (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <Grid container item xs={12}>
          <Grid item xs={11} >
            <h2 style={{marginLeft:'34%'}}>Edit Role Permissions</h2>
          </Grid>
          {/* <Grid item xs={1} style={{marginTop:'4%',}}>
            <CloseIcon onClick={()=>handelSetRolePermissionsClose()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid> */}
        </Grid> 
      </div>
      <div className={classes.modalBody} style={{position:'absolute'}}>
        <Grid>
          {/* to set permissions */}
          <Grid item xs={12}>
            <ul style={{listStyle: "none"}}>
            {
              RolePermissions.map(( item , index ) =>
                <li key={item.action_id}>
                  <Grid container style={{ fontSize: '15px', marginTop:'2%'}} >
                      <Grid  >
                        <input
                          type="checkbox"
                          style={{zoom: 1.5}}
                          size='10px'
                          checked={item.has_permission}
                          onClick={ () => handleRolePermissionCheckboxClick(item)}
                        />
                      </Grid>
                      <Grid style={{marginLeft:'2%', marginTop:'1%'}} >
                        {item.action_name}
                      </Grid>
                  </Grid>
                </li>
              )
            }
            </ul>
          </Grid>
        </Grid>
        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSetRolePermissionsSubmit}
          >
            Submit
          </Button>
          &nbsp;&nbsp;
          <Button
            type="submit"
            variant="contained"
            // color="grey"
            className={classes.submit}
            onClick={handelSetRolePermissionsClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )

  // To add new role
  const handelAddNewRoleOpen = () => {
    setAddNewRoleOpen(true);
  }

  const handelAddNewRoleClose = () => {
    setAddNewRoleOpen(false);
    setroleDetails({
      shortcode: "",
      name: "",
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    setroleNameErr();
    setroleShortCodeErr();
  }

  const handelAddNewRoleMinimize= () => {
    setAddNewRoleOpen(false);
  }

  const validateCreateRole = () => {
    let roleShortCodeErr = '';
    let roleNameErr = '';

    if(!roleDetails.shortcode)  {
        roleShortCodeErr = 'Please Enter Short Code';
    }
    if(!roleDetails.name)  {
        roleNameErr = 'Please Enter Name';
    }

    if(roleNameErr || roleShortCodeErr ){
      setroleNameErr(roleNameErr);
      setroleShortCodeErr(roleShortCodeErr);
      return false;
    }

    return true;
  }

  const handelSubmitAddNewRole = () => {
    dispatch(userActions.checkSessionValidity());
      const isValid = validateCreateRole();
      if(isValid){
        setShowProcedure(true);
        setAddNewRoleOpen(false);
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(roleDetails)
        };
        return fetch( process.env.REACT_APP_CREATE_ROLE , requestOptions)
            .then((Response) => Response.json())
            .then(roleCreated => {
                setShowProcedure(false);
                console.log("roleCreated on same page",roleCreated.success);
                  console.log("roleCreated on same page",roleCreated);
                  if(roleCreated.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Role Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    setroleDetails({
                      shortcode: "",
                      name: "",
                      device_token: JSON.parse(localStorage.getItem('device_token')),
                      session_id: JSON.parse(localStorage.getItem('session_id')),
                    })
                    setroleNameErr();
                    setroleShortCodeErr();
                    setflag(false);
                  }
                  if(roleCreated.success == false || roleCreated.success == null ){
                    Swal.fire({
                      icon: 'error',
                      text: roleCreated.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setAddNewRoleOpen(true);
                    })
                }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
  }

  const bodyAddNewRoleOpen = (
    <div className={classes.paperModalAddRole}>
      <div className={classes.addNewBusinessHeaderBackgroudNewrole}> 
        <Grid container item xs={12}>
          <Grid item xs={10} >
            <h2 style={{marginLeft:'38%'}}>Add New Role</h2>
          </Grid>
          <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
            <MinimizeIcon onClick={()=>handelAddNewRoleMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
          <Grid item xs={1} style={{marginTop:'1%',}}>
            <CloseIcon onClick={()=>handelAddNewRoleClose()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
        </Grid> 
      </div>
      <div className={classes.modalBody}>
        <Grid>
          <TextField
            margin="normal"
            required
            fullWidth
            variant="outlined"
            id="shortcode"
            label="Short Code for Role"
            name="shortcode"
            autoComplete="shortcode"
            autoFocus
            value={roleDetails.shortcode}
            onChange={(e) => setroleDetails({...roleDetails, shortcode:e.target.value }) }
          />
          <div className={classes.validation}>{(roleDetails.shortcode)?(<div></div>):(roleShortCodeErr)}</div>

          <TextField
            margin="normal"
            required
            fullWidth
            variant="outlined"
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            value={roleDetails.name}
            onChange={(e) => setroleDetails({...roleDetails, name:e.target.value }) }
          />
          <div className={classes.validation}>{(roleDetails.name)?(<div></div>):(roleNameErr)}</div>
        </Grid>
        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',marginTop:'6%'}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitAddNewRole}
          >
            Add
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            // color="grey"
            className={classes.submit}
            onClick={handelAddNewRoleClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )

  // Edit role
  const handleEditRoleOpen = () => {
    setEditRoleOpen(true)
  }

  const handleEditRoleClose = () => {
    setEditRoleOpen(false)
  }

  const handelSubmitEditRole = () => {
    setEditRoleOpen(false)
  }

  const bodyEditRoleOpen = (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <Grid container item xs={12}>
          <Grid item xs={11} >
            <h2 style={{marginLeft:'45%'}}>Edit Role</h2>
          </Grid>
          {/* <Grid item xs={1} style={{marginTop:'4%',}}>
            <CloseIcon onClick={()=>handleEditRoleClose()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid> */}
        </Grid> 
      </div>
      <div className={classes.modalBody} style={{position:'absolute'}}>
        <Grid>
          {/* to set permissions */}
          Form will be here
        </Grid>
        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitEditRole}
          >
            Update
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            // color="grey"
            className={classes.submit}
            onClick={handleEditRoleClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )

  return (
    <div className={classes.root}>
      {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
       <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
      </modal>: null}
      <Grid container>
        Roles : 
      </Grid>
      <Grid style={{textAlign:"right"}}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddIcon />}
          // href="/newclient"
          onClick={handelAddNewRoleOpen}
        >
          Add Role
        </Button>
      </Grid>
      <Grid item xs={12}>
        {
          // usersList.map(( item , index ) =>(
            userListForNow.map(( item , index ) =>(
            // console.log("item of user list",item),
            <Grid className={classes.userLabelStyle} 
              onClick={ () => handleRoleClick(item)}
            >
              {item.name}
            </Grid>
          ))
        }
        <TableContainer className={classes.tableWrapper} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rolesList && rolesList.length}
            />
            <TableBody>
              {stableSort(rolesList && rolesList, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => {
                // console.log("row in table map",item)
                return (
                  <TableRow 
                    key={index}
                    hover={true} classes={{hover: classes.hover}}
                    tabIndex={-1}
                    align="center"
                  >
                    <TableCell align="center" >{item.name}</TableCell>
                    <TableCell align="center" >{item.role}</TableCell>
                    <TableCell align="center" >{item.email}</TableCell>
                    <TableCell align="center" >{item.username}</TableCell>
                    <TableCell width="15%" align="center" >
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => handleRoleClick(item)}
                      >
                        Permissions
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => handleEditRoleOpen(item)}
                      >
                        Role
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: rolesList && rolesList.length }]}
                    // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={8}
                    count={rolesList && rolesList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
          </Table>
        </TableContainer>
      </Grid>

      {/* To set/change permisiions */}
      <Modal
        open={openSetRolePermissions}
        onClose={handelSetRolePermissionsClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodySetRolePermissions}
      </Modal>
      {/* To add new role */}
      <Modal
        open={AddNewRoleOpen}
        onClose={handelAddNewRoleClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyAddNewRoleOpen}
      </Modal>
      {/* To edit role */}
      <Modal
        open={EditRoleOpen}
        onClose={handleEditRoleClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyEditRoleOpen}
      </Modal>
    </div>
  );
}