import React, { useEffect } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector , useDispatch} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Modal from '@material-ui/core/Modal';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { userActions } from "../actions";
import Paper from "@material-ui/core/Paper";
import AntSwitch from '@material-ui/core/Switch';
import TextField from "@material-ui/core/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import TableSortLabel from '@material-ui/core/TableSortLabel';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(AllTermsAndConditions, comparator) {
  const stabilizedThis = AllTermsAndConditions && AllTermsAndConditions.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  // { id: 'group_id', numeric: false, label: 'Group Id' ,},
  // { id: 'terms_id', numeric: true, label: '#', },
  { id: 'name', numeric: false, label: 'Name', },
  { id: 'text', numeric: false, label: 'Text', },
  { id: 'start_date', numeric: false, label: 'Start Date', },
  { id: 'end_date', numeric: false, label: 'End Date', },
  { id: 'terms_version', numeric: false, label: 'T & C Version', },
  { id: 'platform_version', numeric: false, label: 'Platform Version', },
  { id: 'is_active', numeric: false, label: 'In Use', },
  { id: 'actions', numeric: false, label: 'Actions', },
];
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          (headCell.id === "actions") ?
            (
              <TableCell
                key={headCell.id}
                align="center"
                style={{ fontWeight: 'bold', fontSize: 17, 
                    // width: "1%" 
                }}
              >
                {headCell.label}
              </TableCell>
            ): 
            (
              <TableCell
                key={headCell.id}
                // align={headCell.numeric ? 'center' : 'center'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                align="center"
                style={{ fontWeight: 'bold', fontSize: 17, }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    // <span className={classes.visuallyHidden}>
                    <span style={{border: 0,
                      clip: 'rect(0 0 0 0)',
                      height: 1,
                      margin: -1,
                      overflow: 'hidden',
                      padding: 0,
                      position: 'absolute',
                      top: 20,
                    //   width: 1,
                    }}
                    >
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    lexGrow: 1,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    height: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block',
    overflowY:'scroll',
  },
  paperModalAddAddress: {
    position: 'absolute',
    width: 500,
    // height: 810,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'1%',
    display:'block'
  },
  modalBodyAddTandC: {
    marginTop: '-2%',
    padding: theme.spacing(2, 4, 3),  
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  linkToAddNewTAndC: {
    color: "blue",
    fontSize: "16px",
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: "600",
    textTransform: "none",
    textAlign: "right",
    marginBottom: "1%"
  },
  paperModalAddTandC : {
    position: 'absolute',
    width: 500,
    // height: 810,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'1%',
    display:'block'
  },
  calenderNewStyle: {
    // border: 10,
    width: '100%',
    cursor:'pointer',
    height: 30
  },
  hover: {
    cursor: "pointer",
  },
  businessHeading: {
    // display: 'flex',
    // justifyContent: 'center',
    // marginTop: '2%',
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "23px",
    color: 'black'
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  paperModalTandCText : {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    width: 700,
    height: 600,
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    overflowY:'scroll',
  },
  modalBodyTandCText: {
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function TermsAndConditionSystemAdmin() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [flag, setflag] = React.useState(false);
  const [ShowProcedure, setShowProcedure] = React.useState(false);

  const [AllTermsAndConditions, setAllTermsAndConditions] = React.useState([]);
  const [OpenTermsAndCondition, setOpenTermsAndCondition] = React.useState(false);
  const [TextToShow, setTextToShow] = React.useState();

  const [addTermsandCondition, setaddTermsandCondition] = React.useState({
    name: "",
    terms_version: "",
    platform_version: "",
    start_date: "",
    end_date: "",
    text: "",
    is_active: true,
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')), 
  });
  const [nameErr, setnameErr] = React.useState();
  const [versionErr, setversionErr] = React.useState();
  const [platformVersionErr, setplatformVersionErr] = React.useState();
  const [validFromErr, setvalidFromErr] = React.useState();
  // const [validtillErr, setvalidtillErr] = React.useState();
  const [textErr, settextErr] = React.useState();
  const [isActiveErr, setisActiveErr] = React.useState();
  const [EditTermsAndConditions, setEditTermsAndConditions] = React.useState(false);
  const [ShowAllTandCText, setShowAllTandCText] = React.useState(false);

  const [minDate, setminDate] = React.useState(new Date());

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');

  const dispatch = useDispatch();

  // sort
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, AllTermsAndConditions && AllTermsAndConditions.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    let valueHere = parseInt(event.target.value, 10)
    let valueToCompare = JSON.stringify(valueHere)
    if( valueToCompare === "null" ){
      setRowsPerPage(0);
    }
    else{
      setRowsPerPage(parseInt(event.target.value, 10));
    }
    setPage(0);
  };


  useEffect(() => {
    
    setflag(true);
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'device_token': device_token, 'session_id': session_id
      })
    };
    fetch(process.env.REACT_APP_GET_ALL_TERMS, requestOptions)
      .then((Response) => Response.json())
      .then(async (AllTermsAndConditions) => {
        setShowProcedure(false);
        
        if (AllTermsAndConditions.success == true) {
            console.log("AllTermsAndConditions on sysadmin serach", AllTermsAndConditions.data.terms_info.all_terms)
            setAllTermsAndConditions(AllTermsAndConditions.data.terms_info.all_terms)
        }
        else if (AllTermsAndConditions.success == false) {
            setAllTermsAndConditions([]);
        }
      })
    // .catch(err => {
    //   setShowProcedure(false);
    //   Swal.fire({
    //     icon: 'error',
    //     text: "Server Error. Please try again.",
    //     confirmButtonColor: '#d33',
    //     confirmButtonText: 'OK'
    //   })
    // });

  }, [flag]);

  const showTextFunction = (text) => {
    // console.log("text is", text)
    return(
      // <text style={{fontSize: 15, fontWeight: 400, color:'black'}}>{description}</text>
      <Grid>
        <text style={{ fontSize: 15, fontWeight: 400, color: 'black' }}>
          {text.substring(0, 50)}... 
        </text>
        <text
          style={{ color: 'blue', cursor: 'pointer' }}
          onClick={() => readAllTandCClicked(text)}
        >
          &nbsp;Read All
        </text>
      </Grid>
    )
  }

  const readAllTandCClicked = async(text) => {
    console.log("readAllTandCClicked", text)
    await setTextToShow(text)
    setShowAllTandCText(true)
  }

  const readAllTandCClickedCancel = () => {
    setShowAllTandCText(false)
    setTextToShow()
  }

  const bodyTandCText = (
    <div className={classes.paperModalTandCText}>
      <Grid container item xs={12}>
        <Grid item xs={12} style={{textAlign: "center",}} >
          <h2 >Terms And Conditions Text</h2>
        </Grid>
      </Grid> 

        <div className={classes.modalBodyTandCText} 
          // style={{position:'absolute'}}
        >
          <Grid item xs={12}>
            <Grid item xs={5}>
              <text>
                {TextToShow}
              </text>
            </Grid>
          </Grid>
          
          

          <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end', marginTop:'5%'}}>
            <Button
              type="submit"
              variant="contained"
              className={classes.submit}
              onClick={readAllTandCClickedCancel}
            >
              Cancel
            </Button>
          </div>

        </div>
    </div>
  )

  const handleClickEditTermsAndConditions = (item) => {
    console.log("handleClickEditTermsAndConditions is clievked", item)
    setaddTermsandCondition({
        ...addTermsandCondition,
        terms_id: item.terms_id,
        name: item.name,
        terms_version: item.terms_version,
        platform_version: item.platform_version,
        start_date: item.start_date,
        end_date: item.end_date,
        text: item.text,
    })
    setEditTermsAndConditions(true)
    handelAddNewTandCOpen();
  }

  const handelAddNewTandCOpen = () => {
    setOpenTermsAndCondition(true);
  }

  const handelAddNewTandCClose = () => {
    setOpenTermsAndCondition(false);
    setaddTermsandCondition({
        ...addTermsandCondition,
        name: "",
        terms_version: "",
        platform_version: "",
        start_date: "",
        end_date: "",
        text: "",
    })
    setnameErr();
    setversionErr();
    setplatformVersionErr();
    setvalidFromErr();
    // setvalidtillErr();
    settextErr();
    setisActiveErr();
    setEditTermsAndConditions(false);
  }

  const validate = () => {
    let nameErr = '';
    let versionErr = '';
    let platformVersionErr = '';
    let validFromErr = '';
    // let validtillErr = '';
    let textErr = '';
    // let isActiveErr = '';

    if(!addTermsandCondition.name)  {
        nameErr = 'Please Enter T&C Name';
    }

    if(!addTermsandCondition.terms_version)  {
        versionErr = 'Please Enter T&C Version';
    }

    if(!addTermsandCondition.platform_version)  {
      platformVersionErr = 'Please Enter Platform Version';
  }

    if(!addTermsandCondition.start_date)  {
        validFromErr = 'Please Select T&C Valid From Date';
    }

    // if(!addTermsandCondition.end_date)  {
    //     validtillErr = 'Please Select T&C Valid Till Date';
    // }

    if(!addTermsandCondition.text)  {
        textErr = 'Please Enter T&C Text';
    }

    // if(addTermsandCondition.is_active == "" && addTermsandCondition.is_active !== false && addTermsandCondition.is_active !== true )  {
    //     isActiveErr = 'Please Select is Active';
    // }

    if( nameErr || versionErr || platformVersionErr || validFromErr || textErr || isActiveErr ){
        setnameErr(nameErr);
        setversionErr(versionErr);
        setplatformVersionErr(platformVersionErr);
        setvalidFromErr(validFromErr);
        // setvalidtillErr(validtillErr);
        settextErr(textErr);
        // setisActiveErr(isActiveErr);
        return false;
      }
      return true;
  }

  const handleAddTandCSubmit = () => {
    setShowProcedure(true);
    dispatch(userActions.checkSessionValidity());
    const isValid = validate();
    if(isValid){
      console.log("handleAddTandCSubmit is clicked", addTermsandCondition)

      if( EditTermsAndConditions === true ){
        console.log("in edit t and c")
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(addTermsandCondition)
        };
        fetch( process.env.REACT_APP_Edit_TERMS , requestOptions)
        .then((Response) => Response.json())
        .then( async(createTerms) => {
          setShowProcedure(false);
          
            if(createTerms.success == true)
            {
              setShowProcedure(false);
                Swal.fire({
                  icon: 'success',
                  text: "Terms and Conditions Updated Successfully",
                  confirmButtonColor: 'primary',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
              console.log("createTerms success", createTerms)
              setaddTermsandCondition({
                ...addTermsandCondition,
                name: "",
                terms_version: "",
                platform_version: "",
                start_date: "",
                end_date: "",
                text: "",
            })
              setnameErr();
              setversionErr();
              setplatformVersionErr();
              setvalidFromErr();
              // setvalidtillErr();
              settextErr();
              setisActiveErr();
              setEditTermsAndConditions(false);
              setOpenTermsAndCondition(false);
              setflag(false);
            }
            else if(createTerms.success == false){
              console.log("createTerms fail", createTerms)
              Swal.fire({
                icon: 'error',
                text: createTerms.error,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 5000,
              })
            }
        })
        .catch(err => {
          setShowProcedure(false);
            Swal.fire({
              icon: 'error',
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
        });
      }
      else if( EditTermsAndConditions === false ){
        console.log("in add t and c")

        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(addTermsandCondition)
        };
        fetch( process.env.REACT_APP_CREATE_TERMS , requestOptions)
        .then((Response) => Response.json())
        .then( async(createTerms) => {
          setShowProcedure(false);
            if(createTerms.success == true)
            {
              setShowProcedure(false);
                Swal.fire({
                  icon: 'success',
                  text: "Terms and Conditions Added Successfully",
                  confirmButtonColor: 'primary',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
              console.log("createTerms success", createTerms)
              setaddTermsandCondition({
                ...addTermsandCondition,
                name: "",
                terms_version: "",
                platform_version: "",
                start_date: "",
                end_date: "",
                text: "",
            })
              setnameErr();
              setversionErr();
              setplatformVersionErr();
              setvalidFromErr();
              // setvalidtillErr();
              settextErr();
              setisActiveErr();
              setEditTermsAndConditions(false);
              setOpenTermsAndCondition(false);
              setflag(false);
            }
            else if(createTerms.success == false){
              console.log("createTerms fail", createTerms)
              Swal.fire({
                icon: 'error',
                text: createTerms.error,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 5000,
              })
            }
        })
        .catch(err => {
          setShowProcedure(false);
            Swal.fire({
              icon: 'error',
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
        });
      }
    }
    
  }

  const bodyAddTandC = (
    <div className={classes.paperModalAddTandC}>
        <Grid container item xs={12}>
            {
                ( EditTermsAndConditions === true )? 
                (
                    <Grid item xs={12} style={{textAlign: "center",}} >
                        <h2 >Edit Terms And Conditions</h2>
                    </Grid>
                ):
                (
                    <Grid item xs={12} style={{textAlign: "center",}} >
                        <h2 >Add Terms And Conditions</h2>
                    </Grid>
                )
            }
          
        </Grid> 

        <div className={classes.modalBodyAddTandC}>
            <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        //   variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="Name"
                        label="Name"
                        name="Name"
                        autoComplete="Name"
                        autoFocus
                        value={addTermsandCondition.name}
                        onChange={(e) => setaddTermsandCondition({...addTermsandCondition, name:e.target.value }) }
                    />
                    <div className={classes.validation}>{(addTermsandCondition.name)?(<div></div>):(nameErr)}</div>

                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          //   variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="terms_version"
                          label="T & C Version"
                          name="T & C Version"
                          autoComplete="terms_version"
                          value={addTermsandCondition.terms_version}
                          onChange={(e) => setaddTermsandCondition({...addTermsandCondition, terms_version:e.target.value }) }
                        />
                        <div className={classes.validation}>{(addTermsandCondition.terms_version)?(<div></div>):(versionErr)}</div>
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          //   variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="platform_version"
                          label="Platform Version"
                          name="Platform Version"
                          autoComplete="platform_version"
                          value={addTermsandCondition.platform_version}
                          onChange={(e) => setaddTermsandCondition({...addTermsandCondition, platform_version:e.target.value }) }
                        />
                        <div className={classes.validation}>{(addTermsandCondition.platform_version)?(<div></div>):(platformVersionErr)}</div>
                      </Grid>
                    </Grid>

                    
                   
                    <TextField
                        //   variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        variant="outlined"
                        id="Text"
                        label="Text"
                        name="Text"
                        multiline
                        rows={4}
                        autoComplete="Text"
                        value={addTermsandCondition.text}
                        onChange={(e) => setaddTermsandCondition({...addTermsandCondition, text:e.target.value }) }
                    />
                    <div className={classes.validation}>{(addTermsandCondition.text)?(<div></div>):(textErr)}</div>

                    
                    {/* <Grid id="is_active" style={{marginTop:"2%", color:"grey"}}>Is Active*</Grid>
                    <Grid container item xs={12} style={{marginTop:"0.5%"}}>
                        <RadioGroup
                            row
                            id ="is_active"
                            aria-label="is_active"
                            name="is_active"
                            defaultValue={JSON.stringify(addTermsandCondition.is_active)}
                            onChange={ (e) => setaddTermsandCondition({...addTermsandCondition, is_active: JSON.parse(e.target.value) })}
                        >                  
                        <div>
                            <FormControlLabel
                                value="true"
                                classes={{ label: classes.label }}
                                control={<Radio size="small" color="primary" />}
                                label= "Yes"
                            />
                        </div>
                        <div>
                            <FormControlLabel
                                value="false"
                                classes={{ label: classes.label }}
                                control={<Radio size="small" color="primary" />}
                                label= "No"
                            />
                        </div>
                        </RadioGroup>
                    </Grid>
                    <div className={classes.validation}>{(addTermsandCondition.is_active == "" && addTermsandCondition.is_active !== false && addTermsandCondition.is_active !== true)?(isActiveErr):(<div></div>)}</div> */}

                    <Grid container item xs={12} spacing={2} style={{marginTop: "3%"}}>
                        <Grid item xs={6}>
                            <Grid container item xs={12} >
                                <Grid  item xs={5}> Start Date :</Grid>
                                <Grid  item xs={7}>
                                    <DatePicker 
                                        className={classes.calenderNewStyle}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText='Start Date'
                                        selected={ Date.parse(moment(addTermsandCondition.start_date, 'DD/MM/YYYY').toISOString()) }
                                        onChange={(date) => setaddTermsandCondition({...addTermsandCondition, start_date:moment(date).format("DD/MM/YYYY") }) }
                                        minDate={minDate}
                                    />
                                    <div className={classes.validation}>{(addTermsandCondition.start_date)?(<div></div>):(validFromErr)}</div>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6}>
                            <Grid container item xs={12} >
                                <Grid  item xs={5}> End Date :</Grid>
                                <Grid  item xs={7}>
                                    <DatePicker 
                                        className={classes.calenderNewStyle}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText='End Date'
                                        selected={ Date.parse(moment(addTermsandCondition.end_date, 'DD/MM/YYYY').toISOString()) }
                                        onChange={(date) => setaddTermsandCondition({...addTermsandCondition, end_date:moment(date).format("DD/MM/YYYY") }) }
                                        minDate={minDate}
                                    />
                                    {/* <div className={classes.validation}>{(addTermsandCondition.end_date)?(<div></div>):(validtillErr)}</div> */}

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>


            <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end', marginTop:'10%'}}>
                {
                    ( EditTermsAndConditions === true )? 
                    (
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleAddTandCSubmit}
                        >
                            Update
                        </Button>
                    ):
                    (
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleAddTandCSubmit}
                        >
                            Add
                        </Button>
                    )
                }
                
                <div style={{marginLeft:'2%'}}></div>
                <Button
                    type="submit"
                    variant="contained"
                    className={classes.submit}
                    onClick={handelAddNewTandCClose}
                >
                    Cancel
                </Button>
            </div>
        </div>
    </div>
  )

  const handleClickDeleteTermsAndConditions = (item) => {
    setShowProcedure(true);
    // console.log("handleClickDeleteTermsAndConditions is clicked",item)
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'terms_id': item.terms_id , 
        'device_token': device_token, 'session_id': session_id, })
    };
    fetch( process.env.REACT_APP_DELETE_TERMS , requestOptions)
    .then((Response) => Response.json())
    .then( async(deleteTerm) => {
      setShowProcedure(false);
      if(deleteTerm.success == true)
      {
        console.log("deleteTerm success", deleteTerm)
        Swal.fire({
          icon: 'success',
          text: "Terms and Conditions Deleted Successfully",
          confirmButtonColor: 'primary',
          confirmButtonText: 'OK',
          timer: 5000,
        })
        
      }
      else if(deleteTerm.success == false){
        console.log("deleteTerm fail", deleteTerm)
        Swal.fire({
          icon: 'error',
          text: deleteTerm.errors,
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK',
          timer: 5000,
        })
      }
      setflag(false);
    })
    .catch(err => {
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK',
          timer: 5000,
        })
    });
  }

  return (
    // console.log("AllTermsAndConditions in new page", AllTermsAndConditions),
    <div className={classes.root}>
      {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
        <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
      </modal> : null}
      <Grid>
        <Grid container item xs={12} className={classes.businessHeading}>
          Terms And Conditions :
        </Grid>
        <Grid container item xs={12}>
            <Grid item xs={12} className={classes.linkToAddNewTAndC} 
                onClick={() => handelAddNewTandCOpen()}
                style={{ cursor:'pointer'}}
            >
                + Add New Terms and Condition
            </Grid>
        </Grid>
        <TableContainer style={{ width: '100%' }} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <EnhancedTableHead
              // classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={AllTermsAndConditions && AllTermsAndConditions.length}
            />
            <TableBody>
              {stableSort(AllTermsAndConditions && AllTermsAndConditions, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  // console.log("row in table map",item)
                  return (
                    <TableRow
                      key={item.state_id}
                      hover={true} classes={{ hover: classes.hover }}
                      // hover
                      tabIndex={-1}
                      align="center"
                      style={{ height: 1 }}
                    >
                      {/* <TableCell onClick={() => handleClickEditTermsAndConditions(item)} align="center">{item.terms_id}</TableCell> */}
                      <TableCell onClick={() => handleClickEditTermsAndConditions(item)} align="center">{item.name}</TableCell>
                      <TableCell  align="center">{ showTextFunction(item.text) }</TableCell>
                      <TableCell onClick={() => handleClickEditTermsAndConditions(item)} align="center">{item.start_date}</TableCell>
                      <TableCell onClick={() => handleClickEditTermsAndConditions(item)} align="center">{item.end_date}</TableCell>
                      <TableCell onClick={() => handleClickEditTermsAndConditions(item)} align="center">{item.terms_version}</TableCell>
                      <TableCell onClick={() => handleClickEditTermsAndConditions(item)} align="center">{item.platform_version}</TableCell>
                      <TableCell onClick={() => handleClickEditTermsAndConditions(item)} align="center">{(item.is_active === true ? "Yes" : "-" )}</TableCell>
                      <TableCell align="center">
                        <Grid>
                          <Grid>
                          <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              className={classes.button}
                              onClick={() => handleClickEditTermsAndConditions(item)}
                          >
                              Edit
                          </Button>
                          </Grid>
                          <Grid style={{marginTop:'1%'}}>
                          <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              className={classes.button}
                              onClick={() => handleClickDeleteTermsAndConditions(item)}
                          >
                              Delete
                          </Button>
                          </Grid>
                        </Grid>
                        
                      </TableCell>
                    </TableRow>
                  );
                })}

            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: AllTermsAndConditions && AllTermsAndConditions.length }]}
                  colSpan={8}
                  count={AllTermsAndConditions && AllTermsAndConditions.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
      <Modal
          open={OpenTermsAndCondition}
          onClose={handelAddNewTandCClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyAddTandC}
      </Modal>

      <Modal
          open={ShowAllTandCText}
          onClose={readAllTandCClickedCancel}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyTandCText}
      </Modal>
      
    </div>
  );
}
