import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import { Link, Redirect} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from "@material-ui/core/Button";
// import {caseupdateActions} from "../../actions";
import { addSelectedCaseDetails, addYearAss } from "../../actions/updatecase.actions";
import { Modal } from 'reactstrap';
import { PropagateLoader } from 'react-spinners';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../../actions";
import useCheckSessionApi from "../../SessionValidity";

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import moment from 'moment';

import { newCaseActionReset } from "../../actions/newcase.actions";
import {addqsnList, addqsnListLevelTwo, addqsnListLevelThree,
 } from "../../actions/updatecase.actions";

import TableSortLabel from '@material-ui/core/TableSortLabel';
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(casesList, comparator) {
  const stabilizedThis = casesList && casesList.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  // { id: 'case_id', numeric: false, label: 'Case Id', },
  // { id: 'name', numeric: false, label: 'Name', },
  // { id: 'description', numeric: false, label: 'Description', },
  { id: 'process', numeric: false, label: 'Process', },
  { id: 'service', numeric: false, label: 'Service', },
  { id: 'created_date', numeric: false, label: 'Created Time (Local)', },
  { id: 'client_name', numeric: false, label: 'Client Name', },
  { id: 'for_assesse_name', numeric: false, label: 'Tax Payer Name', },
  { id: 'owner', numeric: false, label: 'Owner', },
  { id: 'case_curr_state', numeric: false, label: 'Current State', },
  { id: 'actions', numeric: false, label: 'Actions', },
];
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
            ( headCell.id === "actions")?
            (
              <TableCell
                key={headCell.id}
                align="center"
                style={{fontWeight: 'bold', fontSize: 17,  width: "1%"}}
              >
                {headCell.label}
              </TableCell>
            )
            :(
              <TableCell
                key={headCell.id}
                // align={headCell.numeric ? 'center' : 'center'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                align="center"
                style={{fontWeight: 'bold', fontSize: 17,  width: "10%"}}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
           )
          
        
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  rootrp: {
    flexGrow: 1,
    // backgroundColor: 'white',
  },
  cursorPointer: {
    cursor: "pointer"
  },
  wrapper: {
    // margin: "10%",
    // marginRight: "10%",
    marginTop: "5%",
    marginBottom: "3%",
  },
  table: {
    minWidth: 500, 
  },
  tableWrapper:{
    boxShadow: 'none',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: '0px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  hover: {
    cursor: "pointer",
  },

}));

export default function TaxManagementCasesTableClient() {
    const classes = useStyles();
    const [ ShowProcedure, setShowProcedure ] = React.useState(false);
    const [casesList, setcasesList] = React.useState([]);
    const [slectedCaseForDetailsHereClient, setslectedCaseForDetailsHereClient] = React.useState([]);

    const [RedirectToEditClientCase, setRedirectToEditClientCase] = React.useState(false);
    const [RedirectToTaxManagementCaseDetails,setRedirectToTaxManagementCaseDetails] = React.useState(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const [flag, setflag] = React.useState(false);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(false);

    // Redux set
    const dispatch = useDispatch();
    const addSelectedCaseDetailsRedux = (selectedCaseDetails) => dispatch(addSelectedCaseDetails(selectedCaseDetails));
    const addYearAssRedux = (yearAss) => dispatch(addYearAss(yearAss));

    const addqsnListRedux = () => dispatch(addqsnList());
    const addqsnListLevelTwoRedux = (qsnListLevelTwo) => dispatch(addqsnListLevelTwo(qsnListLevelTwo));
    const addqsnListLevelThreeRedux = (qsnListLevelThree) => dispatch(addqsnListLevelThree(qsnListLevelThree));
    const newCaseActionResetCallRedux = () => dispatch(newCaseActionReset());

    // sort
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, casesList && casesList.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        // setRowsPerPage(parseInt(event.target.value, 10));
        let valueHere = parseInt(event.target.value, 10)
        let valueToCompare = JSON.stringify(valueHere)
        if( valueToCompare === "null" ){
          setRowsPerPage(0);
        }
        else{
          setRowsPerPage(parseInt(event.target.value, 10));
        }
        setPage(0);
    };

    useEffect(() => {
      setflag(true);
      dispatch(userActions.checkSessionValidity());
      
      // readItemFromStorage();
      let device_token = JSON.parse(localStorage.getItem('device_token'));
      let session_id = JSON.parse(localStorage.getItem('session_id'));
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      setShowProcedure(true);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'business_id': business_id, 'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_CASES_URL , requestOptions)
        .then((Response) => Response.json())
        .then(casesDetails => {
          setShowProcedure(false);
          console.log("casesDetails on same page",casesDetails);
          if(casesDetails.data.cases)
          {
            setcasesList(casesDetails.data.cases)
          }
          if(casesDetails.success == false){
            setcasesList([])
            Swal.fire({
              icon: 'error',
              text: casesDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    }, [flag]);

    // const readItemFromStorage = async() => {
    //   try {
    //     setDeviceToken(deviceTokenFromRedux)
    //     setSessionId(sessionIdFromRedux)
    //     setflag(false)    
    //   } catch (error) {
    //     console.log("ERROR:",error);        }
    // };
    
    const handleClickEditCase = async(item) => {
      console.log("item clicked is edit case",item);
      await localStorage.setItem('selected_case_for_edit', JSON.stringify(item));
      await addSelectedCaseDetailsRedux(JSON.stringify(item));
      await addYearAssRedux(JSON.stringify(item.assessment_year));
      // await addYearAssRedux(assesmentYearIs);
      // addSSEDetailsRedux(JSON.stringify(item))
    }

    const handleClickEditCaseAdmin =  async(item) => {
      console.log("item clicked is",item);
      await localStorage.setItem('selected_case_for_edit', JSON.stringify(item));

    }

    const handleClickShowCaseDetails = async(item) => {
      console.log("show case  clicked is",item);
      await localStorage.setItem('selected_case_for_details', JSON.stringify(item));
    }

    const handleClickShowCaseDetailsToClient = async(item) => {
      console.log("show case clicked is",item);
      await localStorage.setItem('selected_case_for_details', JSON.stringify(item));
      await localStorage.setItem('navigated_toshow_case_from', JSON.stringify("Cases"));
      setslectedCaseForDetailsHereClient(item)
    }

    const handleRowClickOfTable = async(item) => {
      console.log("handleRowClickOfTable is clicked",item);
      await localStorage.setItem('selected_case_for_edit', JSON.stringify(item));
      await localStorage.setItem('selected_case_for_details', JSON.stringify(item));
      await localStorage.setItem('navigated_toshow_case_from', JSON.stringify("Cases"));
      await addSelectedCaseDetailsRedux(JSON.stringify(item));
      await addYearAssRedux(JSON.stringify(item.assessment_year));
      if( item.case_curr_state == "DRAFT" || item.case_curr_state == "INITIATED" ){
        // href="/EditCase"
        await setRedirectToEditClientCase(true);
      }
      else if(item.case_curr_state == "IN-PROGRESS" || item.case_curr_state == "PENDING" || 
        item.case_curr_state == "PAYMENT-PENDING" || item.case_curr_state == "COMPLETED"){
        // href="/TaxManagementCaseDetails"
        await setRedirectToTaxManagementCaseDetails(true);
      }
      else if(item.case_curr_state == "CREATED"){
        // href="/TaxManagementCaseDetails"
        await setRedirectToTaxManagementCaseDetails(true);
      }
      else if(item.case_curr_state == "CLOSED"){
        await setRedirectToTaxManagementCaseDetails(true);
        // Swal.fire({
        //   text: "Case is closed",
        //   confirmButtonColor: 'primary',
        //   confirmButtonText: 'OK'
        // })
      }
      else {
        Swal.fire({
          // icon: 'error',
          text: "Case is not Confirmed yet",
          confirmButtonColor: 'primary',
          confirmButtonText: 'OK'
        })
      }
    }

    const createdDateFunc = (date) => {
      console.log("date in createdDateFunc is", date)

      let dateInString = JSON.stringify(date)
      console.log("date in straing is ", dateInString)

      var mydateIs = moment(dateInString, 'DD/MM/YYYY hh:mm A'); 
      console.log("formated date is ours",moment(mydateIs).format("MM/DD/YYYY hh:mm A"))
      var myDateWithFormatIs = moment(mydateIs).format("MM/DD/YYYY hh:mm A")
      console.log("myDateWithFormatIs",myDateWithFormatIs)
      console.log("date in format for  in table map is local", new Date(myDateWithFormatIs)) 

      // let myLocalDateIS = new Date(myDateWithFormatIs)
      // let myLocalDateISStringfy = JSON.stringify(myLocalDateIS)
      // console.log("myLocalDateIS,myLocalDateISStringfy",myLocalDateIS,myLocalDateISStringfy)

      // let localDateSplit = myLocalDateISStringfy.split("T");
      // console.log("localDateSplit:",localDateSplit)

      // const localDate = localDateSplit[0].split('"');
      // console.log("localDate:",localDate)

      // const localTime = localDateSplit[1].split('.0');
      // console.log("localTime:",localTime)
      // const localTime2 = localTime[0];
      // console.log("localTime2:",localTime2)

      var local_date= moment.utc(myDateWithFormatIs).local().format('MM/DD/YYYY hh:mm A');
      console.log("local_date using moments is",local_date)

      var local_date_DayFirst= moment.utc(myDateWithFormatIs).local().format('DD/MMM/YYYY hh:mm A');
      console.log("local_date day first",local_date_DayFirst)

      return(
        <div>
          <div>
            {/* {local_date} */}
            {local_date_DayFirst}
          </div>
        {/* <div>{JSON.parse(myLocalDateISStringfy)}</div> */}
        {/* <div>{localDate}</div><br/>
        <div>{localTime2}</div> */}
        </div>  
      )
    }

    let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));
    if (RedirectToEditClientCase == true){

      addqsnListRedux();
      addqsnListLevelTwoRedux();
      addqsnListLevelThreeRedux()  ;
      newCaseActionResetCallRedux();

      
      return <Redirect to={`/EditCase`} />
    }
    else if(RedirectToTaxManagementCaseDetails == true){
      return <Redirect to={`/TaxManagementCaseDetails`} />
    }
    else{
    return(
        console.log("casesList,role_of_user",casesList,role_of_user),
        <div className={classes.rootrp}>
          {ShowProcedure ? <Modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </Modal>: null}
        <div className={classes.wrapper}>
            {/* <Breadcrumbs  aria-label="breadcrumb">
                <Link to="BusinessDashboard" >
                    BusinessDashboard
                </Link>
                <Typography color="textPrimary">casesList</Typography>
            </Breadcrumbs> */}
            <TableContainer className={classes.tableWrapper} component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={casesList && casesList.length}
                    />
                    <TableBody>
                        {stableSort(casesList && casesList, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item, index) => {
                          // console.log("row in table map",item)
                          // if( role_of_user == "client" ){
                            return (
                              <TableRow 
                                  key={item.case_id}
                                  hover={true} classes={{hover: classes.hover}}
                                  // hover
                                  tabIndex={-1}
                                  align="center"
                              >
                                  {/* <TableCell onClick={ () => handleRowClickOfTable(item)} width="10%" align="center">{item.case_id}</TableCell> */}
                                  {/* <TableCell onClick={ () => handleRowClickOfTable(item)} width="10%" align="center">{item.name}</TableCell> */}
                                  {/* <TableCell onClick={ () => handleRowClickOfTable(item)} width="10%" align="center">{item.description}</TableCell> */}
                                  <TableCell onClick={ () => handleRowClickOfTable(item)} width="10%" align="center">{item.process}</TableCell> 
                                  <TableCell onClick={ () => handleRowClickOfTable(item)} width="10%" align="center">{item.service}</TableCell>  
                                  {/* <TableCell onClick={ () => handleRowClickOfTable(item)} width="10%" align="center">{item.created_date}</TableCell> */}
                                  <TableCell onClick={ () => handleRowClickOfTable(item)} width="10%" align="center">{createdDateFunc(item.created_date)}</TableCell>
                                  <TableCell onClick={ () => handleRowClickOfTable(item)} width="10%" align="center">{item.client_name}</TableCell>
                                  <TableCell onClick={ () => handleRowClickOfTable(item)} width="10%" align="center">
                                    {/* <Grid> */}
                                      <text>
                                        {(item.for_assesse_name === " " ? item.client_name : item.for_assesse_name)}
                                      </text><br/>
                                      <text>
                                        &nbsp;({(item.for_assesse_name === " " ? item.client_pan : item.for_assesse_pan)})
                                      </text>
                                    {/* </Grid> */}
                                  </TableCell>
                                  <TableCell onClick={ () => handleRowClickOfTable(item)} width="10%" align="center">{item.owner ? item.owner.owner :null}</TableCell>
                                  <TableCell onClick={ () => handleRowClickOfTable(item)} width="10%" align="center">{item.case_curr_state}</TableCell>
                                  {/* {( item.case_curr_state == "DRAFT" || item.case_curr_state == "INITIATED" && ) */}
                                  {
                                  ( item.case_curr_state == "DRAFT" || item.case_curr_state == "INITIATED" )
                                  ?(<TableCell width="5%" align="center" >
                                      <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        href="/EditCase"
                                        onClick={() => handleClickEditCase(item)}
                                      // >Edit Case Client</Button>
                                      >Edit Case</Button>
                                    </TableCell>
                                  )
                                  :(
                                    // 
                                    ( item.case_curr_state == "IN-PROGRESS" || item.case_curr_state == "PENDING" 
                                      || item.case_curr_state == "PAYMENT-PENDING" || item.case_curr_state == "COMPLETED"
                                      || item.case_curr_state == "CREATED")?
                                    (
                                      <TableCell width="5%" align="center" >
                                        <Button
                                          size="small"
                                          variant="contained"
                                          color="primary"
                                          className={classes.button}
                                          href="/TaxManagementCaseDetails"
                                          onClick={() => handleClickShowCaseDetailsToClient(item)}
                                        // >Edit Case Client</Button>
                                        >Show Case</Button>
                                      </TableCell>
                                    ):
                                    (
                                    <TableCell width="5%" align="center" ></TableCell>
                                    )
                                  )
                                  }                             
                              </TableRow>
                            );
                          // }
                        })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                            <TablePagination
                                // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: casesList && casesList.length }]}
                                rowsPerPageOptions={[100, 50, 25, 5, { label: 'All', value: casesList && casesList.length }]}
                                colSpan={8}
                                count={casesList && casesList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                            </TableRow>
                        </TableFooter>
                </Table>
                </TableContainer>
        </div>
        </div>
    )
  }
}