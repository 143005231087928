import React, { useEffect } from "react";                                               
import { Router, Route, Redirect} from "react-router-dom";                              
import Button from "@material-ui/core/Button";                                          
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import {FileCopy,Forum, AccessTime, AttachMoney} from "@material-ui/icons";
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
                                                                                        
function SamaiHomePage({ intl,users,props }){   
    const [GetOtpClicked, setGetOtpClicked] = React.useState(false);                                        

    const useStyles = makeStyles((theme) => ({ 
        root: {
            width: "100%",
            textAlign: "left",
        },
        paper: {
            margin: theme.spacing(8, 4),
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
        },
        samaiTitleBox: {
            paddingTop: "64px",
            paddingBottom: "64px",
            backgroundColor: "#F9F5F6",
        },
        samaiTitle : {
            fontSize: "48px",
            fontWeight: "bold",
            paddingTop: "32px",
            paddingLeft: "32px",
            color: "#0C134F",
            backgroundColor: "#F9F5F6",
        },
        LoginButtonBox: {
            backgroundColor: "#F9F5F6",
            textAlign: "center",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: "32px",
        },
        LoginButton : {
            // marginTop: '22%',
            // fontSize: "48px",
            fontWeight: "bold",
            // border: "20%",
            // paddingTop: "32px",
            // paddingLeft: "32px",
            // color: "#0C134F",
            // backgroundColor: "#F9F5F6",
            
        },
        SignUpButtonBox: {
            backgroundColor: "#F9F5F6",
            textAlign: "center",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: "32px",
            
        },
        SignUpButton: {
            fontWeight: "bold",
            marginRight: "20%"
        },
        titleSubTextBox: {
            width: "100%",
            textAlign: "center",
        },
        titleSubText: {
            fontSize: "32px",
            fontWeight: "bold",
            paddingLeft: "32px",
            color: "#0C134F",
            marginBottom: "64px",

        },
        samaiIcon: {
            fontSize: "24px",
        }, 
        samaiKeyItemBox: {
            marginTop: "64px",
        },
        samaiKeyItem: {
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "bold",
            color: "#0C134F",
        },
        samaiKeyFeatureTitle: {
            textAlign: "center",
            fontSize: "24px",
            fontWeight: "bold",
            paddingLeft: "32px",
            marginBottom: "32px",
        },
        samaiFeatureBox: {
            textAlign: "left",
            paddingLeft: "32px",
            paddingTop: "64px",
            paddingBottom: "64px",
            fontSize: "16px",
            backgroundColor: "#FFEADD",
        },
        samaiFeatureTitle: {
            textAlign: "left",
            paddingLeft: "32px",
            width: "360px",
            fontWeight: "600",
        },
        samaiFeatureList: {
            textAlign: "left",
            listStyleType: "square",
        },
        samaiCallbackBox: {
            paddingTop: "128px",
            paddingBottom: "128px",
            color: "rgba(0,0,0,0.85)",
            fontSize: "32px",
            fontWeight: "600",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
        },
        samaiCallbackBoxTitle: {
        },
        samaiContactBox: {
            padding: "120px 32px",
            fontSize: "16px",
            fontWeight: "400",
            backgroundColor: "#C5DFF8"
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'scroll',
        },
        paperModal: {
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            width: 500,
            // height: 280,
            height: ( GetOtpClicked === true ? 380 : 280),
            backgroundColor: theme.palette.background.paper,
            // border: '2px solid #000',
            boxShadow: theme.shadows[5],
        },
        addNewBusinessHeaderBackgroud: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // backgroundColor: '#23c1f7',
            backgroundColor: 'white',
            height: '10%',
            marginTop:'5%'
        },
        modalBody: {
            padding: theme.spacing(2, 4, 3),
        },
        validation: {
            color: 'red',
            fontSize: 12,
        },


    }));
    const classes = useStyles();
    const [redirectToLogin, setredirectToLogin] = React.useState(false);
    const [redirectToSignUp, setredirectToSignUp] = React.useState(false);

    const [OpenNewLogin, setOpenNewLogin] = React.useState(false);
    const [EmailIdForLogin, setEmailIdForLogin] = React.useState("");
    const [ErrorEmailIdForLogin, setErrorEmailIdForLogin] = React.useState("");
    const [LoginOTP, setLoginOTP] = React.useState("");
    const [ErrorLoginOTP, setErrorLoginOTP] = React.useState("");
    

    const NewLoginFunc = () => {
        console.log("new Login Func is Clicked")
        // setredirectToLogin(true);
        setOpenNewLogin(true);
    }

    const handleNewLoginClose = () => {
        setOpenNewLogin(false);

        setEmailIdForLogin("");
        setErrorEmailIdForLogin("");
        setGetOtpClicked(false);
        setLoginOTP("");
        setErrorLoginOTP("");
    }

    const validate = () => {
        let ErrorEmailIdForLogin = "";
        let ErrorLoginOTP = "";

        if (!EmailIdForLogin) {
            ErrorEmailIdForLogin = 'Please Enter Email ID';
        }

        if( GetOtpClicked === true ){
            if (!LoginOTP) {
                ErrorLoginOTP = 'Please Enter OTP';
            }
        }

        if (ErrorEmailIdForLogin || ErrorLoginOTP) {
            setErrorEmailIdForLogin(ErrorEmailIdForLogin);
            setErrorLoginOTP(ErrorLoginOTP);
      
            return false;
        }
        return true;
    }

    const handleLoginSubmit = () => {
        const isValid = validate();
        if (isValid) {
            if( GetOtpClicked === false ){
                console.log(" handle LoginSubmit clicked EmailIdForLogin", EmailIdForLogin)

                setGetOtpClicked(true);
            }
            else if( GetOtpClicked === true ){
                console.log(" handle LoginSubmit clicked EmailIdForLogin",EmailIdForLogin, LoginOTP)
                
                setOpenNewLogin(false);

                setEmailIdForLogin("");
                setErrorEmailIdForLogin("");
                setGetOtpClicked(false);
                setLoginOTP("");
                setErrorLoginOTP("");

                alert("OK"  ,{EmailIdForLogin, LoginOTP})
                
            }
            
        }
    }

    const bodyNewLogin = (
        <div className={classes.paperModal}>
            <div className={classes.addNewBusinessHeaderBackgroud}>
                <h1>Login</h1>
            </div>

            <div className={classes.modalBody}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    // style = {{width: '100%'}}
                    variant="outlined"
                    id="emailid"
                    label="Email ID"
                    placeholder='Enter Email ID'
                    name="emailid"
                    inputProps={{ style: { textTransform: "lowercase" } }}
                    disabled = { GetOtpClicked === true ? true : false}  
                    value={EmailIdForLogin}
                    onChange={(e) => setEmailIdForLogin(e.target.value)}
                />
                <div className={classes.validation}>{(EmailIdForLogin) ? (<div></div>) : (ErrorEmailIdForLogin)}</div>

                {
                    ( GetOtpClicked === true )?
                    (
                        <div>
                            <TextField
                                type='number'
                                margin="normal"
                                required
                                fullWidth
                                variant="outlined"
                                id="otp"
                                label="otp"
                                placeholder='Enter OTP'
                                name="otp"
                                value={LoginOTP}
                                onChange={(e) => setLoginOTP(e.target.value)}
                            />
                            <div className={classes.validation}>{(LoginOTP) ? (<div></div>) : (ErrorLoginOTP)}</div>
                        </div>
                    ):
                    (
                        <div></div>
                    )
                }
            

            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '8%' }}>
                <Button
                    type="submit"
                    //   fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handleLoginSubmit}
                >
                    Get OTP
                </Button>
                <div style={{ marginLeft: '2%' }}></div>
                <Button
                    type="submit"
                    variant="contained"
                    className={classes.submit}
                    onClick={handleNewLoginClose}
                >
                    Cancel
                </Button>
            </div>
            </div>
        </div>
    )
                                    
    const LoginFunc = () => {
        console.log("Login Func is Clicked")
        setredirectToLogin(true);
    }

    const SignUpFunc = () => {
        console.log("Sign Up Func is Clicked")
        setredirectToSignUp(true);
    }


    if( redirectToLogin === true ){
        return(
            <Redirect to={'/login'}/>
        )
    }
    if( redirectToSignUp === true ){
        return(
            <Redirect to={'/TaxManagementSignUp'}/>
        )
    }
    else{

        return (                                                                        
            <div>
                <Grid container component="main" className={classes.root}>
                    <CssBaseline />
                    <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square>
                        {/* <Typography className={classes.samaiTitle}>Samai</Typography> */}

                        <Grid container item xs={12}>
                            <Grid item xs={10}>
                                <Typography className={classes.samaiTitle}>Samai</Typography>
                            </Grid>

                            {/* <Grid item xs={1} className={classes.LoginButtonBox}>
                                <Button
                                    size="big"
                                    variant="outlined"
                                    color="primary"
                                    className={classes.LoginButton}
                                    onClick={() => NewLoginFunc()}
                                >
                                    Login New
                                </Button>
                            </Grid> */}

                            <Grid item xs={1} className={classes.LoginButtonBox}>
                                <Button
                                    size="big"
                                    variant="outlined"
                                    color="primary"
                                    className={classes.LoginButton}
                                    onClick={() => LoginFunc()}
                                >
                                    Login
                                </Button>
                            </Grid>

                            <Grid item xs={1} className={classes.SignUpButtonBox}>
                                <Button
                                    size="big"
                                    variant="outlined"
                                    color="primary"
                                    className={classes.SignUpButton}
                                    onClick={() => SignUpFunc()}
                                >
                                    Sign Up
                                </Button>
                            </Grid>

                        </Grid>
                        


                        <Grid container className={classes.samaiTitleBox}>
                            <Grid item  xs={12} sm={12} md={12} className={classes.titleSubTextBox}>
                                <Typography className={classes.titleSubText}>
                                    Empower your business to break barriers, unleash full potential to innovate and transform 
                                </Typography>
                            </Grid>
                            <Grid item  xs={12} sm={3} md={3} className={classes.samaiKeyItem}>
                                <FileCopy className={classes.samaiIcon}/><br/> <br/>
                                Digitalise Business Functions
                            </Grid>
                            <Grid item  xs={12} sm={3} md={3} className={classes.samaiKeyItem}>
                                <Forum className={classes.samaiIcon}/><br/> <br/>
                                Communicate with Context
                            </Grid>
                            <Grid item  xs={12} sm={3} md={3} className={classes.samaiKeyItem}>
                                <AccessTime className={classes.samaiIcon}/><br/> <br/>
                                Manage with efficiency
                            </Grid>
                            <Grid item  xs={12} sm={3} md={3} className={classes.samaiKeyItem}>
                                <AttachMoney className={classes.samaiIcon}/><br/> <br/>
                                Scale with confidence
                            </Grid>
                        </Grid>
                        <Grid container className={classes.samaiFeatureBox}>
                            <Grid item  xs={12} sm={12} md={12} className={classes.samaiKeyFeatureTitle}>
                                Platform brings broad set of capabilities for your evolving business
                            </Grid>
                            <Grid item  xs={12} sm={4} md={4}>
                                <p className={classes.samaiFeatureTitle}>Digital Modelling - Create digital models of your business to get you transformed with ease </p>
                                <ul className={classes.samaiFeatureList}>
                                    <li>Define service/product lines and hierarchies</li>
                                    <li>Digi-Map business functions</li>
                                    <li>Model executive process</li>
                                    <li>Define controls for process execution</li>
                                    <li>Synchronised access across multiple channels</li>
                                    <li>Complete Lifecycle Management of "work-unit"</li>
                                </ul>
                            </Grid>
                            <Grid item  xs={12} sm={4} md={4}>
                                <p className={classes.samaiFeatureTitle}>Security - Solid framework secures all access giving you the confidence</p>
                                <ul className={classes.samaiFeatureList}>
                                    <li>Define executive structure through roles</li>
                                    <li>Configure and manage actions performed</li>
                                    <li>Secure access to all information</li>
                                    <li>Control and visibility into all activities</li>
                                </ul>
                            </Grid>
                            <Grid item  xs={12} sm={4} md={4}>
                                <p className={classes.samaiFeatureTitle}>Interaction - Improve effectiveness of your operations with mature communication and interactions with all stakeholders</p>
                                <ul className={classes.samaiFeatureList}>
                                    <li>Interact within the context of your service/product with your client</li>
                                    <li>Send targeted information to relevant groups</li>
                                    <li>Keep your clients updated on key notes through mass communication</li>
                                    <li>Interact with your colleagues on deliverables</li>
                                </ul>
                            </Grid>
                            <Grid item  xs={12} sm={4} md={4}>
                                <p className={classes.samaiFeatureTitle}>Organisation - Structure to efficiently run your business</p>
                                <ul className={classes.samaiFeatureList}>
                                    <li>Categorise your services/product lines with multiple levels of hierarchies</li>
                                    <li>Unified view of client information</li>
                                    <li>Define groups to organise your clients</li>
                                    <li>Manage association of clients to groups</li>
                                    <li>Group executive roles by work categories and actions</li>
                                    <li>Attach multiple executive processes for a business function</li>
                                    <li>Version control executive process</li>
                                    <li>Dynamic mapping of executive process to business function</li>
                                </ul>
                            </Grid>
                            <Grid item  xs={12} sm={4} md={4}>
                                <p className={classes.samaiFeatureTitle}>Instrumentation - Clear and precise instrumentation brings predictability to your goals.</p>
                                <ul className={classes.samaiFeatureList}>
                                    <li>Complete visibility into all capacity</li>
                                    <li>Dynamic real time view of work assigned</li>
                                    <li>Visibility into the status of all work</li>
                                </ul>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.samaiCallbackBox}>
                            <Grid item  xs={12} sm={2} md={2}>
                            </Grid>
                            <Grid item  xs={12} sm={8} md={8}>
                                With in-product live feedback, we will be in tune with you all through to ensure your aspirations become a reality.
                            </Grid>
                            <Grid item  xs={12} sm={2} md={2}>
                            </Grid>
                        </Grid>


                        <Grid container className={classes.samaiContactBox}>
                            <Grid item  xs={12} sm={12} md={12}>
                                What Next<br/>
                                We can adapt to suit your business.<br/>
                                Lets connect@samai.ai to discuss your goals so we can fine tune for you.
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Modal
                    open={OpenNewLogin}
                    onClose={handleNewLoginClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {bodyNewLogin}
                </Modal>
            </div>
        ) 
    }                                                                              
                                                                                        
}                                                                                       
                                                                                        
export default SamaiHomePage;  
