import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Modal from '@material-ui/core/Modal';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../actions";
import useCheckSessionApi from "../SessionValidity";
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AntSwitch from '@material-ui/core/Switch';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Paper from '@material-ui/core/Paper';
import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import TableSortLabel from '@material-ui/core/TableSortLabel';
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(AddressList, comparator) {
  const stabilizedThis = AddressList && AddressList.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'address', numeric: false, label: 'Address', },
  { id: 'address_type', numeric: false, label: 'Address Type', },
  // { id: 'is_active', numeric: false, label: 'Is Active', },
  { id: 'actions', numeric: false, label: 'Actions', },
];
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          ( headCell.id === "actions")?
            (
              <TableCell
                key={headCell.id}
                align="center"
                style={{fontWeight: 'bold', fontSize: 17,  width: "1%"}}
              >
                {headCell.label}
              </TableCell>
            )
            :(
            <TableCell
              key={headCell.id}
              // align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
              align="center"
              style={{fontWeight: 'bold', fontSize: 17,  width: "10%"}}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
            )
          
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
//   intl: PropTypes.shape.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center'
  },
  wrapper: {
    margin: "10%",
    marginRight: "10%",
    marginTop: "8%",
    marginBottom: "3%",
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paper: {
    position: 'absolute',
    width: 500,
    // height: 650,
    // top:'2%',
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'10%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonSwalDelete: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
  },
  buttonSwalCancel: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
    marginRight: "2%",
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  pageHeaderLabel:{
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
  },
  buttonAddNew: {
    textAlign: "right",
    marginTop:"3%",
    marginLeft: "40%",
  },
  buttonDelete: {
    backgroundColor: "#de4545",
    color:"white",
    '&:hover': {
      backgroundColor: '#e85f5f',
      color: 'white',
    },
  },
  buttonShowHistory: {
    textAlign: "right",
    marginTop:"-8.8%",
    marginLeft: "45%",
  },
  backButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  backArrow: {
    marginRight: "10%",
  },

}));

export default function UserAddressDetails() {
    const classes = useStyles();
    // const [AddressList, setAddressList] = React.useState([
    //   { id: 1, address: "Pune"},
    //   { id: 2, address: "Mumbai"},
    //   { id: 3, address: "Delhi"},
    // ]);
    const [ ShowProcedure, setShowProcedure ] = React.useState(false);
    const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
    const [flag , setflag] = React.useState(false);
    const [AddressList, setAddressList] = React.useState([]);
    const [newAddressDetails, setnewAddressDetails] = React.useState({
      address_id: "",
      full_address: "",
      is_active: true,
      address_type: "",
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')), 
      client_user_id: "",
      updated_by: "self",
    });
    const [yesNo, setyesNo] = React.useState([
      { value: true , name: 'Yes'  },
      { value: false , name: 'No'  },
    ]);
    const [fullAddressErr, setfullAddressErr] = React.useState("");
    const [isActiveErr, setisActiveErr] = React.useState("");
    const [addressTypeErr, setaddressTypeErr] = React.useState("");
    
    const [openAddNewAddress, setopenAddNewAddress] = React.useState(false);

    const [openEditAddress, setopenEditAddress] = React.useState(false);
    const [editAddressDetails,seteditAddressDetails] = React.useState({
      address_id: "",
      full_address: "",
      address_type: "",
      is_active: true,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')), 
      client_user_id: "",
      updated_by: "self",
    });
    const [fullAddressEditErr, setfullAddressEditErr] = React.useState("");
    const [isActiveEditErr, setisActiveEditErr] = React.useState("");
    const [addressTypeEditErr, setaddressTypeEditErr] = React.useState("");

    const [deleteAddressDetails, setdeleteAddressDetails] = React.useState({
      address_id: "",
      full_address: "",
      is_active: true,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')), 
      client_user_id: "",
      updated_by: "self",
    });

    const [clientAddressLogDetails, setclientAddressLogDetails] = React.useState();

    const [clientIdFromLocal, setclientIdFromLocal] = React.useState();


    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);

    const [openShowHistory, setopenShowHistory] = React.useState(false);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(false);
    
    const dispatch = useDispatch();

    // sort
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // pagination
    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, AddressList && AddressList.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        console.log("handleChangeRowsPerPage",parseInt(event.target.value, 10))
        let valueHere = parseInt(event.target.value, 10)
        console.log("valueHere",valueHere, JSON.stringify(valueHere))
        let valueToCompare = JSON.stringify(valueHere)
        
        // If array is null then for all it's showing nan that's why this condition is added
        if( valueToCompare === "null" ){
          console.log("in if NAN condition", parseInt(event.target.value, 10))
          setRowsPerPage(0);
        }
        else{
          setRowsPerPage(parseInt(event.target.value, 10));
        }

        setPage(0);
    };
    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    useEffect(() => {
      setflag(true);
      dispatch(userActions.checkSessionValidity());
      // readItemFromStorage();
      setShowProcedure(true);

      // let updated_by_here = '';
      // let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));
      // console.log("Role of user",role_of_user)
      // if( role_of_user === "client"){
      //   updated_by_here = "self"
      // }
      // else{
      //   updated_by_here = "business"
      // }

      // let logged_in_user_id = JSON.parse(localStorage.getItem('user_id'));
      // console.log("logged_in_user_id",logged_in_user_id)

      // setnewAddressDetails({...newAddressDetails, updated_by: updated_by_here, client_user_id:logged_in_user_id})

      // let user_details = JSON.parse(localStorage.getItem('users'));
      // console.log("user_details in client contact",user_details,user_details.data.user_details.business_link.client_id)
      // let client_id = user_details.data.user_details.business_link.client_id;

      // setclientIdFromLocal(client_id)
      let business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'device_token': device_token, 'session_id': session_id, 'business_id': business_id })
      };
      fetch( process.env.REACT_APP_GET_USER_ADDRESSES , requestOptions)
        .then((Response) => Response.json())
        .then(addressDetails => {
          setShowProcedure(false)
          console.log("addressDetails are",addressDetails);
          if(addressDetails.success == true)
          {
            let address = addressDetails.data.addresses
            let addressLog = addressDetails.data.addresses_log
            let AddressListMerger = [...address, ...addressLog]
            setAddressList(AddressListMerger)
          }
          if(addressDetails.success == false){
            setAddressList([])
            Swal.fire({
              icon: 'error',
              text: addressDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          }
      })
      .catch(err => {
        setAddressList([])
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

      // let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));
      
      // to get address log
      if(role_of_user == "client"){

        let user_details = JSON.parse(localStorage.getItem('users'));
      console.log("user_details in client contact",user_details,user_details.data.user_details.business_link.client_id)
      let client_id = user_details.data.user_details.business_link.client_id;

      setclientIdFromLocal(client_id)
      
      const requestOptionsLog = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'client_id':client_id ,'device_token': device_token, 'session_id': session_id, 'business_id': business_id })
      };
      fetch( process.env.REACT_APP_GET_CLIENT_DETAILS , requestOptionsLog)
        .then((Response) => Response.json())
        .then(clientDetails => {
          setShowProcedure(false)
          console.log("clientDetails are",clientDetails.data.client_details.addresses);
          if(clientDetails.success == true)
          {
            if(clientDetails.data.client_details.addresses){
              setclientAddressLogDetails(clientDetails.data.client_details.addresses.addresses_log)
            }
            else{
              setclientAddressLogDetails([]);
            }
          }
          if(clientDetails.success == false){
            setclientAddressLogDetails([])
            Swal.fire({
              icon: 'error',
              text: clientDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          }
      })
      .catch(err => {
        console.log("err here is",err)
        setclientAddressLogDetails([])
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          // text: "Server Error. Please try again.",
          text: err,
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    }

    }, [flag]);

    // const readItemFromStorage = async() => {
    //   try {
    //     setDeviceToken(deviceTokenFromRedux)
    //     setSessionId(sessionIdFromRedux)
    //     setflag(false)    
    //   } catch (error) {
    //     console.log("ERROR:",error);        }
    // };

    // Add Address functions
    const handelAddNewAddressOpen = () => {
      setopenAddNewAddress(true)
    }

    const handelAddNewAddressClose = () => {
      setopenAddNewAddress(false);
      setShowProcedure(false);
      setnewAddressDetails({
        address_id: "",
        client_user_id: "",
        full_address: "",
        is_active: true,
        address_type: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')), 
        updated_by: "self",
      });
      setfullAddressErr("");
      // setisActiveErr("");
      setaddressTypeErr("");
    }

    const validateAddNewAddress = () => {
      let fullAddressErr = '';
      // let isActiveErr = '';
      let addressTypeErr = '';

      if(!newAddressDetails.full_address)  {
          fullAddressErr = 'Please Enter Full Address';
      }
      if(!newAddressDetails.address_type)  {
        addressTypeErr = 'Please Select Address Type';
      }
      // if(!newAddressDetails.is_active)  {
      // if(newAddressDetails.is_active == "" &&  newAddressDetails.is_active !== false  && newAddressDetails.is_active !== true){
      //     isActiveErr = 'Please Select Is Active';
      // }

      if(fullAddressErr || addressTypeErr ){
        setfullAddressErr(fullAddressErr);
        // setisActiveErr(isActiveErr);
        setaddressTypeErr(addressTypeErr);
        return false;
      }
      return true;
    }

    const handelAddAddressSubmit = () => {
      setShowProcedure(true);
      newAddressDetails.business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));

      console.log("Add adddress clicked",newAddressDetails)
      const isValid = validateAddNewAddress();
      if(isValid){
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(newAddressDetails)
        };
        return fetch( process.env.REACT_APP_CREATE_ADDRESS , requestOptions)
          .then((Response) => Response.json())
          .then(addressAdded => {
            setShowProcedure(false);
            console.log("addressAdded",addressAdded.success);
            console.log("addressAdded",addressAdded);
            if(addressAdded.success == true){
              Swal.fire({
                icon: 'success',
                text: "Address Added Succesfully",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              })
              setopenAddNewAddress(false);
              setnewAddressDetails({
                address_id: "",
                client_user_id: "",
                full_address: "",
                is_active: true,
                address_type: "",
                device_token: JSON.parse(localStorage.getItem('device_token')),
                session_id: JSON.parse(localStorage.getItem('session_id')), 
                updated_by: "self",
              });
              setfullAddressErr("");
              setisActiveErr("");
              setaddressTypeErr("");
            }
            if(addressAdded.success == false ){
              setopenAddNewAddress(true);
              Swal.fire({
                icon: 'error',
                text: addressAdded.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                // timer: 5000,
              })
            } 
          setflag(false);            
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
      }
    }

    const bodyAddNewAddress = (
      <div className={classes.paper}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <h2>Add Address</h2>
      </div>
      <div className={classes.modalBody}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="full_address"
          label="Full Address"
          name="full_address"
          autoComplete="full_address"
          inputProps={{ maxLength: 200 }}
          value={newAddressDetails.full_address}
          onChange={(e) => setnewAddressDetails({...newAddressDetails, full_address:e.target.value }) }
        />
        <div className={classes.validation}>{(newAddressDetails.full_address)?(<div></div>):(fullAddressErr)}</div>

        <FormControl className={classes.formControl}>
          <InputLabel id="address_type">Address Type*</InputLabel>
          <Select
            labelId="address_type"
            id="address_type"
            value={newAddressDetails.address_type}
            onChange={(e) => setnewAddressDetails({...newAddressDetails, address_type:e.target.value }) }
          >
            {/* {yesNo.map(item => */}
              <MenuItem value="home">Home</MenuItem>
              <MenuItem value="work">Work</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            {/* )} */}
          </Select>
        </FormControl>
        <div className={classes.validation}>{(newAddressDetails.address_type)?(<div></div>):(addressTypeErr)}</div>

        {/* <FormControl className={classes.formControl}> */}
          {/* <InputLabel id="is_active">Is Active*</InputLabel>
          <Grid container item xs={12} style={{marginTop:"8%"}}>
            <RadioGroup
              row
              id ="is_active"
              aria-label="is_active"
              name="is_active"
              defaultValue={JSON.stringify(newAddressDetails.is_active)}
              onChange={ (e) => setnewAddressDetails({...newAddressDetails, is_active: JSON.parse(e.target.value) })}
            >                  
              <div>
                <FormControlLabel
                  value="true"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "Yes"
                />
              </div>
              <div>
                <FormControlLabel
                  value="false"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "No"
                />
              </div>
            </RadioGroup>
          </Grid>
          <div className={classes.validation}>{(newAddressDetails.is_active == "" &&  newAddressDetails.is_active !== false  && newAddressDetails.is_active !== true)?(isActiveErr):(<div></div>)}</div> */}

          {/* <Select
            labelId="is_active"
            id="is_active"
            value={newAddressDetails.is_active}
            onChange={(e) => setnewAddressDetails({...newAddressDetails, is_active:e.target.value }) }
          >
            {yesNo.map(item =>
              <MenuItem value={item.value}>{item.name}</MenuItem>
            )}
          </Select> */}
        {/* </FormControl> */}
        {/* <div className={classes.validation}>{(newAddressDetails.is_active)?(<div></div>):(isActiveErr)}</div> */}
        

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelAddAddressSubmit}
          >
            Add Address
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handelAddNewAddressClose}
          >
            Cancel
          </Button>
        </div>
      </div>
      </div>
    )

    // Edit Address Functions
    const handleEditAddress = async(item) => {
      console.log("item",item);
      await seteditAddressDetails({
        address_id: item.address_id,
        full_address: item.address,
        address_type: item.address_type,
        is_active: item.is_active,
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')), 
        client_user_id: "",
        updated_by: "self",
      });
      console.log("editAddressDetails",editAddressDetails);
      handleEditAddressOpen();
    }

    const handleEditAddressOpen = () => {
      setopenEditAddress(true)
    }

    const handleEditAddressClose = () => {
      setopenEditAddress(false);
      setfullAddressEditErr(fullAddressEditErr);
      setaddressTypeEditErr(addressTypeEditErr);
      // setisActiveEditErr(isActiveEditErr);
    }

    const validateEditNewAddress = () => {
      let fullAddressEditErr = '';
      let addressTypeEditErr = '';
      // let isActiveEditErr = '';

      if(!editAddressDetails.full_address)  {
          fullAddressEditErr = 'Please Enter Full Address';
      }
      if(!editAddressDetails.address_type)  {
        addressTypeEditErr = 'Please Select Address Type';
      }
      // if(!editAddressDetails.is_active)  {
      // if(editAddressDetails.is_active == "" &&  editAddressDetails.is_active !== false  && editAddressDetails.is_active !== true)  {
      //   isActiveEditErr = 'Please Select Is Active';
      // }

      if(fullAddressEditErr || addressTypeEditErr ){
        setfullAddressEditErr(fullAddressEditErr);
        setaddressTypeEditErr(addressTypeEditErr);
        // setisActiveEditErr(isActiveEditErr);
        return false;
      }
      return true;
    }

    const handelEditAddressSubmit = () => {
      setShowProcedure(true);
      console.log("Edit adddress clicked",editAddressDetails)
      editAddressDetails.business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));
      // setopenEditAddress(false)
      const isValid = validateEditNewAddress();
      if(isValid){
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(editAddressDetails)
        };
        return fetch( process.env.REACT_APP_CREATE_ADDRESS , requestOptions)
          .then((Response) => Response.json())
          .then(addressEdited => {
            setShowProcedure(false);
            console.log("addressEdited",addressEdited.success);
            console.log("addressEdited",addressEdited);
            if(addressEdited.success == true){
              Swal.fire({
                icon: 'success',
                text: "Address Updated Successfully",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              })
              setopenEditAddress(false);
              seteditAddressDetails({
                address_id:"",
                full_address: "",
                address_type: "",
                is_active: true,
                device_token: JSON.parse(localStorage.getItem('device_token')),
                session_id: JSON.parse(localStorage.getItem('session_id')), 
                client_user_id: "",
                updated_by: "self",
              });
              setfullAddressEditErr("");
              setisActiveEditErr("");
              setaddressTypeEditErr("");
            }
            if(addressEdited.success == false ){
              setopenEditAddress(true);
              Swal.fire({
                icon: 'error',
                text: addressEdited.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                // timer: 5000,
              })
            } 
          setflag(false);            
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
      }
    }

    const bodyEditAddrress = (
      <div className={classes.paper}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <h2>Edit Address</h2>
      </div>
      <div className={classes.modalBody}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="full_address"
          label="Full Address"
          name="full_address"
          autoComplete="full_address"
          inputProps={{ maxLength: 200 }}
          value={editAddressDetails.full_address}
          onChange={(e) => seteditAddressDetails({...editAddressDetails, full_address:e.target.value }) }
        />
        <div className={classes.validation}>{(editAddressDetails.full_address)?(<div></div>):(fullAddressEditErr)}</div>

        <FormControl className={classes.formControl}>
          <InputLabel id="address_type">Address Type*</InputLabel>
          <Select
            labelId="address_type"
            id="address_type"
            value={editAddressDetails.address_type}
            onChange={(e) => seteditAddressDetails({...editAddressDetails, address_type:e.target.value }) }
          >
              <MenuItem value="home">Home</MenuItem>
              <MenuItem value="work">Work</MenuItem>
              <MenuItem value="other">Other</MenuItem>
          </Select>
        </FormControl>
        <div className={classes.validation}>{(editAddressDetails.address_type)?(<div></div>):(addressTypeEditErr)}</div>

        {/* <FormControl className={classes.formControl}>
          <InputLabel id="is_active">Is Active*</InputLabel>
          <Grid container item xs={12} style={{marginTop:"8%"}}>
            <RadioGroup
              row
              id ="is_active"
              aria-label="is_active"
              name="is_active"
              defaultValue={JSON.stringify(editAddressDetails.is_active)}
              onChange={ (e) => seteditAddressDetails({...editAddressDetails, is_active: JSON.parse(e.target.value) })}
            >                  
              <div>
                <FormControlLabel
                  value="true"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "Yes"
                />
              </div>
              <div>
                <FormControlLabel
                  value="false"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "No"
                />
              </div>
            </RadioGroup>
          </Grid>
          <div className={classes.validation}>{(editAddressDetails.is_active == "" &&  editAddressDetails.is_active !== false  && editAddressDetails.is_active !== true)?(isActiveEditErr):(<div></div>)}</div> */}

          {/* <Select
            labelId="is_active"
            id="is_active"
            value={editAddressDetails.is_active}
            onChange={(e) => seteditAddressDetails({...editAddressDetails, is_active:e.target.value }) }
          >
            {yesNo.map(item =>
              <MenuItem value={item.value}>{item.name}</MenuItem>
            )}
          </Select> */}
        {/* </FormControl> */}
        {/* <div className={classes.validation}>{(editAddressDetails.is_active)?(<div></div>):(isActiveEditErr)}</div> */}
        

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelEditAddressSubmit}
          >
            Update Address
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handleEditAddressClose}
          >
            Cancel
          </Button>
        </div>
      </div>
      </div>
    )

  // const handleDeleteAddress = async(item) => {
  //     console.log("in delete",item, item.is_active);
  //     let idtoDelete =  item.address_id;
  //     await setdeleteAddressDetails({
  //       address_id: item.address_id,
  //       full_address: item.address,
  //       address_type: item.address_type,
  //       is_active: !item.is_active,
  //       device_token: JSON.parse(localStorage.getItem('device_token')),
  //       session_id: JSON.parse(localStorage.getItem('session_id')), 
  //     });
  //     console.log("deleteAddressDetails",deleteAddressDetails);

  //     let deleteAddressDetailsFinal = ({
  //       address_id: item.address_id,
  //       full_address: item.address,
  //       address_type: item.address_type,
  //       is_active: !item.is_active,
  //       device_token: JSON.parse(localStorage.getItem('device_token')),
  //       session_id: JSON.parse(localStorage.getItem('session_id')), 
  //     });
  //     console.log("deleteAddressDetailsFinal",deleteAddressDetailsFinal);
        
  //     const swalWithBootstrapButtons = Swal.mixin({
  //       customClass: {
  //         confirmButton: classes.buttonSwalDelete,
  //         cancelButton: classes.buttonSwalCancel,
  //       },
  //       buttonsStyling: false
  //     })
  //     swalWithBootstrapButtons.fire({
  //       title: 'Are you sure?',
  //       text: "You won't be able to revert this!",
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#d33',
  //       confirmButtonText: 'Yes',
  //       cancelButtonText: 'No, cancel',
  //       reverseButtons: true
  //     }).then((result) => {

  //       if (result.value) {
  //         setShowProcedure(true);
  //         const requestOptions = {
  //           method: 'POST',
  //           headers: authHeader(),
  //           body: JSON.stringify(deleteAddressDetailsFinal)
  //         };
  //         return fetch( process.env.REACT_APP_CREATE_ADDRESS , requestOptions)
  //           .then((Response) => Response.json())
  //           .then(addressDeleted => {
  //             setShowProcedure(false);
  //             console.log("addressDeleted",addressDeleted.success);
  //             console.log("addressDeleted",addressDeleted);
  //             if(addressDeleted.success == true){
  //               Swal.fire({
  //                 icon: 'success',
  //                 text: "Data Updated Succesfully",
  //                 confirmButtonColor: 'primary',
  //                 confirmButtonText: 'OK',
  //                 timer: 5000,
  //               })
  //               setdeleteAddressDetails({
  //                 address_id: "",
  //                 full_address: "",
  //                 is_active: "",
  //                 device_token: JSON.parse(localStorage.getItem('device_token')),
  //                 session_id: JSON.parse(localStorage.getItem('session_id')), 
  //               });
  //             }
  //             if(addressDeleted.success == false ){
  //               Swal.fire({
  //                 icon: 'error',
  //                 text: addressDeleted.errors,
  //                 confirmButtonColor: '#d33',
  //                 confirmButtonText: 'OK',
  //                 // timer: 5000,
  //               })
  //             } 
  //           setflag(false);            
  //         })
  //         .catch(err => {
  //           setShowProcedure(false);
  //           Swal.fire({
  //             icon: 'error',
  //             // text: "Something went wrong",
  //             text: "Server Error. Please try again.",
  //             confirmButtonColor: '#d33',
  //             confirmButtonText: 'OK'
  //           })
  //         });
                
  //       } else if (
  //         /* Read more about handling dismissals below */
  //         result.dismiss === Swal.DismissReason.cancel
  //       ) 
  //       {
  //         // swalWithBootstrapButtons.fire(
  //         //   'Cancelled',
  //         //   'Your imaginary file is safe :)',
  //         //   'error'
  //         // )
  //       }
  //     })
  // }

  const handleDeleteAddress = async(item) => {
    console.log("in delete",item, item.is_active);
    let idtoDelete =  item.address_id;
    await setdeleteAddressDetails({
      address_id: item.address_id,
      full_address: item.address,
      address_type: item.address_type,
      is_active: !item.is_active,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')), 
      client_user_id: "",
      updated_by: "self",
    });
    console.log("deleteAddressDetails",deleteAddressDetails);

    let deleteAddressDetailsFinal = ({
      address_id: item.address_id,
      full_address: item.address,
      address_type: item.address_type,
      is_active: !item.is_active,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')), 
      client_user_id: "",
      business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
      updated_by: "self",
    });
    console.log("deleteAddressDetailsFinal",deleteAddressDetailsFinal);

        setShowProcedure(true);
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(deleteAddressDetailsFinal)
        };
        return fetch( process.env.REACT_APP_CREATE_ADDRESS , requestOptions)
          .then((Response) => Response.json())
          .then(addressDeleted => {
            setShowProcedure(false);
           
            console.log("addressDeleted",addressDeleted);
            if(addressDeleted.success == true){
              console.log("addressDeleted success",addressDeleted);
              // Swal.fire({
              //   icon: 'success',
              //   text: "Data Updated Succesfully",
              //   confirmButtonColor: 'primary',
              //   confirmButtonText: 'OK',
              //   timer: 5000,
              // })
              setdeleteAddressDetails({
                address_id: "",
                full_address: "",
                is_active: true,
                device_token: JSON.parse(localStorage.getItem('device_token')),
                session_id: JSON.parse(localStorage.getItem('session_id')), 
                client_user_id: "",
                updated_by: "self",
              });
            }
            if(addressDeleted.success == false ){
              Swal.fire({
                icon: 'error',
                text: addressDeleted.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                // timer: 5000,
              })
            } 
          setflag(false);            
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
}

  // history
  const handleShowHistoryModalOpen = () => {
    setopenShowHistory(true)
  }

  const handleShowHistoryModalClose = () => {
    setopenShowHistory(false)
  }

  const bodyshowHistory = (
    <div className={classes.paper}>
      <div > 
        <Grid container item xs={12}>
          <Grid item xs={10} style={{marginLeft:'5%'}}>
            <h2>Address History</h2>
          </Grid>
          <Grid item xs={1} style={{marginTop:'3%', marginLeft:'1%'}}>
            <CloseIcon onClick={()=>handleShowHistoryModalClose()} style={{cursor:'pointer'}}/>
          </Grid>
        </Grid>
      </div>
      <div className={classes.modalBody}>
        {
          clientAddressLogDetails && clientAddressLogDetails.map((e, i)=>(
            console.log("e in clientAddressLogDetails", e),
            <Grid style={{backgroundColor:'#bfd3e0'}}>
              <Grid style={{padding: 10}}>{e.address}</Grid>
            </Grid>
          ))
        }

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleShowHistoryModalClose}
          >
            Ok
          </Button>
        </div>
      </div>
      </div>
  )

  let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));
  let user_details = JSON.parse(localStorage.getItem('users'));
  console.log("user_details in profile",user_details)
    
    return(
      console.log("AddressList",AddressList),
      console.log("clientAddressLogDetails",clientAddressLogDetails),
        <div className={classes.root}>
        {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        <div className={classes.wrapper}>
            {/* <div>Address Details</div> */}
            <Grid container>
              <Grid item xs={1} className={classes.pageHeaderLabel} >
                <Button href="/UsersProfile" className={classes.backButton}>
                    <span className={classes.backArrow}>
                    <ArrowBackIcon fontSize="small" />
                  </span>
                  Back
                </Button>
              </Grid>
            </Grid>
            <Grid container item xs={12} style={{ marginTop:"2%"}}>
              <Grid item xs={8} className={classes.pageHeaderLabel} style={{marginTop:'0.5%'}} >
                {/* <h2>List of Addresses</h2> */}
                <text>List of Addresses</text>
              </Grid>

              <Grid item xs={1}>
                {
                  (role_of_user == "client")?
                  (
                    <Button
                      variant="contained"
                      color="primary"
                      // className={classes.buttonAddNew}
                      onClick={handleShowHistoryModalOpen}
                    >
                      History
                    </Button>
                  )
                  :(
                      <Grid></Grid>
                  )
                }
              </Grid>
              
              <Grid item xs={3} >
                {/* <h2>Add New Business</h2> */}
                <Button
                  variant="contained"
                  color="primary"
                  // className={classes.buttonAddNew}
                  startIcon={<AddIcon />}
                  onClick={handelAddNewAddressOpen}
                >
                  Add New Address
                </Button>
              </Grid>
              {/* add new address modal */}
              <Modal
                open={openAddNewAddress}
                onClose={handelAddNewAddressClose}
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                {bodyAddNewAddress}
              </Modal>
            </Grid>
            <Grid item xs={12} style={{marginTop:"2%"}}>
              {/* {
                (role_of_user == "client")?
                (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonShowHistory}
                    onClick={handleShowHistoryModalOpen}
                  >
                    History
                  </Button>
                )
                :(
                    <Grid></Grid>
                )
              } */}
              
              {/* show history modal */}
              <Modal
                open={openShowHistory}
                onClose={handleShowHistoryModalClose}
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                {bodyshowHistory}
              </Modal>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <TableContainer className={classes.tableWrapper} component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={AddressList && AddressList.length}
                    />
                    <TableBody>
                        {stableSort(AddressList && AddressList, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        // .filter(item => item.is_active == true ).map((AddressList) => {
                          .map((AddressList) => {
                          // console.log("row in table map",AddressList)
                            return (
                            <TableRow 
                                key={AddressList.id} 
                                hover={true} classes={{hover: classes.hover}}
                                tabIndex={-1}
                                align="center"
                            >
                              <TableCell sx={{
                                width: '10%',
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                                overflowWrap: 'anywhere',
                                }}
                                align="center" 
                              >
                                  {AddressList.address}</TableCell>
                              <TableCell width="10%" align="center">{AddressList.address_type}</TableCell>
                              {/* <TableCell width="10%" align="center">{AddressList.is_active ? 'True' : 'false'}</TableCell>  */}
                              <TableCell width="5%" align="center" >
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  className={classes.button}
                                  onClick={() => handleEditAddress(AddressList)}
                                  >
                                  {/* Edit */}
                                  Update
                                </Button>
                                {/* &nbsp;&nbsp;&nbsp; */}
                                {/* <Button
                                  size="small"
                                  variant="contained"
                                  className={classes.buttonDelete}
                                  onClick={() => handleDeleteAddress(AddressList)}
                                  >
                                  Delete
                                </Button> */}
                                {/* <AntSwitch 
                                  checked={AddressList.is_active} 
                                  onChange={() => handleDeleteAddress(AddressList)} 
                                  size="small" 
                                  name="item.is_active" 
                                  value="item.is_active"
                                  color="primary"
                                /> */}
                              </TableCell>
                            </TableRow>
                            );
                        })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { 
                                  label: 'All', value: AddressList && AddressList.length 
                                }]}
                                colSpan={8}
                                count={AddressList && AddressList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                            </TableRow>
                        </TableFooter>
                </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Modal
              open={openEditAddress}
              onClose={handleEditAddressClose}
              className={classes.modal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {bodyEditAddrress}
            </Modal>
        </div>  
        </div>
    )
}