import React, {useCallback, useEffect} from "react";
import fetch from "cross-fetch";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { injectIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../actions";
import { debounce } from "lodash";

function SearchView({ intl, match }) {

  const [device_token, setdevice_token ] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id ] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [business_id, setbusiness_id ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const [role_of_user, setrole_of_user] = React.useState(JSON.parse(localStorage.getItem('role_of_user')));

  const dispatch = useDispatch();
  const infoFromRedux = useSelector(state => state);
  const deviceTokenFromRedux = useSelector(state => state.loginDetails.deviceToken);
  const sessionIdFromRedux = useSelector(state => state.loginDetails.sessionId);
  const businessIdFromRedux = useSelector(state => state.loginDetails.businessId);

  const [searchListWhole, setsearchListWhole] = React.useState([]);
  // const [searchListCases, setsearchListCases] = React.useState([]);
  // const [searchListMessages, setsearchListMessages] = React.useState([]);
  // const [searchListClients, setsearchListClients] = React.useState([]);
  const [selectedSearch, setselectedSearch] = React.useState("");
  
  const [redirect, setRedirect] = React.useState(false);
  const [searchItem, setSearchItem] = React.useState();


  // useEffect(() => {
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: authHeader(),
  //     body: JSON.stringify({'device_token': deviceTokenFromRedux, 'session_id': sessionIdFromRedux})
  //   };
  //   fetch( process.env.REACT_APP_GET_SEARCH_LOG , requestOptions)
  //     .then((Response) => Response.json())
  //     .then( async(SearchLogDetails) => {
  //       console.log("SearchLogDetails in search",SearchLogDetails.data);
  //       if(SearchLogDetails.data.case_searchlog)
  //       {
  //         searchListWhole(SearchLogDetails.data.case_searchlog)
  //       }
  //       else{
  //         searchListWhole([])
  //       }
  //     })
  //     .catch(err => {
  //       Swal.fire({
  //         icon: 'error',
  //         text: "Something went wrong",
  //         confirmButtonColor: '#d33',
  //         confirmButtonText: 'OK'
  //       })
  //     });

  // }, []);

  const handleSearchLogClick = () => {
    dispatch(userActions.checkSessionValidity());
    console.log("handleSearchLogClick is clicked")
    // if( role_of_user == "client"){
    //   Swal.fire({
    //     icon: 'error',
    //     text: "Search for client is not implemented yet",
    //     confirmButtonColor: '#d33',
    //     confirmButtonText: 'OK'
    //   })
    // }
    // else{
      const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify({
            // 'device_token': deviceTokenFromRedux, 'session_id': sessionIdFromRedux, 
            'device_token': device_token, 'session_id': session_id,
            'business_id': business_id
          })
        };
        fetch( process.env.REACT_APP_GET_SEARCH_LOG , requestOptions)
          .then((Response) => Response.json())
          .then( async(SearchLogDetails) => {
            console.log("SearchLogDetails in search",SearchLogDetails.data);
            if(SearchLogDetails.data.case_searchlog)
            {
            var serachLogAfterName=[];
              SearchLogDetails.data.case_searchlog.map((data) => {
                  // console.log("data is",data)
                  data.name = data.search_string
                  data.username = data.username
                  data.nameOrg = data.name
                  serachLogAfterName = serachLogAfterName.concat(data);
                  // console.log("data join : ",MessageAfterName);
                })           
              setsearchListWhole(serachLogAfterName)
            }
            else{
              setsearchListWhole([])
            }
          })
          // .catch(err => {
          //   Swal.fire({
          //     icon: 'error',
          //     // text: "Something went wrong",
          //     text: "Server Error. Please try again.",
          //     confirmButtonColor: '#d33',
          //     confirmButtonText: 'OK'
          //   })
          // });
    // }
  }

  const fetchSearchResults = useCallback(
    debounce(async(search_string)=>{
      if( search_string !== "" ){
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify({'search_str': search_string, 'business_id': business_id,
          // 'device_token': deviceTokenFromRedux, 'session_id': sessionIdFromRedux,'business_id': 1, 
          'device_token': device_token, 'session_id': session_id,
          'sort_by': '', 'priority': '', 'tags': [], 'assigned_users': [],
          "client_type": "ALL" })
        };
        console.log("requestOptions of search ",requestOptions)
        fetch( process.env.REACT_APP_SEARCH_CASES , requestOptions)
          .then((Response) => Response.json())
          .then( async(SearchDetails) => {
            console.log("SearchDetails in search",SearchDetails.data);
            console.log("SearchDetails in search search_results",SearchDetails.data.search_results);
            if(SearchDetails.data.search_results)
            {
              // setsearchListWhole(SearchDetails.data.search_results);
              // setsearchListCases(SearchDetails.data.search_results.cases);
              // setsearchListMessages(SearchDetails.data.search_results.messages);
              // setsearchListClients(SearchDetails.data.search_results.clients);
  
              // to add name in message array
              var MessageAfterName=[];
              SearchDetails.data.search_results.messages.map((data) => {
                console.log("data is",data)
                data.name = data.message
                MessageAfterName = MessageAfterName.concat(data);
                // console.log("data join : ",MessageAfterName);
              })
              console.log("data join : MessageAfterName ",MessageAfterName);
  
              // / to add name in clients array
              var ClientsAfterName=[];
              SearchDetails.data.search_results.clients.map((data) => {
                console.log("data is",data)
                data.name = data.client_name
                ClientsAfterName = ClientsAfterName.concat(data);
                // console.log("data join : ",ClientsAfterName);
              })
              console.log("data join : ClientsAfterName ",ClientsAfterName);
              
              // to merge all 3 arrays
              const wholeSearchResultIs = [...SearchDetails.data.search_results.cases, 
                ...SearchDetails.data.search_results.messages, ...SearchDetails.data.search_results.clients];
  
              console.log("wholeSearchResultIs",wholeSearchResultIs);
              setsearchListWhole(wholeSearchResultIs);
              
            }
            if(SearchDetails.success == false){
              if( SearchDetails.error_code === null){
                console.log("error is == null")
              }
              else{
                console.log("error is == not null")
                setsearchListWhole([])
                Swal.fire({
                  icon: 'error',
                  // text: SearchDetails.errors,
                  // text: "Something went wrong",
                  text: SearchDetails.errors,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              }
            }
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });
      }
    },300),
    []
  )

  const handleSearch = async(e) => {
    console.log("in handleSearch",e.target.value)
    let search_string = e.target.value;
    setSearchItem(search_string);
    await localStorage.setItem('searchStringIs', JSON.stringify(search_string));
    console.log("search_string",search_string)
    console.log("client search device_token,session_id,businessIdFromRedux,business_id ",
      deviceTokenFromRedux,sessionIdFromRedux,businessIdFromRedux,business_id);
    
    fetchSearchResults(search_string);
  }

  const handleHighlightChange = (event, option, reason) => {
    console.log("in handle HighlightChange", option)
    if (option) {
      // setSearchItem(option.name);
      // setSearchItem(option);
      // setselectedSearch(option);
    }
  };

  const handleClienSelect = async(value) => {
    console.log("value in handleClienSelect",value)

    // 
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'search_str': searchItem, 'business_id': businessIdFromRedux, 
      'device_token': device_token, 'session_id': session_id,
      'sort_by': '', 'priority': '', 'tags': [], 'assigned_users': [],
      "client_type": "ALL" })
    };
    fetch( process.env.REACT_APP_SEARCH_CASES , requestOptions)
        .then((Response) => Response.json())
        .then( async(SearchDetails) => {
          console.log("SearchDetails in search",SearchDetails.data);
          if(SearchDetails.data.search_results)
          {
            // to add name in message array
            var MessageAfterName=[];
            SearchDetails.data.search_results.messages.map((data) => {
              console.log("data is",data)
              data.name = data.message
              MessageAfterName = MessageAfterName.concat(data);
              // console.log("data join : ",MessageAfterName);
            })
            console.log("data join : MessageAfterName ",MessageAfterName);

            // / to add name in clients array
            var ClientsAfterName=[];
            SearchDetails.data.search_results.clients.map((data) => {
              console.log("data is",data)
              data.name = data.client_name
              ClientsAfterName = ClientsAfterName.concat(data);
              // console.log("data join : ",ClientsAfterName);
            })
            console.log("data join : ClientsAfterName ",ClientsAfterName);
            
            // to merge all 3 arrays
            const wholeSearchResultIs = [...SearchDetails.data.search_results.cases, 
              ...SearchDetails.data.search_results.messages, ...SearchDetails.data.search_results.clients];

            console.log("wholeSearchResultIs",wholeSearchResultIs);
            setsearchListWhole(wholeSearchResultIs);

            setSearchItem(value);
            await localStorage.setItem('searchStringIs', JSON.stringify(value.name));
            setRedirect(true);
            
          }
          if(SearchDetails.success == false){
            if( SearchDetails.error_code === null){
              console.log("error is == null")
              setsearchListWhole([])
              Swal.fire({
                icon: 'error',
                // text: SearchDetails.errors,
                text: "No Result For Your Search Criteria",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })

            }
            else{
              console.log("error is == not null")
              setsearchListWhole([])
              Swal.fire({
                icon: 'error',
                // text: SearchDetails.errors,
                text: "Something went wrong",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            }
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

    // 
  }

  const handleKeyDown = (e) => {
    console.log("e on enter",e,searchItem)
    if (["Enter"].includes(e.key)) {
      if(searchItem){
        // console.log("call api")
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify({'search_str': searchItem, 'business_id': businessIdFromRedux, 
          'device_token': device_token, 'session_id': session_id,
          'sort_by': '', 'priority': '', 'tags': [], 'assigned_users': [],
          "client_type": "ALL" })
        };
        fetch( process.env.REACT_APP_SEARCH_CASES , requestOptions)
          .then((Response) => Response.json())
          .then( async(SearchDetails) => {
            console.log("SearchDetails in search",SearchDetails.data);
            if(SearchDetails.data.search_results)
            {
              // to add name in message array
              var MessageAfterName=[];
              SearchDetails.data.search_results.messages.map((data) => {
                console.log("data is",data)
                data.name = data.message
                MessageAfterName = MessageAfterName.concat(data);
                // console.log("data join : ",MessageAfterName);
              })
              console.log("data join : MessageAfterName ",MessageAfterName);
  
              // / to add name in clients array
              var ClientsAfterName=[];
              SearchDetails.data.search_results.clients.map((data) => {
                console.log("data is",data)
                data.name = data.client_name
                ClientsAfterName = ClientsAfterName.concat(data);
                // console.log("data join : ",ClientsAfterName);
              })
              console.log("data join : ClientsAfterName ",ClientsAfterName);
              
              // to merge all 3 arrays
              const wholeSearchResultIs = [...SearchDetails.data.search_results.cases, 
                ...SearchDetails.data.search_results.messages, ...SearchDetails.data.search_results.clients];
  
              console.log("wholeSearchResultIs",wholeSearchResultIs);
              setsearchListWhole(wholeSearchResultIs);
  
              e.preventDefault();
              console.log("enteredd")
              setRedirect(true);
              
            }
            if(SearchDetails.success == false){
              if( SearchDetails.error_code === null){
                console.log("error is == null")
                setsearchListWhole([])
                Swal.fire({
                  icon: 'error',
                  // text: SearchDetails.errors,
                  text: "No Result For Your Search Criteria",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
                setSearchItem();
              }
              else{
                console.log("error is == not null")
                setsearchListWhole([])
                Swal.fire({
                  icon: 'error',
                  // text: SearchDetails.errors,
                  text: "Something went wrong",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              }
            }
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });
      }
      else{
        // console.log("don't")
      }
      
    }
  }

  if (redirect) {
    // return <Redirect to={`/searchresult?countryName:${searchItem.name}`} />
    // return <Redirect to={`/searchresult?searchString:${searchItem}`} />
    return <Redirect to={`/searchresult`} />
  }

  let role_of_user_here = JSON.parse(localStorage.getItem('role_of_user'));
  console.log("role_of_user_here on search==", role_of_user_here);

  if( role_of_user_here == "client"){
    return (
      <Grid item xs={12}>
        
      </Grid>
    );
  }

  else{
    return (
      console.log("infoFromRedux in return searchview",infoFromRedux),
      console.log("searchItem in return",searchListWhole),
      <Grid item xs={12}>
        <Autocomplete
          options={searchListWhole}
          // noOptionsText={"Nothing Related"}
          noOptionsText={"No Search Results"}
          onHighlightChange={handleHighlightChange}
          getOptionLabel={(option) => option.name}
          onChange={(event, value) => handleClienSelect(value)}
          // onKeyDown={(e,value) => handleKeyDown(e)}
          // autoHighlight={true}
          // autoSelect={true}
          // autoComplete
          // autoHighlight
          renderInput={(params) => 
            <TextField 
              {...params}
              placeholder="Search Case, Client and Messages"
              variant="outlined"
              value = {selectedSearch}
              onClick={handleSearchLogClick}
              onChange={handleSearch}
              onKeyDown={(e,value) => handleKeyDown(e)}
            />
          }
          
        />
      </Grid>
    );
  }
}

export default injectIntl(SearchView);


