import React, { useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import TaxManagementRecentCases from "../TaxManagementRecentCases";
import SearchView from "../SearchView";
import { FormattedMessage } from "react-intl";
import { userActions } from "../actions";
import Grid from "@material-ui/core/Grid";
import TaxManagementCaseDetails from "../TaxManagementCaseDetails";
import TaxManagementRecentClient from "../TaxManagementRecentClient";
import TaxManagementClientDetails from "../TaxManagementClientDetails";
import useCheckSessionApi from "../SessionValidity";
import { authHeader } from '../helpers';
import { useSelector, useDispatch } from 'react-redux';
import Paper from "@material-ui/core/Paper";
import { set } from "lodash";
import BusinessIcon from "@material-ui/icons/Business";
import { PropagateLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import { modal } from 'reactstrap';
import Modal from '@material-ui/core/Modal';
import Button from "@material-ui/core/Button";

import { useThemeContext, useThemeUpdate } from '../ThemeContext'

import ChartsFile from "./ChartsFile";
import UsersLoginInfo from "./UsersLoginInfo";
// import TermsAndConditionSystemAdmin from "../TermsAndConditions/TermsAndConditionSystemAdmin";

// import SessionValidity from '../SessionValidity';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '5%',
    // overflowY: 'scroll',
  },
  root2: {
    flexGrow: 1,
    marginTop: '50%',
  },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  quickAccessLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  recentCaseLabelContainer: {
    marginTop: "5%",
    marginBottom: "1%",
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  pageHeaderContainer: {
    marginBottom: "5%",
  },
  casesdisplay: {
    display: "block",
  },
  casesdisplayNone: {
    display: "none",
  },
  pageHeaderLabelClient: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "25px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
    marginTop: "2%",
  },
  paperWrapper: {
    margin: "10%",
    marginRight: "10%",
    marginTop: "8%",
    marginBottom: "3%",
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  businessHeading: {
    // display: 'flex',
    // justifyContent: 'center',
    // marginTop: '2%',
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "23px",
    color: 'black'
  },
  activeBusinessLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "20px",
    color: "black",
    // textAlign: "left",
  },
  IconColor: {
    color: 'blue',
    size: '10'
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  paperForSysAdminTAndC: {
    padding: theme.spacing(2),
    height: '100%',
    marginRight: "5%",
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // overflow: 'scroll', 
  },
  paperModalTandC: {
    // position: 'absolute',
    // alignItems: 'center',
    // justifyContent: 'center',
    // width: 600,
    // height: 650,
    // backgroundColor: theme.palette.background.paper,
    // // border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    // overflowY: 'scroll',

    // position: 'absolute',
    // width: 600,
    // // height: 500,
    // backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    // // top:'2%',
    // display: 'flex',
    // flexDirection: 'column',
    // // overflowY:'scroll',
    // overflow: 'auto',

    width: 600,
    height: 550,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  TandCHeaderBackgroud: {
    // display: 'flex',
    // alignItems: 'center', 
    // justifyContent: 'center',
    // // backgroundColor: '#23c1f7',
    // height:'10%',
    // marginTop:'2%',

    // backgroundColor: 'white',
    // // height:'12%',
    // alignItems: 'flex-start',
    // justifyContent: 'flex-start',
    // alignContent: 'flex-start',

    marginTop:"1%",
    backgroundColor: 'white',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
  },
  modalBodyTandC: {
    // padding: theme.spacing(2, 4, 3),  
    // width:'100%', 

    // padding: theme.spacing(9, 4, 3),
    // width: '100%',
    // alignItems: 'center',
    // justifyContent: 'center',
    // alignContent: 'center',

    padding: theme.spacing(1, 4, 3),
    // padding: theme.spacing(1, 4, 3, 3),
    width: '100%',
    height:'70%',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    overflow: 'auto', 
  },

  TandCModalFooter: {
    // padding: theme.spacing(0, 4),
    // marginTop: '8%',
    // display: 'flex',
    // alignItems: 'flex-end',
    // justifyContent: 'flex-end',
    // alignContent: 'flex-end',
    // // flex: 1,
    // marginBottom: '2%',

    padding: theme.spacing(0, 4),
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
  },

}));

export default function TaxManagementSearch(props) {
  const darkTheme = useThemeContext()
  const classes = useStyles();
  const [ShowProcedure, setShowProcedure] = React.useState(true);
  // const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  // const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  // const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const [device_token, setdevice_token] = React.useState();
  const [session_id, setsession_id] = React.useState();
  const [business_id, setbusiness_id] = React.useState();
  const [user_deatils_async, setuser_deatils_async] = React.useState(JSON.parse(localStorage.getItem('users')));
  const [user_deatils_redux, setuser_deatils_redux] = React.useState();
  const [user_name_redux, setuser_name_redux] = React.useState("");
  const [user_business_redux, setuser_business_redux] = React.useState("");
  const [searchChecked, setChecked] = React.useState(false);
  const [recentCaseChecked, setRecentCaseChecked] = React.useState(true);
  const [recentClientChecked, setRecentClientChecked] = React.useState(true);
  const [DeviceToken, setDeviceToken] = React.useState();
  const [SessionId, setSessionId] = React.useState();
  const [flag, setflag] = React.useState(false);
  // const [drawerOpenClose, setdrawerOpenClose] = React.useState(JSON.parse(localStorage.getItem('drawerOpenClose')));
  const [drawerOpenClose, setdrawerOpenClose] = React.useState();

  // t and c
  // const [TermsAndConditionAccepted, setTermsAndConditionAccepted] = React.useState(false);
  const [TermsAndConditionAccepted, setTermsAndConditionAccepted] = React.useState(false);
  const [TandCdata, setTandCdata] = React.useState();
  const [OpenTermsAndCondition, setOpenTermsAndCondition] = React.useState(false);

  // const [token, setToken] =  useState('');

  //  const [createSession, setcreateSession] = React.useState(false);

  const dispatch = useDispatch();
  const infoFromRedux = useSelector(state => state);
  const userInfoFromRedux = useSelector(state => state.users);
  const userInfoFromReduxLogDetails = useSelector(state => state.loginDetails.logedInUsersDetails);
  const deviceTokenFromRedux = useSelector(state => state.loginDetails.deviceToken);
  const sessionIdFromRedux = useSelector(state => state.loginDetails.sessionId);
  const businessIdFromRedux = useSelector(state => state.loginDetails.businessId);

  const [ipAddress, setIpAddress] = React.useState('');

  // ////////////////////////////////////////////////////////////////////////////
  // working 7 june this is working 27 july

  // let user = JSON.parse(localStorage.getItem('user'));
  // const [data, checkSessionValidity] = useCheckSessionApi({
  //   url: process.env.REACT_APP_CHECK_SESSION_VALIDITY,
  //   options: {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: "Token " + user.key,
  //     },
  //     // body: ({'device_token':deviceTokenFromRedux, 
  //     //       'session_id':sessionIdFromRedux}),
  //     body: JSON.stringify({
  //       device_token: deviceTokenFromRedux,
  //       session_id: sessionIdFromRedux,
  //     }),
  //   },
  //   props,
  // });

  // /////////////////////////////////////////////////////////////////////////////

  // useEffect(() => {
  //   setdrawerOpenClose(JSON.parse(localStorage.getItem('drawerOpenClose')))
  // }, [drawerOpenClose]);

  // useEffect(() => {
  //   setflag(true)
  //  if(deviceTokenFromRedux !== undefined ){
  //            setDeviceToken(deviceTokenFromRedux)
  //           // setflag(false)
  //         }
  //         if(sessionIdFromRedux !== undefined ){
  //            setSessionId(sessionIdFromRedux)
  //           // setflag(true)
  //         }
  // readItemFromStorage();
  // if (DeviceToken && SessionId) {
  // const resp = checkSessionValidity;
  // }
  // console.log("infoFromRedux on search page useEffect",infoFromRedux)
  // console.log("deviceTokenFromRedux in search useeffect", deviceTokenFromRedux)
  // console.log(" SessionId in search for check call",sessionIdFromRedux)

  // let device_token = JSON.parse(localStorage.getItem('device_token'));
  // let session_id = JSON.parse(localStorage.getItem('session_id'));
  // console.log("device token n session id from localstorage on search useeffect",device_token,session_id)



  // readItemFromRedux();
  // if(DeviceToken !== '' && SessionId !== '' && DeviceToken !== null && SessionId !== null){
  // if( DeviceToken !== undefined && SessionId !== undefined ){
  // console.log("here in search useeffect if condition",DeviceToken,SessionId);
  // const resp =  checkSessionValidity
  // const requestOptions = {
  //   method: 'POST',
  //   headers: authHeader(),
  //   // body: JSON.stringify({'username': username})
  //   body: JSON.stringify({'device_token': "db04433b-c8e6-4d6a-a821-4d5c48677b8a", 'session_id' : "078103b8-0da7-4c05-b9b0-7f622bced3a7"})
  // };
  // console.log("requestOptions in search",requestOptions)
  // return fetch( process.env.REACT_APP_CHECK_SESSION_VALIDITY , requestOptions)
  // .then((Response) => Response.json())
  // .then( async(checkSession) => {
  //       console.log("checkSession in check session func on search==========",checkSession.success,checkSession);
  //       // console.log("checkSession in check session func .data.session_info.is_active==========",checkSession.data.session_info.is_active);
  //       if(checkSession.success === true){
  //           console.log("in checkSession.success === true");
  //           if(checkSession.data.session_info.is_active == true && checkSession.data.session_info.session_id_refreshed == false ){
  //               console.log("as it is session==1=1=1=1=");
  //           }
  //           if(checkSession.data.session_info.is_active == true && checkSession.data.session_info.session_id_refreshed == true ){
  //               console.log("in checkSession.data.session_info.is_active === true && checkSession.data.session_info.session_id_refreshed === true");
  //               await localStorage.setItem('device_token', JSON.stringify(checkSession.data.session_info.device_token));
  //               await localStorage.setItem('session_id', JSON.stringify(checkSession.data.session_info.session_id));
  //               }
  //           else if(checkSession.data.session_info.is_active === false){
  //               console.log("checkSession.data.session_info.is_active === false");
  //               // dispatch(userActions.logout());
  //           }
  //       }
  //       else if(checkSession.success === false){
  //           console.log("checkSession checkSession.success === false",checkSession);
  //           // dispatch(userActions.logout());
  //       }

  //   })
  //  }
  // }, []);

  // const readItemFromStorage = async() => {
  //       try {
  //           // let device_token = JSON.parse(localStorage.getItem('device_token'));
  //           // let session_id = JSON.parse(localStorage.getItem('session_id'));
  //           // console.log("here in search====>device_token,session_id",device_token,session_id);
  //           // if(deviceTokenFromRedux !== undefined ){
  //           //   await setDeviceToken(deviceTokenFromRedux)
  //           //   // setflag(false)
  //           // }
  //           // if(sessionIdFromRedux !== undefined ){
  //           //   await setSessionId(sessionIdFromRedux)
  //           //   // setflag(true)
  //           // }
  //            setDeviceToken(deviceTokenFromRedux)
  //            setSessionId(sessionIdFromRedux)
  //           setflag(false)
  //           // callCjeckSession()
  //           // const resp =  checkSessionValidity

  //       } catch (error) {
  //           console.log("ERROR:",error);
  //       }
  //     };

  // const callCjeckSession = () => {
  //   dispatch(userActions.checkSessionValidity(deviceTokenFromRedux,sessionIdFromRedux));
  // }

  const handleRecentCaseChange = () => {
    setChecked((prev) => !prev);
    setRecentCaseChecked((prev) => !prev);
  };

  const handleRecentClientChange = () => {
    setChecked((prev) => !prev);
    setRecentClientChecked((prev) => !prev);
  };

  let logedIn_users_businessId = JSON.parse(localStorage.getItem('logedIn_users_businessId'));
  let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));
  let users = JSON.parse(localStorage.getItem('users'));
  let device_token_local = JSON.parse(localStorage.getItem('device_token'));
  let session_id_local = JSON.parse(localStorage.getItem('session_id'));
  let business_id_local = JSON.parse(localStorage.getItem('logedIn_users_businessId'));

  React.useEffect(() => {
    // let device_token = JSON.parse(localStorage.getItem('device_token'));
    // let session_id = JSON.parse(localStorage.getItem('session_id'));
    // // if (device_token){
    //   dispatch(userActions.checkSessionValidity(device_token, session_id));
    // // }
    getInfo()
    localStorage.removeItem('view_clients_active_cases');
    // localStorage.setItem('pageNumberOfTableCasesTableTrpAdmin', 0);
    // localStorage.setItem('rowsPerPageOfTableCasesTableTrpAdmin', 5);

    // checkTermsAndConditionAcceted()
    setflag(false)

 
      fetch('https://geolocation-db.com/json/')
        .then(response => response.json())
        .then(data => {
          console.log("device details in useeffect of serach",data,data.IPv4)
          // setIPAddress(data.IPv4)
          // setCountry(data.country_name)
          // setLatitude(data.latitude)
          // setLongitude(data.longitude)
        })
        .catch(error => console.log(error))
    
    //////////////////////////////////////////////////////////////
    // const fetchIpAddress = () => {
    //   const handleSuccess = (position) => {
    //     const { coords } = position;
    //     const { latitude, longitude } = coords;

    //     // Use a geolocation-based IP lookup service (e.g., ip-api.com)
    //     fetch(`https://ip-api.com/json?lat=${latitude}&lon=${longitude}`)
    //       .then(response => response.json())
    //       .then(data => setIpAddress(data.query))
    //       .catch(error => console.error('Error fetching IP address:', error));
    //   };

    //   const handleError = (error) => {
    //     console.error('Error getting geolocation:', error);
    //     setIpAddress('Could not fetch IP address');
    //   };

    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    //   } else {
    //     console.error('Geolocation is not supported by this browser.');
    //     setIpAddress('Geolocation not supported');
    //   }
    // };

    // fetchIpAddress();
    //////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////
    // const fetchIpAddress = () => {
    //   const RTCPeerConnection = window.RTCPeerConnection || window.webkitRTCPeerConnection; 
    //   const pc = new RTCPeerConnection({
    //     iceServers: [] // No STUN/TURN servers needed
    //   });

    //   pc.createDataChannel(''); // Create a data channel
    //   pc.createOffer().then(offer => pc.setLocalDescription(offer))
    //     .then(() => {
    //       // Trick to get our public IP
    //       pc.onicecandidate = (ice) => {
    //         if (ice.candidate) {
    //           console.log("ice.candidate",ice.candidate.candidate)
    //           const ipRegex = /([0-255]\.[0-255]\.[0-255]\.[0-255])/g;
    //           const ipAddress = ice.candidate.candidate.match(ipRegex)[0];
    //           console.log("ipAddress is",ipAddress)
    //           // setIpAddress(ipAddress);
    //         }
    //       };
    //     });
    // };

    // fetchIpAddress();
    //////////////////////////////////////////////////////////////


    

  }, [flag,user_name_redux, user_business_redux, user_deatils_redux, userInfoFromReduxLogDetails, drawerOpenClose,
    device_token, session_id, business_id]);

  const getInfo = async () => {
    setdrawerOpenClose(JSON.parse(localStorage.getItem('drawerOpenClose')))
    if (userInfoFromReduxLogDetails !== undefined) {
      await setuser_deatils_redux(userInfoFromReduxLogDetails);
      await setuser_name_redux(userInfoFromReduxLogDetails && userInfoFromReduxLogDetails.name);

      if (userInfoFromReduxLogDetails.business_link !== null) {
        await setuser_business_redux(userInfoFromReduxLogDetails && userInfoFromReduxLogDetails.business_link.business_name)
      }
    }
    setdevice_token(deviceTokenFromRedux)
    setsession_id(sessionIdFromRedux)
    setbusiness_id(businessIdFromRedux)

    console.log("in getInfo normal", device_token, session_id, business_id)
    console.log("in getInfo redux",
      businessIdFromRedux, deviceTokenFromRedux, sessionIdFromRedux)
    console.log("in getinfo device_token_local,session_id_local,business_id_local",
      device_token_local, session_id_local, business_id_local)
    console.log("role= role_of_user in useeffect of search", role_of_user)
    if (role_of_user === "business-user" || role_of_user === "client" ) {
      checkTermsAndConditionAcceted()
    }
    else{
      setTermsAndConditionAccepted(true)
    }

  }

  // const changeFlag = () => {
  //   setflag(false);
  // }
  const themeStyles = {
    // backgroundColor: darkTheme ? {marginLeft: '10%'} : '',
    // color: darkTheme ? '#CCC' : '#333',
    marginLeft: darkTheme ? '20%' : '0%',
    marginTop: darkTheme ? '10%' : '0%',
    // padding: '2rem',
    // margin: '2rem',
  }

  // t and c func

  const checkTermsAndConditionAcceted = () => {
    // console.log("in checkTermsAndConditionAcceted normal", device_token, session_id, business_id)
    // console.log("in checkTermsAndConditionAcceted redux",
    //   businessIdFromRedux, deviceTokenFromRedux, sessionIdFromRedux)

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'business_id': business_id_local,
        'device_token': device_token_local, 'session_id': session_id_local,
      })
    };
    return fetch(process.env.REACT_APP_GET_TERMS_STATUS_CHECK, requestOptions)
      .then((Response) => Response.json())
      .then(TermsStatusCheck => {
        if (TermsStatusCheck.success === true) {
          console.log("accepted t and c ON search", TermsStatusCheck.accepted)
          if (TermsStatusCheck.accepted === false) {
            console.log("data in TermsStatusCheck.accepted === false",TermsStatusCheck.data.terms_info)
            setTermsAndConditionAccepted(false);
            setTandCdata(TermsStatusCheck.data.terms_info)
            handleOpenTermsAndCondition();

          }
          else if (TermsStatusCheck.accepted === true) {
            setTermsAndConditionAccepted(true);
          }

        }
        else if (TermsStatusCheck.success === false) {
          setTermsAndConditionAccepted(false);
          // handleCloseTermsAndCondition();
        }
      })
    // .catch(err => {
    //   // setTermsAndConditionAccepted(false);
    //   handleCloseTermsAndCondition();
    //   Swal.fire({
    //     icon: 'error',
    //     text: "Something went wrong",
    //     confirmButtonColor: '#d33',
    //     confirmButtonText: 'OK'
    //   })
    // })
  }

  const handleOpenTermsAndCondition = () => {
    console.log("in handleOpenTermsAndCondition")
    setOpenTermsAndCondition(true)
    // getTandC();
  }

  // const getTandC = () => {

  //     console.log("in getTandC of wrapper normal", device_token, session_id, business_id)
  //     console.log("in getTandC of wrapper redux",
  //       businessIdFromRedux, deviceTokenFromRedux, sessionIdFromRedux)

  //     const requestOptions = {
  //       method: 'POST',
  //       headers: authHeader(),
  //       body: JSON.stringify({
  //         // 'business_id': businessIdFromRedux,
  //         "terms_id": 1,
  //         'device_token': deviceTokenFromRedux, 'session_id': sessionIdFromRedux,
  //       })
  //     };
  //     return fetch(process.env.REACT_APP_GET_SINGLE_TERMS, requestOptions)
  //       .then((Response) => Response.json())
  //       .then(AcceptenceTandC => {
  //         if (AcceptenceTandC.success === true) {
  //           console.log("accepted t and c ON WRAPPER", AcceptenceTandC.data.business_info)

  //           setTandCdata(AcceptenceTandC.data.business_info);
  //         }
  //         else if (AcceptenceTandC.success === false) {
  //           setTandCdata();
  //         }
  //       })
  //       // .catch(err => {
  //       //   Swal.fire({
  //       //     icon: 'error',
  //       //     text: "Something went wrong",
  //       //     confirmButtonColor: '#d33',
  //       //     confirmButtonText: 'OK'
  //       //   })
  //       // })
  //   }

  const handleCloseTermsAndCondition = () => {
    setOpenTermsAndCondition(false);
    setTandCdata();
    dispatch(userActions.logout());
  }

  const handleAcceptTermsAndCondition = async() => {
    // console.log("in handleAcceptTermsAndCondition of wrapper normal", device_token, session_id, business_id)
    // console.log("in handleAcceptTermsAndCondition of wrapper redux",
    //   businessIdFromRedux, deviceTokenFromRedux, sessionIdFromRedux)
    setShowProcedure(true)

    

    const response =await fetch("https://api.ipify.org?format=json");
    const dataIP = await response.json();
    console.log("dataIP is in t and c",dataIP.ip)

    let device_token_local = JSON.parse(localStorage.getItem('device_token'));
    let session_id_local = JSON.parse(localStorage.getItem('session_id'));
    let business_id_local = JSON.parse(localStorage.getItem('logedIn_users_businessId'));
    console.log("in handleAcceptTermsAndCondition device_token_local,session_id_local,business_id_local",
      device_token_local, session_id_local, business_id_local)

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        "terms_id": TandCdata.terms_id, "accepted_state": true,
        "country": "India", 
        "ip_address": dataIP.ip,
        'business_id': business_id_local, 'device_token': device_token_local, 'session_id': session_id_local,
      })
    };
    return fetch(process.env.REACT_APP_CREATE_ACCEPTANCE_TERMS, requestOptions)
    .then((Response) => Response.json())
    .then( async(tandcAcceptDetails) => {
      setShowProcedure(false)
      console.log("tandcAcceptDetails response",tandcAcceptDetails);
        if(tandcAcceptDetails.success == true)
        {
          setTermsAndConditionAccepted(true);
          setOpenTermsAndCondition(false);
          setTandCdata();
          setflag(true);
          Swal.fire({
            icon: 'success',
            text: "Accepted Terms and Conditions",
            confirmButtonColor: 'primary',
            confirmButtonText: 'OK'
          })
        }
        else if(tandcAcceptDetails.success == false)
        {
          setTermsAndConditionAccepted(false);
          Swal.fire({
            icon: 'error',
            text: tandcAcceptDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })  
        }

    })
    .catch(err => {
      setShowProcedure(false)
      Swal.fire({
        icon: 'error',
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });
  }

  const bodyTermsAndCondition = (
    console.log("t and c data in bodyTermsAndCondition is", TandCdata),
    <div className={classes.paperModalTandC}>
      {/* <div className={classes.TandCHeaderBackgroud} style={{ justifyContent: 'center', }}> */}
      <div className={classes.TandCHeaderBackgroud}>
        <Grid container style={{ justifyContent: 'center', }}>
          <h2> Terms and Conditions </h2>
        </Grid>
      </div>

      <div className={classes.modalBodyTandC}>
        <Grid item xs={12}>
          {
            // TandCdata && TandCdata.map( (item, index) => (
            <Grid>
              <div style={{ maxWidth: '100%', wordBreak: 'break-all' }}>
                {TandCdata && TandCdata.text}
              </div>
            </Grid>
            // ))
          }
        </Grid>

      </div>

      <div className={classes.TandCModalFooter}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleAcceptTermsAndCondition}
        >
          Accept
        </Button>
        <div style={{ marginLeft: '2%' }}></div>
        <Button
          type="submit"
          variant="contained"
          className={classes.submit}
          onClick={handleCloseTermsAndCondition}
        >
          Reject
        </Button>
      </div>
    </div>
  )

  if (userInfoFromReduxLogDetails) {
    console.log("ok got userInfoFromReduxLogDetails", userInfoFromReduxLogDetails)

    // system admin
    // if (role_of_user == "sysadmin") {
    //   return(
    //     <div className={classes.root}>
    //       <div className={classes.wrapper}>
    //         <Grid >
    //           <Grid container item xs={12} className={classes.businessHeading}>
    //             Terms And Conditions :
    //           </Grid>
    //           <TermsAndConditionSystemAdmin />
    //         </Grid>
    //       </div>
    //     </div>
    //   )
    // }

    // client
    if (role_of_user == "client") {
      if( TermsAndConditionAccepted === true ){
        return (
          <div
            className={
              !searchChecked ? classes.casesdisplay : classes.casesdisplayNone
            }
          >
            <Slide
              direction="right"
              in={!searchChecked}
              mountOnEnter
              unmountOnExit
            >
              <Grid container className={classes.pageHeaderContainer}>
                <Grid item xs={12} className={classes.pageHeaderLabelClient}>
                  Welcome !! &nbsp; {user_name_redux && user_name_redux}
                  {/* {user_deatils_async && user_deatils_async.data.user_details.name} */}
                  {/* {userInfoFromRedux.items.data.user_details.name} */}
                </Grid>
              </Grid>
            </Slide>
  
            {/* here switch business */}
            <div className={classes.paperWrapper}>
              <Paper className={classes.paper}>
                <Grid container>
                  <Grid item xs={12} className={classes.businessHeading}>
                    Current Business Details
                  </Grid>
                  {/* <hr style={{width: "950%", marginTop:"2%",}}/> */}
                  <Grid container style={{ marginTop: "4%", }}>
                    <Grid item xs={12} className={classes.activeBusinessLabel}>
                      Active Business : {user_business_redux && user_business_redux}
                    </Grid>
                  </Grid>
  
                  {/* <Grid container style={{marginTop:"4%",}}>
                          <Grid container item xs={12} className={classes.activeBusinessLabel} style={{cursor:"pointer"}} >
                            <Grid item style={{marginLeft: "39%"}}>
                              <BusinessIcon className={classes.IconColor} />
                            </Grid>
                            <Grid item style={{marginLeft: "2%"}}>
                              Switch Business
                            </Grid>
                          </Grid>
                        </Grid> */}
  
                </Grid>
              </Paper>
  
              <Grid style={{ marginTop: '5%' }} className={classes.pageHeaderLabel}>
                <Grid className={classes.pageHeaderLabel}>
                  Charts:
                </Grid>
                <Grid item xs={12} style={{ marginTop: '1%' }}>
                  <ChartsFile />
                </Grid>
  
              </Grid>
            </div>
          </div>
        )
      }
      else if (TermsAndConditionAccepted === false) {
        return (
          console.log(" aCCEPT TermsAndConditionAccepted ", TermsAndConditionAccepted),
          <div className={classes.root}>
            {/* <Grid>Show tandc</Grid> */}
            <Modal
              open={OpenTermsAndCondition}
              onClose={handleCloseTermsAndCondition}
              className={classes.modal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {bodyTermsAndCondition}
            </Modal>
          </div>
        )
      }
      
    }

    // trpadmin
    // if ( role_of_user == "trpadmin" && userInfoFromReduxLogDetails && userInfoFromReduxLogDetails.is_active == false){
    // if ( role_of_user == "trpadmin" && userInfoFromReduxLogDetails && userInfoFromReduxLogDetails.business_is_active == false){
    // if (role_of_user == "trpadmin" && userInfoFromReduxLogDetails && userInfoFromReduxLogDetails.business.business_is_active == false) {

    if (role_of_user == "business-user" && userInfoFromReduxLogDetails && userInfoFromReduxLogDetails.business.business_is_active == false) {
      return (
        console.log("userInfoFromReduxLogDetails", userInfoFromReduxLogDetails),
        <div className={classes.root}>
          <div className={classes.wrapper}>
            <div className={classes.paperWrapper} style={{ marginTop: '10%' }}>
              <Paper className={classes.paper}>
                <Grid container className={classes.businessHeading}>
                  Business account is inactive. Please contact your administrator.
                </Grid>
              </Paper>
            </div>

          </div>
        </div>

      )
    }

    // otherwise
    else {
      // add condition of t and c here in if and then in return show the ui
      if (TermsAndConditionAccepted === true) {
        return (
          // console.log("logedIn_users_businessId on search",logedIn_users_businessId),
          // console.log("infoFromRedux on search page return",infoFromRedux),
          // console.log("deviceTokenFromRedux,businessIdFromRedux in search return", deviceTokenFromRedux,businessIdFromRedux),
          // console.log("role_of_user on search",role_of_user),
          console.log(" TermsAndConditionAccepted ", TermsAndConditionAccepted),
          console.log("userInfoFromReduxLogDetails", userInfoFromReduxLogDetails),
          console.log("user_deatils_redux,user_name_redux,user_business_redux ", user_deatils_redux, user_name_redux, user_business_redux),
          console.log("drawerOpenClose in search page", drawerOpenClose),

          <div className={classes.root}>
            <div className={classes.wrapper}>
              <div
                className={
                  !searchChecked ? classes.casesdisplay : classes.casesdisplayNone
                }
              >
                {/* <h1>Your IP Address:</h1>
                <p>{ipAddress}</p> */}

                {/* Search from here */}
                <Grid container className={classes.pageHeaderContainer}>
                  <Grid item xs={12} className={classes.pageHeaderLabel}>
                    <FormattedMessage id="searchLink" />
                  </Grid>
                </Grid>

                <Grid container style={{ marginTop: '-3%' }}>
                  <Grid item xs={12}>
                    <SearchView />
                  </Grid>
                </Grid>
                {/* Search to here */}

                {/* </Slide> */}

                {/* Users Login Info */}
                <Grid style={{ marginTop: '3%' }} className={classes.pageHeaderLabel}>
                  {
                    (role_of_user !== "sysadmin" && role_of_user !== "trpdoc") ?
                      (
                        // <Grid className={classes.pageHeaderLabel}>
                        //   User's Login Info:
                        // </Grid>
                        <Grid item xs={12} style={{ marginTop: '1%' }}>
                          <UsersLoginInfo />
                        </Grid>
                      ) :
                      (<Grid></Grid>)
                  }
                  {/* <Grid item xs={12} style={{ marginTop: '1%' }}>
                    <UsersLoginInfo />
                  </Grid> */}

                </Grid>


                {/* Charts */}
                <Grid style={{ marginTop: '2%' }} className={classes.pageHeaderLabel}>
                  {
                    (role_of_user !== "sysadmin" && role_of_user !== "trpdoc") ?
                      (
                        <Grid className={classes.pageHeaderLabel}>
                          Charts
                          {/* {process.env.REACT_APP_API_TRP_FLOW_COM_WORKFLOW}
                                {process.env.NODE_ENV} */}
                        </Grid>
                      ) :
                      (<Grid></Grid>)
                  }
                  <Grid item xs={12} style={{ marginTop: '1%' }}>
                    <ChartsFile />
                    {/* <Chart
                              width={1000}
                              height={400}
                              chartType="ColumnChart"
                              loader={<div>Loading Chart</div>}
                              data={[
                                ['No', 'Title'],
                                ['CREATED', CaseStatCreatedCount],
                                ['DRAFT', CaseStatDraftCount],
                                ['CONFIRMED', CaseStatConfirmedCount],
                                ['INITIATED', CaseStatInitittedCount],
                              ]}
                              options={{
                                title: 'Cases count on current states',
                                chartArea: { width: '30%' },
                                hAxis: {
                                  title: 'Case Stat',
                                  minValue: 0,
                                },
                                vAxis: {
                                  title: 'Count',
                                },
                              }}
                              legendToggle
                            /> */}
                  </Grid>
                </Grid>
                {/* Charts */}


              </div>
            </div>
          </div>
        );
      }
      else if (TermsAndConditionAccepted === false) {
        return (
          console.log(" TermsAndConditionAccepted ", TermsAndConditionAccepted),
          <div className={classes.root}>
            {/* <Grid>Show tandc</Grid> */}
            <Modal
              open={OpenTermsAndCondition}
              onClose={handleCloseTermsAndCondition}
              className={classes.modal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {bodyTermsAndCondition}
            </Modal>
          </div>
        )
      }

    }
  }
  else {
    return (
      console.log("userInfoFromReduxLogDetails", userInfoFromReduxLogDetails),
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <div className={classes.paperWrapper} style={{ marginTop: '10%' }}>
            {/* <Paper className={classes.paper}>
                  <Grid container className={classes.businessHeading}>
                    Loading....
                  </Grid>
                </Paper> */}
            {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
              <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
            </modal> : null}
          </div>

        </div>
      </div>

    )
  }

}