import React, { useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import TaxManagementRecentCases from "../TaxManagementRecentCases";
import SearchView from "../SearchView";
import { FormattedMessage } from "react-intl";
import { userActions } from "../actions";
import Grid from "@material-ui/core/Grid";
import TaxManagementCaseDetails from "../TaxManagementCaseDetails";
import TaxManagementRecentClient from "../TaxManagementRecentClient";
import TaxManagementClientDetails from "../TaxManagementClientDetails";
import useCheckSessionApi from "../SessionValidity";
import { authHeader } from '../helpers';
import { useSelector, useDispatch } from 'react-redux';
import Paper from "@material-ui/core/Paper";
import { set } from "lodash";
import BusinessIcon from "@material-ui/icons/Business";
import { PropagateLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import { modal } from 'reactstrap';

import { useThemeContext, useThemeUpdate } from '../ThemeContext'

import Chart from "react-google-charts";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      marginTop: '5%',
      // overflowY: 'scroll',
    },
    root2: {
      flexGrow: 1,
      marginTop: '50%',
    },
    wrapper: {
      margin: "10%",
      marginRight: "5%",
      marginTop: "7%",
      marginBottom: "3%",
    },
    quickAccessLabel: {
      fontFamily: [
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "20px",
      color: "rgba(0, 0, 0, 0.64)",
      textAlign: "left",
    },
    recentCaseLabelContainer: {
      marginTop: "5%",
      marginBottom: "1%",
    },
    pageHeaderLabel: {
      fontFamily: [
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "20px",
      lineHeight: "20px",
      color: "rgba(0, 0, 0, 0.64)",
      textAlign: "left",
    },
    pageHeaderContainer: {
      marginBottom: "5%",
    },
    casesdisplay: {
      display: "block",
    },
    casesdisplayNone: {
      display: "none",
    },
    pageHeaderLabelClient: {
      fontFamily: [
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "25px",
      lineHeight: "20px",
      color: "rgba(0, 0, 0, 0.64)",
      textAlign: "left",
      marginTop: "2%",
    },
    paperWrapper: {
      margin: "10%",
      marginRight: "10%",
      marginTop: "8%",
      marginBottom: "3%",
    },
    paper: {
      padding: theme.spacing(2),
      height: '100%',
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    businessHeading: {
      // display: 'flex',
      // justifyContent: 'center',
      // marginTop: '2%',
      fontFamily: [
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "23px",
      color: 'black'
    },
    activeBusinessLabel: {
      fontFamily: [
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "20px",
      color: "black",
      // textAlign: "left",
    },
    IconColor: {
      color: 'blue',
      size: '10'
    },
    showProcedureStyle: {
      position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
    },
  
  }));

  export default function ChartsFile(props) {
    const darkTheme = useThemeContext()
    const classes = useStyles();
    const [ ShowProcedure, setShowProcedure ] = React.useState(true);
    const [device_token, setdevice_token ] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id ] = React.useState(JSON.parse(localStorage.getItem('session_id')));
    const [business_id, setbusiness_id ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));

     // stat details
    const [CaseOwnerStatDetails, setCaseOwnerStatDetails] = React.useState([]);
    const [CaseOwnerStatDetailsArrayMy, setCaseOwnerStatDetailsArrayMy] = React.useState([]);

    // case_service_stats
    const [CaseServiceStatDetails, setCaseServiceStatDetails] = React.useState([]);
    const [CaseServiceStatDetailsArrayMy, setCaseServiceStatDetailsArrayMy] = React.useState([]);

    // case_stats details
    const [CaseStatDetails, setCaseStatDetails] = React.useState([]);
    const [CaseStatDetailsArrayMy, setCaseStatDetailsArrayMy] = React.useState([]);

    

    const dispatch = useDispatch();
    const infoFromRedux = useSelector(state => state);
    const userInfoFromRedux = useSelector(state => state.users);
    const userInfoFromReduxLogDetails = useSelector(state => state.loginDetails.logedInUsersDetails);
    const deviceTokenFromRedux = useSelector(state => state.loginDetails.deviceToken);
    const sessionIdFromRedux = useSelector(state => state.loginDetails.sessionId);
    const businessIdFromRedux = useSelector(state => state.loginDetails.businessId);

    let device_token_local = JSON.parse(localStorage.getItem('device_token'));
    let session_id_local = JSON.parse(localStorage.getItem('session_id'));
    let business_id_local = JSON.parse(localStorage.getItem('logedIn_users_businessId'));

    React.useEffect(() => {
      setShowProcedure(true)
      dispatch(userActions.checkSessionValidity());

      console.log("in getinfo device_token_local,session_id_local,business_id_local",
        device_token_local,session_id_local,business_id_local)
      getInfo()
    },[deviceTokenFromRedux, sessionIdFromRedux, device_token, session_id])

    const getInfo = async () => {

      setdevice_token(deviceTokenFromRedux)
      setsession_id(sessionIdFromRedux)
      setbusiness_id(businessIdFromRedux)

      if( device_token_local && session_id_local )
      {
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify({ 
            // 'device_token': deviceTokenFromRedux, 'session_id': sessionIdFromRedux 
            // 'device_token': device_token, 'session_id': session_id                      //after create_session change
            'business_id': business_id_local,
            'device_token': device_token_local, 'session_id': session_id_local
          })
        };
        fetch( process.env.REACT_APP_GET_CASE_STATS , requestOptions)
        .then((Response) => Response.json())
        .then( async(casesStatDetails) => {
          setShowProcedure(false)
          console.log("casesStatDetails response",casesStatDetails);
          if(casesStatDetails.success == true)
          {
            if(casesStatDetails.data.case_owner_stats){
              console.log("casesStatDetails.case_owner_stats",casesStatDetails.data.case_owner_stats)
              setCaseOwnerStatDetails(casesStatDetails.case_owner_stats);
              let arrayHere = [['Owner', 'Count']];
              casesStatDetails.data.case_owner_stats.map((item, index)=>{
                arrayHere.push([item.owner__first_name, item.total_count])
              })
              setCaseOwnerStatDetailsArrayMy(arrayHere)
              console.log("arrayHere setCaseOwnerStatDetailsArrayMy===",arrayHere,CaseOwnerStatDetailsArrayMy);
            }
            else{
              setCaseOwnerStatDetailsArrayMy([])
              setCaseOwnerStatDetails([])
            }
  
            if(casesStatDetails.data.case_service_stats){
              setCaseServiceStatDetails(casesStatDetails.data.case_service_stats)
              let arrayHere = [['Service', 'Count']];
              casesStatDetails.data.case_service_stats.map((item, index)=>{
                arrayHere.push([item.process__service__name, item.total_count])
              })
              setCaseServiceStatDetailsArrayMy(arrayHere)
              console.log("arrayHere=== setCaseServiceStatDetailsArrayMy",arrayHere,CaseServiceStatDetailsArrayMy);
            }
            else{
              setCaseServiceStatDetailsArrayMy([])
              setCaseServiceStatDetails([])
            }
  
            if(casesStatDetails.data.case_stats){
              setCaseStatDetails(casesStatDetails.data.case_stats)
              let arrayHere = [['Status', 'Count']];
              casesStatDetails.data.case_stats.map((item, index)=>{
                arrayHere.push([item.case_curr_state, item.total_count])
              })
              // casesStatDetails.data.case_stats.map((item, index)=>{
              //   arrayHere.push(["INITIATED", 0])
              // })
              setCaseStatDetailsArrayMy(arrayHere)
              console.log("arrayHere=== setCaseStatDetailsArrayMy",arrayHere,CaseStatDetailsArrayMy);
            }
            else{
              setCaseStatDetailsArrayMy([])
              setCaseStatDetails([])
            }
          }
          else if(casesStatDetails.success == false){
            Swal.fire({
              icon: 'error',
              text: "Stat Details Not found",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
            setCaseOwnerStatDetailsArrayMy([])
              setCaseOwnerStatDetails([])
              setCaseServiceStatDetailsArrayMy([])
              setCaseServiceStatDetails([])
              setCaseStatDetailsArrayMy([])
              setCaseStatDetails([])
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
      }
    }

    let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));

    

    return(
      // console.log("CaseOwnerStatDetailsArrayMy in return",CaseOwnerStatDetails, CaseOwnerStatDetailsArrayMy.length),
      // console.log("CaseStatDetailsArrayMy in return",CaseStatDetailsArrayMy, CaseStatDetailsArrayMy.length),
      // console.log("CaseServiceStatDetailsArrayMy in return",CaseServiceStatDetailsArrayMy, CaseServiceStatDetailsArrayMy.length),
      <div>
        {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
          <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
        </modal>: null}
        <div>
          <Grid>
          {
            ( ShowProcedure == false )?
            (
              <Grid>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    {
                      ( role_of_user !== "sysadmin" && role_of_user !== "trpdoc" )?
                      (
                        <Grid>
                          {
                            ( CaseStatDetailsArrayMy && CaseStatDetailsArrayMy.length == "1" || CaseStatDetailsArrayMy.length == undefined )?
                            (
                              <Grid></Grid>
                            ):
                            (
                              <Chart
                                width={585}
                                height={300}
                                chartType="ColumnChart"
                                loader={<div>Loading Chart 1</div>}
                                data={CaseStatDetailsArrayMy}
                                options={{
                                  title: 'Count based on current states of cases',
                                  chartArea: { width: '30%' },
                                  hAxis: {
                                    title: 'Case State',
                                    minValue: 0,
                                    // viewWindowMode:'explicit',
                                    viewWindow: {
                                      min: 0,
                                      // max: 1
                                      // max: 100
                                    },
                                  },
                                  vAxis: {
                                    viewWindow:{ 
                                      min: 0,
                                      // max: 1 
                                    },
                                    title: 'Number of Cases',
                                    minValue: 0,
                                    format: '0'    ,                                  //for not allowing .int values
                                    // maxValue: 100,
                                      // ticks: 0-5
                                      // ticks: [0, 1],
                                    // ticks: [0, 5, 10, 15, 20 ] ,
                                    // ticks: [ -10, -5, 0, 5, 10 ] 
                                    // viewWindowMode:'explicit',
                                    
                                  },
                                }}
                                
                                legendToggle
                              />
                            )
                          }
                        </Grid>
                      ):
                      (<Grid></Grid>)
                    }
                  </Grid>
                  <Grid item xs={6}>
                    {
                      ( role_of_user !== "client" && role_of_user !== "sysadmin" && role_of_user !== "trpdoc" )?
                      (
                        <Grid>
                          {
                            ( CaseServiceStatDetailsArrayMy && CaseServiceStatDetailsArrayMy.length == "1" || CaseServiceStatDetailsArrayMy.length == undefined )?
                            (
                              <Grid></Grid>
                            ):
                            (
                              <Chart
                                width={585}
                                height={300}
                                chartType="ColumnChart"
                                loader={<div>Loading Chart 2</div>}
                                data={CaseServiceStatDetailsArrayMy}
                                // data={[
                                //   // ['Title', 'Count'],
                                //   // CaseServiceStatDetailsArrayMy,
                                // ]}
                                options={{
                                  title: 'Count based on services',
                                  chartArea: { width: '30%' },
                                  hAxis: {
                                    title: 'Case Service',
                                    // minValue: 0,
                                    viewWindow: {
                                      min: 0,
                                      // max: 100
                                    },
                                  },
                                  vAxis: {
                                    title: 'Number of Cases',
                                    minValue: 0,
                                    format: '0'
                                  },
                                }}
                                legendToggle
                              />
                            )
                          }
                        </Grid>
                      ):
                      (<Grid></Grid>)
                    }
                  
                  </Grid>
                </Grid>     

                <Grid container item xs={12} style={{marginTop:'2%'}}>
                  <Grid item xs={12}>
                    {
                      ( role_of_user !== "client"  && role_of_user !== "sysadmin" 
                        && role_of_user !== "trp" && role_of_user !== "trpdoc")?
                      (
                        <Grid>
                          {
                            ( CaseOwnerStatDetailsArrayMy && CaseOwnerStatDetailsArrayMy.length == "1" || CaseOwnerStatDetailsArrayMy.length == undefined )?
                            (
                              <Grid></Grid>
                            ):
                            (
                              <Chart
                                width={'99%'}
                                height={400}
                                chartType="ColumnChart"
                                loader={<div>Loading Chart 3</div>}
                                data={CaseOwnerStatDetailsArrayMy}
                                // data={[
                                //   ['No', 'Title'],
                                //   ['CREATED', CaseStatCreatedCount],
                                //   ['DRAFT', CaseStatDraftCount],
                                //   ['CONFIRMED', CaseStatConfirmedCount],
                                //   ['INITIATED', CaseStatInitittedCount],
                                // ]}
                                options={{
                                  title: 'Number of Cases Assign to Owner / User',
                                  chartArea: { width: '30%' },
                                  hAxis: {
                                    title: 'Owner / User Name ',
                                    // minValue: 0,
                                    viewWindow: {
                                      min: 0,
                                      // max: 100
                                    },
                                      // ticks: [0, 5, 10, 15, 20] 
                                  },
                                  vAxis: {
                                    title: 'Number of Cases',
                                    minValue: 0,
                                    format: '0'
                                  },
                                }}
                                legendToggle
                              />
                            )
                          }
                        </Grid>
                      ):
                      (<Grid></Grid>)
                    }
                    
                  </Grid>

                </Grid>
              </Grid>
            )
            :(<Grid></Grid>)
          }
          </Grid>
        </div>
       </div>
    )
  }