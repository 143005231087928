import React, { useEffect } from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link, Redirect} from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { authHeader } from '../helpers';
import { userActions } from "../actions";
import { useSelector , useDispatch} from 'react-redux';
import Swal from 'sweetalert2';
import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableSortLabel from '@material-ui/core/TableSortLabel';

import moment from 'moment';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(Feedbacks, comparator) {
  const stabilizedThis = Feedbacks && Feedbacks.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  { id: 'ticket_id', numeric: false, label: 'Id', },
  { id: 'subject', numeric: false, label: 'Subject', },
  { id: 'description', numeric: false, label: 'Feedback', },
  { id: 'severity', numeric: false, label: 'Severity', },
  // { id: 'ticket_priority', numeric: false, label: 'Priority', },
  { id: 'created', numeric: false, label: 'Added On', },
  { id: 'actions', numeric: false, label: 'Actions', },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          (headCell.id === "actions") ?
            (
              <TableCell
                key={headCell.id}
                align="center"
                style={{ fontWeight: 'bold', fontSize: 17 }}
              >
                {headCell.label}
              </TableCell>
            )
            : (
              <TableCell
                key={headCell.id}
                // align={headCell.numeric ? 'center' : 'center'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                align="center"
                style={{ fontWeight: 'bold', fontSize: 17}}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )


        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  pageHeaderContainer: {
    marginBottom: "5%",
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  feedbackDescription: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "15px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  CategoryLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
    // marginLeft: "2%",
    // marginTop: "1%",
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    textAlign: "left",
    width: '100%',
    color: theme.palette.text.secondary,
    marginTop: '-1%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  hover: {
    cursor: "pointer",
  },
}));

export default function ShowFeedback() {
  const classes = useStyles();
  const [ShowProcedure, setShowProcedure] = React.useState(false)
  const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('selected_business_id')));
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [Feedbacks, setFeedbacks] = React.useState([
    // {
    //   feedback_id: 1,
    //   feedback: "feedback 1 description",
    //   subject: "feedback 1 subject",
    //   priority: "Low",

    // },
    // {
    //   feedback_id: 2,
    //   feedback: "feedback 2 description",
    //   subject: "feedback 2 subject",
    //   priority: "High",

    // },
  ]);
  const [RedirectToFeedbackDetails, setRedirectToFeedbackDetails] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');

  const dispatch = useDispatch();

  React.useEffect(() => {
    setShowProcedure(true);
    dispatch(userActions.checkSessionValidity());

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_TICKETS , requestOptions)
      .then((Response) => Response.json())
      .then( async(FeedbackDetails) => {
        setShowProcedure(false);
        console.log("FeedbackDetails after api call in useeffect",FeedbackDetails);
        if(FeedbackDetails.success == true)
        {
          console.log("FeedbackDetails.data.tickets ",FeedbackDetails.data.tickets);
          setFeedbacks(FeedbackDetails.data.tickets)
        }
        else if(FeedbackDetails.success == false){
          setFeedbacks([]);
          Swal.fire({
            icon: 'error',
            text: FeedbackDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 9000,
          })
        }
    })
    .catch(err => {
      setShowProcedure(false);
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });

  }, []);

  // sort
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, Feedbacks && Feedbacks.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    let valueHere = parseInt(event.target.value, 10)
    let valueToCompare = JSON.stringify(valueHere)
    if( valueToCompare === "null" ){
      setRowsPerPage(0);
    }
    else{
      setRowsPerPage(parseInt(event.target.value, 10));
    }
    setPage(0);
  };

  const handleRowClickOfTable = async(item) => {
    console.log("item in row click",item)
    setRedirectToFeedbackDetails(true);
    await localStorage.setItem('selected_Feedback_Details', JSON.stringify(item));
  }

  const handleFeedbackDetailsClick = async(item) => {
    console.log("item in handleFeedbackDetailsClick",item);
    setRedirectToFeedbackDetails(true);
    await localStorage.setItem('selected_Feedback_Details', JSON.stringify(item));
  }

  const createdDateFunc = (date, item) => {
    console.log("date in createdDateFunc is", date, item.name)
    let dateInString = JSON.stringify(date)

    console.log("date in straing is ", dateInString)


    var mydateIs = moment(dateInString, 'DD/MM/YYYY hh:mm A'); 
    console.log("formated date is ours",moment(mydateIs).format("MM/DD/YYYY hh:mm A"))
    var myDateWithFormatIs = moment(mydateIs).format("MM/DD/YYYY hh:mm A")
    console.log("myDateWithFormatIs",myDateWithFormatIs)
    console.log("date in format for  in table map is local", new Date(myDateWithFormatIs)) 

    var local_date= moment.utc(myDateWithFormatIs).local().format('MM/DD/YYYY hh:mm A');
    console.log("local_date using moments is",local_date)

    var local_date_DayFirst= moment.utc(myDateWithFormatIs).local().format('DD/MMM/YYYY hh:mm A');
    console.log("local_date day first",local_date_DayFirst)

    return(
      <div>
        <div>
          {local_date_DayFirst}
        </div>
      </div>  
    )
  }

  if( RedirectToFeedbackDetails === true ){
    return <Redirect to={`/FeedbackDetail`} />
  }
  else{
  return (
    <div className={classes.root}>
      {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
        <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
      </modal> : null}
      <div className={classes.wrapper}>

        <Grid container className={classes.pageHeaderContainer} style={{ marginTop: '2%' }}>
          <Grid item xs={10} className={classes.pageHeaderLabel}>
            Feedback List
          </Grid>
        </Grid>

        <TableContainer className={classes.tableWrapper} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={Feedbacks && Feedbacks.length}
            />
            <TableBody>
              {stableSort(Feedbacks && Feedbacks, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  // console.log("row in table map",item)
                  // if( role_of_user == "client" ){
                  return (
                    <TableRow
                      key={item.case_id}
                      hover={true} classes={{ hover: classes.hover }}
                      // hover
                      tabIndex={-1}
                      align="center"
                    >
                      <TableCell onClick={() => handleRowClickOfTable(item)} align="center">{item.ticket_id}</TableCell>
                      <TableCell onClick={() => handleRowClickOfTable(item)} align="center">{item.subject}</TableCell>
                      <TableCell onClick={() => handleRowClickOfTable(item)} align="center">{item.description}</TableCell>
                      <TableCell onClick={() => handleRowClickOfTable(item)} align="center">{item.severity}</TableCell>
                      {/* <TableCell onClick={() => handleRowClickOfTable(item)} align="center">{item.ticket_priority}</TableCell> */}
                      <TableCell onClick={() => handleRowClickOfTable(item)} align="center">{createdDateFunc(item.created, item)}</TableCell>
                      <TableCell align="center" >
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          // href="/FeedbackDetail"
                          onClick={() => handleFeedbackDetailsClick(item)}
                        >
                          Details
                        </Button>
                      </TableCell>
                          
                    </TableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: Feedbacks && Feedbacks.length }]}
                  colSpan={8}
                  count={Feedbacks && Feedbacks.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>


      </div>
    </div>
  )
  }
}