import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Steps, { Step } from "rc-steps";
import "rc-steps/assets/index.css";
import Grid from "@material-ui/core/Grid";
import AdjustIcon from "@material-ui/icons/Adjust";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { FormattedMessage } from "react-intl";
import CasedetailOverviewDescription from '../CasedetailOverviewDescription';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import GroupIcon from "@material-ui/icons/Group";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '25px',
    marginBottom: '25px'
  },
  stepContainer: {
    textAlign: "left",
    color: "#4D47DD",
  },
}));

export default function TaxManagementCasesOverView() {
  const classes = useStyles();
  const [processIdFromAsync, setprocessIdFromAsync] = React.useState(JSON.parse(localStorage.getItem('selected_process_id')));
  const [caseDetailsSteps, setcaseDetailsSteps] = React.useState([]);
  const [CurrentStepCount, setCurrentStepCount] = React.useState([]);

  let selected_process_id = JSON.parse(localStorage.getItem('selected_process_id'));

  React.useEffect(() => {
    // to fetch case details of steps
    let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
    let device_token = JSON.parse(localStorage.getItem('device_token'));
    let session_id = JSON.parse(localStorage.getItem('session_id'));
    let process_id =  processIdFromAsync
    console.log("process_id is business_id",process_id,business_id)
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'process_id':process_id, 
        'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
    };
    console.log("requestOptions",requestOptions)
    fetch( process.env.REACT_APP_GET_PROCESS_STEPS , requestOptions)
      .then((Response) => Response.json())
      .then( async(caseStepDetails) => {
        // setShowProcedure(false);
        console.log("caseStepDetails on show case details",caseStepDetails);
        if(caseStepDetails.success == true)
        {
          console.log("caseStepDetails on show case details true",caseStepDetails.data.steps_in_order);
          let case_steps = caseStepDetails.data.steps_in_order ;
          setcaseDetailsSteps(case_steps);
          let cureentStepCount = caseStepDetails.data.steps_in_order.length
          setCurrentStepCount(cureentStepCount)
        }
        else if(caseStepDetails.success == false){
          setcaseDetailsSteps([]);
          Swal.fire({
            icon: 'error',
            text: caseStepDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 9000,
          })
        }
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });

  }, []);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} className={classes.stepContainer}>
          <Steps direction="vertical" current={CurrentStepCount} status="error" size="small">
            { 
              caseDetailsSteps && caseDetailsSteps.map((i, index) =>(
                // console.log("item is",item)
                <Step
                  style={{marginTop:'2%'}}
                  title={
                    <div style={{ color: "#4D47DD" }}>
                      {i.process}
                    </div>
                  }
                  subTitle={
                    <div style={{ marginLeft: '-8px', marginTop: '5px', color: "#000000"}}
                      id="stepIdDiv"
                      key={i.step_id}
                      value={i.step_id}
                      // onClick={()=>StepClickedFunction(i.step_id, i)}
                    >
                      {i.name}
                    </div>
                  }
                  description={
                    // <CasedetailOverviewDescription />
                    <div className={classes.rootDescription}>
                    <Grid container>
                      {/* <Grid item xs={4}>
                        <CheckBoxIcon
                          fontSize="small"
                          style={{ marginRight:"5px", color: "rgba(0, 0, 0, 0.32);" }}
                        ></CheckBoxIcon>
                        <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block", verticalAlign: "top", fontSize: "14px" }} >0/12</div>
                      </Grid> */}
                      {/* <Grid item xs={4}>
                        <AccessTimeIcon
                          fontSize="small"
                          style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32);" }}
                        ></AccessTimeIcon>
                        <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block", verticalAlign: "top", fontSize: "14px" }} >No due date</div>
                      </Grid> */}
                      {/* <Grid item xs={4}>
                        <GroupIcon
                          fontSize="small"
                          style={{  marginRight:"5px", color: "rgba(0, 0, 0, 0.32);" }}
                        ></GroupIcon>
                        <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block", verticalAlign: "top", fontSize: "14px" }} >Unassigned</div>
                      </Grid> */}
                    </Grid>
                  </div>
                  }
                  icon={
                    <AdjustIcon fontSize="small" style={{ color: "#4D47DD" }} />
                  }
                />
              ))
            }
            

          </Steps>
        </Grid>
      </Grid>
      {/* <Grid container>
        <Grid item xs={12} className={classes.stepContainer}>
          <Steps direction="vertical" current={2} status="error" size="small">
            <Step
              title={<div style={{ color: "#4D47DD" }}><FormattedMessage id="notStartedLabel" /></div>}
              subTitle={<div style={{ marginLeft: '-8px', marginTop: '5px', color: "#000000" }}><FormattedMessage id="collectClientLabel" /></div>}
              description={
                <CasedetailOverviewDescription />}
              icon={
                <AdjustIcon fontSize="small" style={{ color: "#4D47DD" }} />
              }
            />
            <Step
              title={<div style={{ color: "rgba(0, 0, 0, 0.32);" }}><FormattedMessage id="notStartedLabel" /></div>}
              subTitle={<div style={{ marginLeft: '-8px', marginTop: '5px',color: "#000000" }}><FormattedMessage id="prepareITLabel" /></div>}
              description={
                <CasedetailOverviewDescription />}
              icon={
                <RadioButtonUncheckedIcon
                  fontSize="small"
                  style={{ color: "#DADADA" }}
                />
              }
            />
            <Step
              title={<div style={{ color: "rgba(0, 0, 0, 0.32);" }}><FormattedMessage id="notStartedLabel" /></div>}
              subTitle={<div style={{ marginLeft: '-8px', marginTop: '5px',color: "#000000" }}><FormattedMessage id="qualityControlLabel" /></div>}
              description={
                <CasedetailOverviewDescription />}
              icon={
                <RadioButtonUncheckedIcon
                  fontSize="small"
                  style={{ color: "#DADADA" }}
                />
              }
            />
            <Step
              title={<div style={{ color: "rgba(0, 0, 0, 0.32);" }}><FormattedMessage id="notStartedLabel" /></div>}
              subTitle={<div style={{ marginLeft: '-8px', marginTop: '5px',color: "#000000" }}><FormattedMessage id="fileITLabel" /></div>}
              description={
                <CasedetailOverviewDescription />}
              icon={
                <RadioButtonUncheckedIcon
                  fontSize="small"
                  style={{ color: "#DADADA" }}
                />
              }
            />
            <Step
              title={<div style={{ color: "rgba(0, 0, 0, 0.32);" }}><FormattedMessage id="notStartedLabel" /></div>}
              subTitle={<div style={{ marginLeft: '-8px', marginTop: '5px',color: "#000000" }}><FormattedMessage id="eVerifyLabel" /></div>}
              description={
                <CasedetailOverviewDescription />}
              icon={
                <RadioButtonUncheckedIcon
                  fontSize="small"
                  style={{ color: "#DADADA" }}
                />
              }
            />
            <Step
              title={<div style={{ color: "rgba(0, 0, 0, 0.32);" }}><FormattedMessage id="notStartedLabel" /></div>}
              subTitle={<div style={{ marginLeft: '-8px', marginTop: '5px',color: "#000000" }}><FormattedMessage id="finishedLabel" /></div>}
              description={
                <CasedetailOverviewDescription />}
              icon={
                <RadioButtonUncheckedIcon
                  fontSize="small"
                  style={{ color: "#DADADA" }}
                />
              }
            />
          </Steps>
        </Grid>
      </Grid> */}
    </div>
  );
}
