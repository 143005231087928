import React,{ useEffect } from "react";
import { Link , Route, Redirect} from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../../actions";
import useCheckSessionApi from "../../SessionValidity";
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';

import PersonIcon from "@material-ui/icons/Person";
import ReceiptIcon from "@material-ui/icons/Receipt";
import DomainIcon from "@material-ui/icons//Domain";

const useStyles = makeStyles((theme) => ({
  cursorPointer: {
    cursor: "pointer"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    marginRight:"5%",
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  moreVertIcon:{
    textAlign: "right",
  },
  personIconColor: {
      color: 'blue',
  },
  userAddUpdateDelete: {
      marginTop: '4%',
      fontSize: 11,
      color: 'black',
      cursor: "pointer",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    // height: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'15%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
  },
  modalBodyBulk: {
    padding: theme.spacing(7, 4, 3,), 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  paperModalBulk: {
    position: 'absolute',
    width: 500,
    // height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  validationBulk: {
    marginTop: '2%',
    color: 'red',
    fontSize: 12,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },

}));


// export default function StepStates ({stepListDropdown,stateListDropdown}) {
export default function StepStates (stepListDropdown) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [stepStateDetails, setstepStateDetails] = React.useState({
        step_id: "",
        state_id: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
    });
    const [stepIdErr, setstepIdErr] = React.useState();
    const [stateIdErr, setstateIdErr] = React.useState();

    const [openBulk, setopenBulk] = React.useState(false);
    const [ExcelFile, setExcelFile] = React.useState();
    const [ExcelName, setExcelName] = React.useState();
    const [fileErr, setfileErr] = React.useState();
    const [stateListDropdown, setstateListDropdown] = React.useState([]);

    const [openBulkSecond, setopenBulkSecond] = React.useState(false);
    const [ExcelFileSecond, setExcelFileSecond] = React.useState();
    const [ExcelNameSecond, setExcelNameSecond] = React.useState();
    const [fileErrSecond, setfileErrSecond] = React.useState();

    const [ ShowProcedure, setShowProcedure ] = React.useState(false);

    const [flag, setflag] = React.useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      setflag(true);
      // readItemFromStorage();
      // dispatch(userActions.checkSessionValidity());
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      let device_token = JSON.parse(localStorage.getItem('device_token'));
      let session_id = JSON.parse(localStorage.getItem('session_id'));

      const requestOptionsTwo = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'business_id':business_id,
        'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_ALL_STATES , requestOptionsTwo)
      .then((Response) => Response.json())
      .then(stepStateDetails => {
        console.log("state Details on same page",stepStateDetails.data.states);
        if(stepStateDetails.data.states)
        {
          setstateListDropdown(stepStateDetails.data.states)
        }
        else{
          setstateListDropdown([])
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    }, [flag, open, openBulk, openBulkSecond]);
    
    // const readItemFromStorage = async() => {
    //   try {
    //     setDeviceToken(deviceTokenFromRedux)
    //     setSessionId(sessionIdFromRedux)
    //     setflag(false)    
    //   } catch (error) {
    //     console.log("ERROR:",error);        }
    // };

    const handelCreateStepStateOpen = () => {
      setOpen(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateStepStateClose = () => {
      setOpen(false);
      setstepStateDetails({
        step_id: "",
        state_id: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      setstepIdErr();
      setstateIdErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateStepStateClear = () => {
      setstepStateDetails({
        step_id: "",
        state_id: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      setstepIdErr();
      setstateIdErr();
    }

    const handelCreateStepStateMinimize = () => {
      setOpen(false);
      dispatch(userActions.checkSessionValidity());
    }

    const validate = () => {
      let stepIdErr = '';
      let stateIdErr = '';

      if(!stepStateDetails.step_id)  {
          stepIdErr = 'Please Enter Step Id';
      }
      if(!stepStateDetails.state_id)  {
          stateIdErr = 'Please Enter State Id';
      }

      if(stepIdErr || stateIdErr ){
        setstepIdErr(stepIdErr);
        setstateIdErr(stateIdErr);

        return false;
      }

      return true;

    }

    const handelSubmitCreateStepState = () => {
      // console.log("stepStateDetails",stepStateDetails);
      dispatch(userActions.checkSessionValidity());
      const isValid = validate();
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      if(isValid){
        setShowProcedure(true);
        setOpen(false);
        const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({...stepStateDetails, business_id: business_id})
        };
        return fetch( process.env.REACT_APP_CREATE_STEP_STATE , requestOptions)
            .then((Response) => Response.json())
            .then(stepStateCreated => {
                setShowProcedure(false);
                console.log("stepStateCreated on same page",stepStateCreated.success);
                console.log("stepStateCreated on same page",stepStateCreated);
                if(stepStateCreated.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Step State created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    setstepStateDetails({
                      step_id: "",
                      state_id: "",
                      device_token: JSON.parse(localStorage.getItem('device_token')),
                      session_id: JSON.parse(localStorage.getItem('session_id')),
                    })
                    setstepIdErr();
                    setstateIdErr();
                  }
                  if(stepStateCreated.success == false || stepStateCreated.success == null){
                    Swal.fire({
                      icon: 'error',
                      text: stepStateCreated.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    .then(function (result) {
                      console.log("result of swal",result)
                      setOpen(true);
                    })
                  }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
    }

    const body = (
        <div className={classes.paperModal}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'40%'}}>Create Step State</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelCreateStepStateMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelCreateStepStateClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBody}>
            <FormControl className={classes.formControl}>
              {/* <InputLabel id="step_id">Step*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Step*</Grid>
              <Select
                native
                labelId="step_id"
                id="step_id"
                value={stepStateDetails.step_id}
                onChange={(e) => setstepStateDetails({...stepStateDetails, step_id:e.target.value }) }
              >
                {/* <MenuItem value={''}>Select Step</MenuItem>
                {stepListDropdown.stepListDropdown.map(item =>
                  <MenuItem value={item.step_id}>{item.name}</MenuItem>
                )} */}
                <option value="" style={{color:'grey'}}>Select Step</option>
                  {stepListDropdown.stepListDropdown.map(item =>
                    <option value={item.step_id}>{item.name}</option>
                )}
              </Select>
            </FormControl>
            <div className={classes.validation}>{(stepStateDetails.step_id)?(<div></div>):(stepIdErr)}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="state_id">State*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>State*</Grid>
              <Select
                native
                labelId="state_id"
                id="state_id"
                value={stepStateDetails.state_id}
                onChange={(e) => setstepStateDetails({...stepStateDetails, state_id:e.target.value }) }
              >
                {/* <MenuItem value={''}>Select State</MenuItem>
                {stateListDropdown && stateListDropdown.map(item =>
                  <MenuItem value={item.state_id}>{item.name}</MenuItem>
                )} */}
                <option value="" style={{color:'grey'}}>Select State</option>
                  {stateListDropdown && stateListDropdown.map(item =>
                    <option value={item.state_id}>{item.name}</option>
                )}
              </Select>
            </FormControl>
            <div className={classes.validation}>{(stepStateDetails.state_id)?(<div></div>):(stateIdErr)}</div>

            <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
                <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handelSubmitCreateStepState}
                >
                Create
                </Button>
                <div style={{marginLeft:'2%'}}></div>
                <Button
                    type="submit"
                    variant="contained"
                    // color="grey"
                    className={classes.submit}
                    onClick={handelCreateStepStateClear}
                >
                Clear
                </Button>
            </div>
        </div>
        </div>
    )

    const handelStepStatesBulkOpen = () => {
      setopenBulk(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelStepStatesBulkClose = () => {
      setopenBulk(false);
      setExcelFile();
      setExcelName();
      setfileErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelStepStatesBulkClear = () => {
      setExcelFile();
      setExcelName();
      setfileErr();
    }

    const handelStepStatesBulkMinimize = () => {
      setopenBulk(false);
      dispatch(userActions.checkSessionValidity());
    }

    const onUploadExcel= (event) => {
        console.log("event.target.files[0]",event.target.files[0])
        console.log("event.target.files[0] name",event.target.files[0].name)
        setExcelName(event.target.files[0].name);
        setExcelFile(event.target.files[0]);
        dispatch(userActions.checkSessionValidity());
    }

    const validateBulk = () => {
      let fileErr = '';

      if(!ExcelFile)  {
          fileErr = 'Please Select File';
      }

      if(fileErr ){
        setfileErr(fileErr);

        return false;
      }
      return true;
    }

    const handelSubmitStepStateBulk = () => {
      dispatch(userActions.checkSessionValidity());
      let users = JSON.parse(localStorage.getItem('users'));
      let username = users.data.user_details.username
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      // console.log("username in category bulk",username);
      // console.log("business_id in category bulk",business_id);
      // console.log("ExcelFile in category bulk",ExcelFile);

      const isValid = validateBulk();
      if(isValid){
        setShowProcedure(true);
        setopenBulk(false);
        let formdata = new FormData();
        formdata.append('business_id', business_id);
        formdata.append('username', username);
        formdata.append('excel_file', ExcelFile);
        let user = JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
          method: 'POST',
          headers:  {
            Authorization: "Token " + user.key,
          },
          body: formdata,
        };
        return fetch( process.env.REACT_APP_BULK_CREATE_STEP_STATES , requestOptions)
            .then((Response) => Response.json())
            .then( stepStatesBulk => {
                setShowProcedure(false);
                console.log("stepStatesBulk on same page",stepStatesBulk.success);
                  console.log("stepStatesBulk on same page",stepStatesBulk);
                  if(stepStatesBulk.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Step Stated Bulk Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    setExcelFile();
                    setExcelName();
                    setfileErr();
                  }
                  if(stepStatesBulk.success == false){
                    Swal.fire({
                      icon: 'error',
                      text: stepStatesBulk.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setopenBulk(true);
                    })
                }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
    }

    const bulkBody = (
        <div className={classes.paperModalBulk}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'40%'}}>Step States Bulk</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelStepStatesBulkMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelStepStatesBulkClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBodyBulk}>
            {/* <input type='file' 
              accept=".xlsx"
              id="myBulk"
              onChange={ (e) => onUploadExcel(e)} 
            /> */}
            <Grid container>
            <Grid item xs={3}>
              <input type='file' 
                // id="files"
                // accept=".xlsx"
                id="myBulk"
                onChange={ (e) => onUploadExcel(e)}  
                hidden
              />
              <label for="myBulk" style={{backgroundColor: '#cbd4cd', height:8, width:40, padding:10, borderRadius:5,
                cursor: "pointer"}}>Select file</label>
            </Grid>
            <Grid item xs={6} style={{marginLeft:'-2%'}}>
              {
                ( ExcelName)?
                (
                  <div >
                    {/* <a 
                      href={this.state.BiographyResumeFileName} target = "_blank"> */}
                      <text>{ExcelName}</text>
                    {/* </a> */}
                  </div>
                ):
                (
                  <div>
                    No file Choosen 
                  </div>
                )
              }
            </Grid>         
          </Grid>
          <div className={classes.validationBulk}>{(ExcelFile)?(<div></div>):(fileErr)}</div>

          <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handelSubmitStepStateBulk}
            >
              Create Bulk
            </Button>
            <div style={{marginLeft:'2%'}}></div>
            <Button
              type="submit"
              variant="contained"
              className={classes.submit}
              onClick={handelStepStatesBulkClear}
            >
              Clear
            </Button>
          </div>
        </div>
        </div>
    )

    const handelStatesBulkOpen = () => {
      setopenBulkSecond(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelStatesBulkClose = () => {
      setopenBulkSecond(false);
      setExcelNameSecond();
      setExcelFileSecond();
      setfileErrSecond();
      dispatch(userActions.checkSessionValidity());
    }

    const onUploadExcelSecond= (event) => {
        console.log("event.target.files[0]",event.target.files[0])
        console.log("event.target.files[0] name",event.target.files[0].name)
        setExcelNameSecond(event.target.files[0].name);
        setExcelFileSecond(event.target.files[0]);
        dispatch(userActions.checkSessionValidity());
    }

    const validateBulkSecond = () => {
      let fileErrSecond = '';

      if(!ExcelFileSecond)  {
          fileErrSecond = 'Please Select File';
      }

      if(fileErrSecond ){
        setfileErrSecond(fileErrSecond);

        return false;
      }
      return true;
    }

    const handelSubmitStatesBulk = () => {
      dispatch(userActions.checkSessionValidity());
      let users = JSON.parse(localStorage.getItem('users'));
      let username = users.data.user_details.username
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      // console.log("username in category bulk",username);
      // console.log("business_id in category bulk",business_id);
      // console.log("ExcelFileSecond in category bulk",ExcelFileSecond);

      const isValid = validateBulkSecond();
      if(isValid){
        setShowProcedure(true);
        setopenBulkSecond(false);
        let formdata = new FormData();
        formdata.append('business_id', business_id);
        formdata.append('username', username);
        formdata.append('excel_file', ExcelFileSecond);
        let user = JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
          method: 'POST',
          headers:  {
            Authorization: "Token " + user.key,
          },
          body: formdata,
        };
        return fetch( process.env.REACT_APP_BULK_CREATE_STATES , requestOptions)
            .then((Response) => Response.json())
            .then( statesBulk => {
                setShowProcedure(false);
                console.log("statesBulk on same page",statesBulk.success);
                  console.log("statesBulk on same page",statesBulk);
                  if(statesBulk.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "States Bulk Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    setExcelNameSecond();
                    setExcelFileSecond();
                    setfileErrSecond();
                  }
                  if(statesBulk.success == false){
                    Swal.fire({
                      icon: 'error',
                      text: statesBulk.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setopenBulkSecond(true);
                    })
                }             
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
    }

    const bulkBodySecond = (
        <div className={classes.paperModalBulk}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
            <h2>States Bulk</h2>
        </div>
        <div className={classes.modalBodyBulk}>
            {/* <input type='file' 
              accept=".xlsx"
              id="myBulk"
              onChange={ (e) => onUploadExcelSecond(e)} 
            /> */}
          <Grid container>
            <Grid item xs={3}>
              <input type='file' 
                // id="files"
                // accept=".xlsx"
                id="myBulk"
                onChange={ (e) => onUploadExcelSecond(e)}  
                hidden
              />
              <label for="myBulk" style={{backgroundColor: '#cbd4cd', height:8, width:40, padding:10, borderRadius:5,
                cursor: "pointer"}}>Select file</label>
            </Grid>
            <Grid item xs={6} style={{marginLeft:'-2%'}}>
              {
                ( ExcelNameSecond) ?
                (
                  <div >
                    {/* <a 
                      href={this.state.BiographyResumeFileName} target = "_blank"> */}
                      <text>{ExcelNameSecond}</text>
                    {/* </a> */}
                  </div>
                ):
                (
                  <div>
                    No file Choosen 
                  </div>
                )
              }
            </Grid>         
          </Grid>
          <div className={classes.validationBulk}>{(ExcelFileSecond)?(<div></div>):(fileErrSecond)}</div>

          <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handelSubmitStatesBulk}
            >
              Create Bulk
            </Button>
            <div style={{marginLeft:'2%'}}></div>
            <Button
              type="submit"
              variant="contained"
              className={classes.submit}
              onClick={handelStatesBulkClose}
            >
              Cancel
            </Button>
          </div>
        </div>
        </div>
    )

  return(
    <Grid item xs={4}>
        <div>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        </div>
        <Paper className={classes.paper}>
            <Grid container>
                <Grid item xs={1} className={classes.moreVertIcon}>
                    <PersonIcon className={classes.personIconColor}/>
                </Grid>
                <Grid item xs={6} style={{marginLeft:'2%', color:'black'}}>
                    Step States List
                </Grid>
            </Grid>
            <hr/>
            <Grid container>
                <Grid item xs={12} className={classes.userAddUpdateDelete}>
                  <Link to="StepStateList" style={{ textDecoration: 'none', color:'black' }}>
                    Step State List
                  </Link>                   
                </Grid>
                <Grid item xs={12} onClick={handelCreateStepStateOpen} className={classes.userAddUpdateDelete}>
                    Create Step State
                </Grid>
                <Modal
                    open={open}
                    onClose={handelCreateStepStateClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>
                <Grid item xs={12} onClick={handelStepStatesBulkOpen} className={classes.userAddUpdateDelete}>
                    Bulk Create Step States
                </Grid>
                <Modal
                    open={openBulk}
                    onClose={handelStepStatesBulkClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                  {bulkBody}
                </Modal>
                {/* <Grid item xs={12} onClick={handelStatesBulkOpen} className={classes.userAddUpdateDelete}>
                    Bulk Create States
                </Grid> */}
                <Modal
                    open={openBulkSecond}
                    onClose={handelStatesBulkClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                  {bulkBodySecond}
                </Modal>
            </Grid>
        </Paper>
    </Grid>
  )
}
