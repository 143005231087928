import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Grid from "@material-ui/core/Grid";
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import Avatar from "@material-ui/core/Avatar";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { useSelector, useDispatch } from 'react-redux';
import useCheckSessionApi from "../SessionValidity";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  intl: PropTypes.shape.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
    
  },
  tableWrapper:{
    boxShadow: 'none',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: '0px',
  },
  tableContainer: {
    marginTop: "7%",
  },
  tableHeaderBar: {
    marginBottom: "1%",
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
  },
  leftAlign: {
    textAlign: 'left'
  },
  rightAlign: {
    textAlign: 'right'
  },
  verticalAlignTop: {
    verticalAlign: 'top'
  },
  chipColor: {
    background: 'rgba(77, 71, 221, 0.16)'
  },
  sortByClasses: {
    padding: 0,
    lineHeight: 0,
    textTransform: 'none',
    color: 'rgba(0, 0, 0, 0.64)',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  hover: {
    cursor: "pointer",
  }
});

function TaxManagementClientTable({ intl, handleChange, clientList }) {
  console.log(clientList);
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const [flag , setflag] = React.useState(false)
  const [DeviceToken, setDeviceToken] =   React.useState();
  const [SessionId, setSessionId] =   React.useState();
  const deviceTokenFromRedux = useSelector(state => state.loginDetails.deviceToken);
  const sessionIdFromRedux = useSelector(state => state.loginDetails.sessionId);
  let user = JSON.parse(localStorage.getItem('user'));
  const [data, checkSessionValidity] = useCheckSessionApi({
    url: process.env.REACT_APP_CHECK_SESSION_VALIDITY ,
    options: {
      method: "POST",
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + user.key,
      },
      body: JSON.stringify({
        device_token: deviceTokenFromRedux,
        session_id: sessionIdFromRedux,
      }),
    },
  });

  useEffect(() => {
    setflag(false);
    // localStorage.setItem('view_clients_active_cases', []);
    localStorage.removeItem('view_clients_active_cases');
    readItemFromStorage();
  }, [flag]);

  const readItemFromStorage = async() => {
      try {
      setDeviceToken(deviceTokenFromRedux)
      setSessionId(sessionIdFromRedux)
      setflag(false)    
      } catch (error) {
          console.log("ERROR:",error);
      }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, clientList.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    let valueHere = parseInt(event.target.value, 10)
    let valueToCompare = JSON.stringify(valueHere)
    if( valueToCompare === "null" ){
      setRowsPerPage(0);
    }
    else{
      setRowsPerPage(parseInt(event.target.value, 10));
    }
    setPage(0);
  };

  return (
    <div className={classes.tableContainer}>
      <Grid container className={classes.tableHeaderBar}>
        <Grid item xs={6} className={classes.leftAlign}>
          <FormattedMessage id="clientDataLabel" />
        </Grid>
        <Grid item xs={6} className={classes.rightAlign}>
        <Button
          ref={anchorRef}
          className={classes.sortByClasses}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
        <FormattedMessage id="sortByRecentLabel" />
        <span className={classes.verticalAlignTop}>
          <ExpandMoreIcon />
          </span>
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={handleClose}><FormattedMessage id="clientPan" /></MenuItem>
                    <MenuItem onClick={handleClose}><FormattedMessage id="clientName" /></MenuItem>
                    <MenuItem onClick={handleClose}><FormattedMessage id="assignedhourLabel" /></MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        </Grid>
      </Grid>
      <TableContainer className={classes.tableWrapper} component={Paper}>
        <Table
          size={"small"}
          className={classes.table}
          aria-label="custom pagination table"
        >
          <TableBody>
            {(rowsPerPage > 0
              ? clientList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : clientList
            ).map((row) => (
              <TableRow key={row.name} onClick={handleChange} hover={true} classes={{hover: classes.hover}} >
                <TableCell key={row.avatarId} align="right">
                  <Avatar src="/static/images/avatar/1.jpg" />
                </TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="right">{row.client_pan}</TableCell>
                <TableCell align="right">
                  <FormattedMessage id="assignedLabel" />
                  {"2"}
                  <FormattedMessage id="hoursAgoLabel" />
                </TableCell>
                <TableCell key={row.client_pan} align="right">
                  <MoreVertIcon />
                </TableCell>
              </TableRow>
            ))}

            {/* {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: clientList.length }]}
                colSpan={8}
                count={clientList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                intl={intl}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

export default injectIntl(TaxManagementClientTable);
