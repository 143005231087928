import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../actions";
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  pageHeaderContainer: {
    marginBottom: "5%",
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  feedbackDescription: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "15px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  CategoryLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
    // marginLeft: "2%",
    // marginTop: "1%",
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    textAlign: "left",
    width: '100%',
    color: theme.palette.text.secondary,
    marginTop: '-1%',
  },
  profileImgContainer: {
    // display: 'flex',
    justifyContent: 'center',
    marginTop: '2%',
  },
  profileImg: {
    // size:'600%',
    // height: '80%',
    // width: '20%',
    width: '700px',
    height: '500px',
    // borderRadius: '50%',
  },
}));

export default function FeedbackImages() {
  const classes = useStyles();
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('selected_business_id')));
  const [ShowProcedure, setShowProcedure] = React.useState(false)
  const [Feedbacks, setFeedbacks] = React.useState();
  const [FeedbackImageToShow, setFeedbackImageToShow] = React.useState();

  const dispatch = useDispatch();

  React.useEffect(() => {
    setShowProcedure(true);
    dispatch(userActions.checkSessionValidity());

    let Feedback_Details_Here = JSON.parse(localStorage.getItem('selected_Feedback_Details'));
    console.log("Feedback_Details_Here", Feedback_Details_Here.attachment)
    setFeedbacks(Feedback_Details_Here)

    getFeedbackPicture()

    setShowProcedure(false);
    
  }, []);

  const getFeedbackPicture = () => {
    let Feedback_Details_Here = JSON.parse(localStorage.getItem('selected_Feedback_Details'));
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'business_id': business_id, 'doc_file_path': Feedback_Details_Here.attachment,
        'device_token': device_token, 'session_id': session_id,
      })
    };
    return fetch(process.env.REACT_APP_GET_DOWNLOAD_INFO, requestOptions)
    .then((Response) => Response.json())
    .then(DownloadInfo => {
      if (DownloadInfo.success == true) {
        console.log("DownloadInfo in Feedback is", DownloadInfo)
        setFeedbackImageToShow(DownloadInfo.data.download_info)
      }
      else{
        setFeedbackImageToShow("")
        Swal.fire({
          icon: 'error',
          text: DownloadInfo.errors,
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      }
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        text: "Something went wrong",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    })
  }

  const HandleImageClick = (FeedbackImageToShow) => {
    console.log("Image is clicked", Feedbacks.attachment, FeedbackImageToShow)
    // return (
      // <a
      //   // href={item.user_uploaded.document}
      //   // href={ process.env.REACT_APP_MEDIA +item.user_uploaded.document}
      //   // href={Feedbacks.attachment}
      //   href={FeedbackImageToShow}
      //   target="_blank"
      // >
      //   {/* <CloudDownloadIcon style={{color:'black'}}/> */}
      // </a>

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          'business_id': business_id, 'doc_file_path': Feedbacks.attachment,
          'device_token': device_token, 'session_id': session_id,
        })
      };
      return fetch(process.env.REACT_APP_GET_DOWNLOAD_INFO, requestOptions)
      .then((Response) => Response.json())
      .then(DownloadInfo => {
        setShowProcedure(false);
        if (DownloadInfo.success == true) {
          console.log("DownloadInfo is", DownloadInfo)
          window.open(DownloadInfo.data.download_info, "_blank");
        }
        else{
          Swal.fire({
            icon: 'error',
            text: DownloadInfo.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Something went wrong",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      })
    // )
  }

  return (
    console.log("Feedback_Details_Here in feedback images", Feedbacks && Feedbacks.attachment),
    <div className={classes.root}>
      {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
        <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
      </modal> : null}
      <div >
        <Grid container className={classes.pageHeaderContainer} style={{ marginTop: '2%' }}>
          <Grid item xs={12}>
            {/* <Grid> */}
            {/* <Grid container className={classes.pageHeaderContainer} style={{marginTop:'2%'}}> */}
            {/* <Grid item xs={10} className={classes.pageHeaderLabel}> */}
            {/* Feedback Images:: */}
            {/* </Grid> */}
            {/* </Grid> */}
            {/* </Grid> */}
            <Grid item xs={10} className={classes.pageHeaderLabel}>
              Feedback Image
            </Grid>
            <Grid item xs={12} className={classes.profileImgContainer} style={{cursor:"pointer"}} >
              <div style={{ maxWidth: '100%', wordBreak: 'break-all' }}>
                <img
                  onClick={() => HandleImageClick(FeedbackImageToShow)}
                  // class="fit-picture"
                  className={classes.profileImg}
                  // src={Feedbacks && Feedbacks.attachment}
                  src= {FeedbackImageToShow && FeedbackImageToShow}
                // alt="Grapefruit slice atop a pile of other slices" 
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}