import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../../actions";
import useCheckSessionApi from "../../SessionValidity";
import moment from 'moment';

moment.defaultFormat = "DD/MM/YYYY HH:mm"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridContianer: {
    padding: theme.spacing(2),
  },
  leftAlign: {
    color: 'rgba(0, 0, 0, 0.32)',
    textAlign: 'left',
    marginLeft: '2%',
  },
  doneBy: {
    color: 'rgba(0, 0, 0, 0.32)',
    textAlign: 'left',
    fontSize: 12,
    marginLeft: '2%',
    // marginBottom: '1%'
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },

}));

export default function TaxManagementCasesActivity() {
  const classes = useStyles();
  const [ ShowProcedure, setShowProcedure ] = React.useState(false);
  const [caseForDetailsFromAsync, setcaseForDetailsFromAsync] = React.useState(JSON.parse(localStorage.getItem('selected_case_for_details')));
  const [caseDetailsActivity, setcaseDetailsActivity] = React.useState([]);

  const [flag, setflag] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setShowProcedure(true);
    setflag(true);
    dispatch(userActions.checkSessionValidity());
    // readItemFromStorage();
    // to fetch case details of steps
    let case_id = caseForDetailsFromAsync.case_id;
    let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
    let device_token = JSON.parse(localStorage.getItem('device_token'));
    let session_id = JSON.parse(localStorage.getItem('session_id'));

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'case_id': case_id, 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
    };
    // console.log("requestOptions",requestOptions)
    fetch( process.env.REACT_APP_GET_CASE_ACTIVITY , requestOptions)
      .then((Response) => Response.json())
      .then( async(caseActivityDetails) => {
        setShowProcedure(false);
        console.log("caseActivityDetails on show case details",caseActivityDetails);
        if(caseActivityDetails.success == true)
        {
          console.log("caseActivityDetails on show case details true",caseActivityDetails.data.case_activity);
          let case_activity = caseActivityDetails.data.case_activity ;
          // setcaseDetailsActivity(case_activity);
          // await setcaseDetailsActivity(
          //   case_activity.map((f) => {
          //     f.created = moment.utc(f.created , moment.defaultFormat).local().format('YYYY/MM/DD HH:mm');
          //     return f ;
          //   })
          // )
          await setcaseDetailsActivity(
            case_activity
          )
        }
        else if(caseActivityDetails.success == false){
          setcaseDetailsActivity([]);
          Swal.fire({
            icon: 'error',
            text: caseActivityDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 9000,
          })
        }
    })
    .catch(err => {
      setShowProcedure(false);
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });
  }, [flag]);


  // const readItemFromStorage = async() => {
  //   try {
  //     setDeviceToken(deviceTokenFromRedux)
  //     setSessionId(sessionIdFromRedux)
  //     setflag(false)    
  //   } catch (error) {
  //     console.log("ERROR:",error);        }
  // };

  const createdDateFunc = (date) => {
    console.log("date in created DateFunc is activity", date)

    let dateInString = JSON.stringify(date)
    console.log("date in straing is ", dateInString)

    var mydateIs = moment(dateInString, 'DD/MM/YYYY hh:mm A'); 
    // var mydateIs = moment(dateInString, 'YYYY/MM/DD hh:mm A'); 
    // var mydateIs = moment(dateInString, 'DD/MM/YYYY hh:mm A'); 
    console.log("formated date is ours",moment(mydateIs).format("YYYY/MM/DD hh:mm A"))
    // var myDateWithFormatIs = moment(mydateIs).format("MM/DD/YYYY hh:mm A")

    var myDateWithFormatIs = moment(mydateIs).format("MM/DD/YYYY hh:mm A")
    console.log("myDateWithFormatIs",myDateWithFormatIs)

    // console.log("date in format for  in table map is local", new Date(myDateWithFormatIs)) 

    // var local_date= moment.utc(myDateWithFormatIs).local().format('MM/DD/YYYY hh:mm A');
    // console.log("local_date using moments is",local_date)

    var local_date= moment.utc(myDateWithFormatIs).local().format('MM/DD/YYYY hh:mm A');
    console.log("local_date using moments is",local_date)
    
    var local_date_DayFirst= moment.utc(myDateWithFormatIs).local().format('DD/MMM/YYYY hh:mm A');
    console.log("local_date day first",local_date_DayFirst)


    // var forForNow= moment.utc(date, moment.defaultFormat).local().format('YYYY/MM/DD HH:mm');
    var forForNow= moment.utc(date, moment.defaultFormat).local().format('YYYY/MM/DD HH:mm');

    return(
      <Grid item container xs={12} className={classes.leftAlign} >
        {/* {'14 hours ago'} */}
        <Grid style={{fontSize:15 }}>
          {/* {date.split(" ")[0]} &nbsp; */}
          {/* {local_date} */}
          {/* {myDateWithFormatIs} */}
          {local_date_DayFirst}
        </Grid>
        <Grid style={{fontSize:13 , alignSelf:'center'}}> 
          {/* ({moment(date).fromNow(true)})  //true to remove ago word */}
    
          {/* ({moment(date).fromNow()}) */}
          ({moment(forForNow).fromNow()})
        </Grid>
      </Grid>
    )

  }

  return (
    console.log("caseForDetailsFromAsync on case overview page,caseDetailsActivity",caseForDetailsFromAsync,caseDetailsActivity),
    <div className={classes.root}>
      {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
       <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
      </modal>: null}
      <Grid container>
        <Grid item xs={12}>
          { 
            caseDetailsActivity.map((item, index) =>(
            <Grid container className={classes.gridContianer}>
              <Grid item xs={1} style={{alignSelf:'center'}}>
                <Avatar src="/static/images/avatar/1.jpg" />
              </Grid>
              <Grid item xs={11} >
                <Grid container>
                  <Grid item xs={12} className={classes.leftAlign}>
                    {item.message}
                  </Grid>
                  

                  {createdDateFunc(item.created)}

                  {/* <Grid item container xs={12} className={classes.leftAlign} > */}
                    {/* {'14 hours ago'}   //commented */}
                    {/* <Grid style={{fontSize:15 }}> */}
                      {/* {item.created.split(" ")[0]} &nbsp;    //commented */}
                      {/* {item.created} &nbsp; */}
                    {/* </Grid> */}
                    {/* <Grid style={{fontSize:13 , alignSelf:'center'}}>  */}
                      {/* ({moment(item.created).fromNow(true)})  //true to remove ago word    //commented */}
                      {/* {createdDateFunc(item. created)}   //commented */}
                      {/* ({moment(item.created).fromNow()}) */}
                    {/* </Grid> */}
                  {/* </Grid> */}

                  <Grid item xs={12} className={classes.doneBy}>
                    By: {item.user_full_name}
                  </Grid>

                </Grid>
              </Grid>
              <Grid item xs={12} style={{marginTop:'2%'}}>
                <Divider />
              </Grid>
            </Grid>
            ))
          }
        </Grid>
      </Grid>
    </div>
  );
}
