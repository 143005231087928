import React, {useEffect} from "react";
import { makeStyles, useTheme, lighten } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import InputLabel from '@material-ui/core/InputLabel';
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import useCheckSessionApi from "../SessionValidity";
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link , Route, Redirect, Switch} from 'react-router-dom';
import AntSwitch from '@material-ui/core/Switch';

import BusinessesList from "./BusinessesList";

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import TableSortLabel from '@material-ui/core/TableSortLabel';

import BusinessDashboard from '../BusinessDashboard';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(businessDetails, comparator) {
  const stabilizedThis = businessDetails && businessDetails.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, label: 'Name', },
  { id: 'shortcode', numeric: false, label: 'Shortcode',},
  { id: 'tan', numeric: false, label: 'PAN', },
  { id: 'actions', numeric: false, label: 'Actions', },
  // { id: 'contachName', numeric: false, label: 'Contact Name' ,},
  // { id: 'contactNumber', numeric: false, label: 'Contact Number' , },
  // { id: 'email', numeric: false, label: 'Email' ,},
];
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
      {headCells.map((headCell) => (
          ( headCell.id === "actions")?
            (
              <TableCell
                key={headCell.id}
                align="center"
                style={{fontWeight: 'bold', fontSize: 17,  width: "1%"}}
              >
                {headCell.label}
              </TableCell>
            )
            :(
              <TableCell
                key={headCell.id}
                // align={headCell.numeric ? 'center' : 'center'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                align="center"
                style={{fontWeight: 'bold', fontSize: 17,  width: "10%"}}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
           )
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
//   intl: PropTypes.shape.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: "10%",
    marginRight: "10%",
    marginTop: "8%",
    marginBottom: "3%",
  },
  pageHeaderLabel:{
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  AddNewBusinessLabel:{
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "right",
    cursor: "pointer",
    marginTop: '1.5%',
  },
   modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paper: {
    position: 'absolute',
    width: 500,
    // height: 650,
    // top:'2%',
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'10%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

}));

export default function Businesses(props) {
    const classes = useStyles();
    const [businessDetails, setbusinessDetails] = React.useState([]);
    const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
    const [flag , setflag] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    // const [modalStyle] = React.useState(getModalStyle);
    const [Name, setName] = React.useState();
    const [ShortCode, setShortCode] =  React.useState();
    const [Address, setAddress] =  React.useState();
    const [Website, setWebsite] =  React.useState();
    const [PAN, setPAN] =  React.useState();
    const [contactName, setcontactName] =  React.useState();
    const [contact, setcontact] =  React.useState();
    const [email, setemail] =  React.useState();

    const [nameErr, setnameErr] = React.useState();
    const [shortCodeErr, setshortCodeErr] = React.useState();
    const [addressErr, setaddressErr] = React.useState();
    // const [websiteErr, setwebsiteErr] = React.useState();
    const [panErr, setpanErr] = React.useState();

    const [openEditBusiness, setopenEditBusiness] = React.useState(false);
    const [EditBusinessId, setEditBusinessId] = React.useState();
    const [EditBusinessName, setEditBusinessName] = React.useState();
    const [EditBusinessAddress, setEditBusinessAddress] = React.useState();
    const [EditBusinessPan, setEditBusinessPan] = React.useState();
    const [EditBusinessWebsite, setEditBusinessWebsite] = React.useState();
    const [EditBusinessShortcode, setEditBusinessShortcode] = React.useState();
    const [EditBusinessIsActive, setEditBusinessIsActive] = React.useState();
    const [nameEditErr, setnameEditErr] = React.useState();
    const [shortCodeEditErr, setshortCodeEditErr] = React.useState();
    const [addressEditErr, setaddressEditErr] = React.useState();
    // const [websiteEditErr, setwebsiteEditErr] = React.useState();
    const [panEditErr, setpanEditErr] = React.useState();

    const [ShortCodeAlreadyExistErr, setShortCodeAlreadyExistErr] = React.useState("");

    // const [flag, setflag] = React.useState(false);
    // const [apiURL, setAPIURL] =  React.useState("https://api.trpflow.com");
    const [DeviceToken, setDeviceToken] =   React.useState();
    const [SessionId, setSessionId] =   React.useState();
    const deviceTokenFromRedux = useSelector(state => state.loginDetails.deviceToken);
    const sessionIdFromRedux = useSelector(state => state.loginDetails.sessionId);
    let user = JSON.parse(localStorage.getItem('user'));
    const [data, checkSessionValidity] = useCheckSessionApi({
        // url: apiURL + "/users/checksessionvalidity/",
        url: process.env.REACT_APP_CHECK_SESSION_VALIDITY,
        options: {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Token " + user.key,
        },
        // body: ({'device_token':deviceTokenFromRedux, 
        //       'session_id':sessionIdFromRedux}),
        body: JSON.stringify({
            device_token: deviceTokenFromRedux,
            session_id: sessionIdFromRedux,
        }),
        },
        props,
    });

    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(false);

    useEffect(() => {
      setflag(true);

        const requestOptions = {
          method: 'POST',
          // headers: { 'Content-Type': 'application/json' },
          headers: authHeader(),
          body: JSON.stringify({'device_token': device_token, 'session_id': session_id })
        };

        fetch( process.env.REACT_APP_GET_BUSINESSES , requestOptions)
        .then((Response) => Response.json())
        .then(businessesDetails => {
            console.log("businessesDetails respones from api",businessesDetails.data.businesses);
            if(businessesDetails.data.businesses)
            {
                setbusinessDetails(businessesDetails.data.businesses)
                // localStorage.setItem('businessesDetails', JSON.stringify(businessesDetails.data.businesses));
            }
            if(businessesDetails.success == false){
              setbusinessDetails([])
                Swal.fire({
                icon: 'error',
                text: businessesDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

        // readItemFromStorage()
    }, [flag]);

    const readItemFromStorage = async() => {
        try {
        setDeviceToken(deviceTokenFromRedux)
        setSessionId(sessionIdFromRedux)
        setflag(false)    
        } catch (error) {
            console.log("ERROR:",error);
        }
    };

     // sort
     const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
  };

  // pagination
  const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, businessDetails && businessDetails.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
      setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    let valueHere = parseInt(event.target.value, 10)
    let valueToCompare = JSON.stringify(valueHere)
    if( valueToCompare === "null" ){
      setRowsPerPage(0);
    }
    else{
      setRowsPerPage(parseInt(event.target.value, 10));
    }
    setPage(0);
  };
  const handleChangeDense = (event) => {
      setDense(event.target.checked);
  };

    const handelAddNewBusinessOpen = () => {
        setOpen(true);
    }

    const handelAddNewBusinessClose = () => {
        setOpen(false);
        setnameErr();
        setshortCodeErr();
        setaddressErr();
        // setwebsiteErr();
        setpanErr();
    };

    const handelAddNewBusinessMinimize= () => {
      setOpen(false);
    }

    const handelAddNewBusinessReset = async() => {
      await setName("");
      await setShortCode("");
      await setAddress("");
      await setWebsite("");
      await setPAN("");

      await setnameErr("");
      await setshortCodeErr("");
      await setaddressErr("");
      await setpanErr("");
    }

    const handleChange = (e) => {
        console.log("in handel change",e.target)
        console.log("in handel change",e.target.value)
    }

    const validate = () => {
      console.log("in validate func")
      let nameErr = '';
      let shortCodeErr = '';
      let addressErr = '';
      // let websiteErr = '';
      let panErr = '';

      if(!Name)  {
          nameErr = 'Please Enter Name';
      }
      if(!ShortCode)  {
          shortCodeErr = 'Please Enter Short Code';
      }
      if(!Address)  {
          addressErr = 'Please Enter Address';
      }
      // if(!Website)  {
      //     websiteErr = 'Please Enter Website';
      // }

      if(!PAN)  {
          panErr = 'Please Enter PAN';
          console.log("in blank pan")
      }
      // let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
      else if (typeof PAN !== "undefined") {
        var pattern = new RegExp(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/)
        if(PAN.length !== 10){
          console.log("in != 10")
          // if(PAN.length < 10){
          //   panErr = "PAN Number Contains Less than 10 Letters";
          // }
          // else if(PAN.length > 10){
          //   panErr = "PAN Number Contains More than 10 Letters";
          // }
          panErr = "PAN Number is Not Valid";
        }
        else if (!pattern.test(PAN)) {
          console.log("in string check")
          panErr = "PAN Number is Not Valid";
        } 
      }
      else{
            setpanErr();
      }

      if(nameErr || addressErr || panErr || shortCodeErr){
        setnameErr(nameErr);
        setshortCodeErr(shortCodeErr);
        setaddressErr(addressErr);
        // setwebsiteErr(websiteErr);
        setpanErr(panErr);
        return false;
      }
      return true;
    }

    const refreshBusinesses = () => {
      const requestOptions = {
        method: 'POST',
        // headers: { 'Content-Type': 'application/json' },
        headers: authHeader(),
        body: JSON.stringify({'device_token': device_token, 'session_id': session_id })
      };

      fetch( process.env.REACT_APP_GET_BUSINESSES , requestOptions)
      .then((Response) => Response.json())
      .then(businessesDetails => {
          console.log("businessesDetails respones from api",businessesDetails.data.businesses);
          if(businessesDetails.data.businesses)
          {
              setbusinessDetails(businessesDetails.data.businesses)
          }
          if(businessesDetails.success == false){
              Swal.fire({
              icon: 'error',
              text: businessesDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
          })
          }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    }

    const handelSubmitCreateNewBusiness = () => {
      const isValid = validate();
      if(isValid){
        setnameErr("");
        setshortCodeErr("");
        setaddressErr("");
        setpanErr("");
        if( ShortCodeAlreadyExistErr == "" ){
          let device_token = JSON.parse(localStorage.getItem('device_token'));
          let session_id = JSON.parse(localStorage.getItem('session_id'));
          const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify({'name': Name,'shortcode': ShortCode, 'address': Address, 'website': Website,
                  'tan': PAN, 'device_token': device_token, 'session_id': session_id })
          };
          return fetch( process.env.REACT_APP_CREATE_BUSINESS , requestOptions)
            .then((Response) => Response.json())
            .then( businessAdded => {
                  console.log("businessAdded on same page",businessAdded.success);
                    console.log("businessAdded on same page",businessAdded);
                    if(businessAdded.success == true){
                      Swal.fire({
                        icon: 'success',
                        text: "Business registered successfully!",
                        confirmButtonColor: 'primary',
                        confirmButtonText: 'OK',
                        timer: 5000,
                      })
                      setOpen(false);
                      setName();
                      setShortCode();
                      setAddress();
                      setWebsite();
                      setPAN();
  
                      setnameErr();
                      setshortCodeErr();
                      setaddressErr();
                      // setwebsiteErr();
                      setpanErr();
                    }
                    if(businessAdded.success == false){
                      setOpen(true);
                      Swal.fire({
                        icon: 'error',
                        text: businessAdded.errors,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK',
                        timer: 5000,
                      })
                  }
                
                setflag(false);
                refreshBusinesses();
              })
              .catch(err => {
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Server Error. Please try again.",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              });
        }
        else{
          Swal.fire({
            // icon: 'error',
            // text: "Something went wrong",
            text: "Please change the shortcode",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      }
    }

    const handleChangeShortcode = async(value) => {
      await setShortCode(value);

      if ( value !== "" ){
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify({'shortcode': value, 'entity_type': "business",
          'device_token': device_token, 'session_id': session_id })
        };
        return fetch( process.env.REACT_APP_SHORTCODE_VALIDATION, requestOptions)
        .then((Response) => Response.json())
        .then( checkShortcode => {
          console.log("checkShortcode is valid or not",checkShortcode)
          console.log("checkShortcode is valid or not",checkShortcode.data.is_valid)
          if( checkShortcode.success == true ){
            if( checkShortcode.data.is_valid == false ){
              setShortCodeAlreadyExistErr("Short code already Exist")
            }
            else if( checkShortcode.data.is_valid == true ){
              setShortCodeAlreadyExistErr("")
            }
          }
        })
      }
    }

    const body = (
        <div className={classes.paper}>
        {/* <h2 className={classes.addNewBusinessHeader}>Add New Business</h2> */}
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          {/* <h2>Add New Business</h2> */}
          <Grid item xs={10} >
            <h2 style={{marginLeft:'38%'}}>Add New Business</h2>
          </Grid>
          <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
            <MinimizeIcon onClick={()=>handelAddNewBusinessMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
          <Grid item xs={1} style={{marginTop:'1%',}}>
            <CloseIcon onClick={()=>handelAddNewBusinessClose()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
        </div>
        <div className={classes.modalBody}>
            <TextField
            //   variant="outlined"
              margin="normal"
              required
              fullWidth
              id="Name"
              label="Name"
              name="Name"
              autoComplete="Name"
              autoFocus
              value={Name}
              onChange={(e) => setName(e.target.value)}
            />
            <div className={classes.validation}>{(Name)?(<div></div>):(nameErr)}</div>
            <TextField
              margin="normal"
              required
              fullWidth
              id="PAN"
              label="PAN"
              name="PAN"
              autoComplete="PAN"
              value={PAN}
              placeholder="XXXXX0000X"
              inputProps={{ maxLength: 10, style: { textTransform: "uppercase" } }}
              onChange={(e) => setPAN(e.target.value.toUpperCase())}
            />
            {/* <div className={classes.validation}>{(PAN)?(<div></div>):(panErr)}</div> */}
            <div className={classes.validation}>{panErr}</div>
            <TextField
              margin="normal"
              required
              fullWidth
              id="Address"
              label="Address"
              name="Address"
              autoComplete="Address"
              value={Address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <div className={classes.validation}>{(Address)?(<div></div>):(addressErr)}</div>

            {/* <TextField
              margin="normal"
              required
              fullWidth
              id="contactName"
              label="Contact Name"
              name="contactName"
              autoComplete="contactName"
              value={contactName}
              onChange={(e) => setcontactName(e.target.value)}
            /> */}
            {/* <div className={classes.validation}>{(contactName)?(<div></div>):(contactNameErr)}</div> */}
            {/* <TextField
              margin="normal"
              required
              fullWidth
              id="contact"
              label="Contact Number"
              name="contact"
              autoComplete="contact"
              value={contact}
              onChange={(e) => setcontact(e.target.value)}
            /> */}
            {/* <div className={classes.validation}>{(contact)?(<div></div>):(contactErr)}</div> */}
            {/* <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Id"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setemail(e.target.value)}
            /> */}
            {/* <div className={classes.validation}>{(email)?(<div></div>):(emailErr)}</div> */}
            
            <TextField
              margin="normal"
              required
              fullWidth
              id="ShortCode"
              label="Short Code"
              name="ShortCode"
              autoComplete="ShortCode"
              value={ShortCode}
              // onChange={(e) => setShortCode(e.target.value)}
              onChange={(e) => handleChangeShortcode(e.target.value)}
            />
            <div className={classes.validation}>{(ShortCode)?(<div></div>):(shortCodeErr)}</div>
            <div className={classes.validation}>{ShortCodeAlreadyExistErr}</div>
            
            <TextField
              margin="normal"
              // required
              fullWidth
              id="Website"
              label="Website"
              name="Website"
              autoComplete="Website"
              value={Website}
              onChange={(e) => setWebsite(e.target.value)}
            />
            {/* <div className={classes.validation}>{(Website)?(<div></div>):(websiteErr)}</div> */}
            

            <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
                <Button
                type="submit"
                //   fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handelSubmitCreateNewBusiness}
                >
                Register
                </Button>
                <div style={{marginLeft:'2%'}}></div>
                <Button
                    type="submit"
                    //   fullWidth
                    variant="contained"
                    className={classes.submit}
                    onClick={handelAddNewBusinessReset}
                >
                Reset
                </Button>
            </div>
        </div>
        </div>
    );

     // mu function
     const handleNameClick = async(business_id) => {
        console.log("item id",business_id);
        await localStorage.setItem('selected_business_id', JSON.stringify(business_id));
        // setredirect(true);
        // setbusiness_id(business_id);
        //  <Switch>
        //     {/* <Route exact path="/BusinessDashboard"> */}
        //       <Redirect to="/BusinessDashboard" />
        //     {/* </Route> */}
        // </Switch>
    }

    const handleClickEditBusiness = (editBusinessDetails) => {
      console.log("in Edit Business",editBusinessDetails)
      setEditBusinessId(editBusinessDetails.business_id);
      setEditBusinessName(editBusinessDetails.name);
      setEditBusinessAddress(editBusinessDetails.address);
      setEditBusinessPan(editBusinessDetails.tan);
      setEditBusinessWebsite(editBusinessDetails.website);
      setEditBusinessShortcode(editBusinessDetails.shortcode);
      setEditBusinessIsActive(editBusinessDetails.is_active)
      handelEditBusinessOpen();
    }

    const handelEditBusinessOpen = () =>{
      setopenEditBusiness(true);
    }

    // const handelEditBusinessMinimize = () => {
    //   setopenEditBusiness(false);
    // } 

    const handelEditBusinessClose = () => {
      setopenEditBusiness(false);
      setEditBusinessName("");
      setEditBusinessAddress("");
      setEditBusinessPan("");
      setEditBusinessWebsite("");
      setEditBusinessShortcode("");
      setEditBusinessIsActive("");
      setnameEditErr();
      setshortCodeEditErr();
      setaddressEditErr();
      // setwebsiteEditErr();
      setpanEditErr();
    }

    const handelEditBusinessReset = async() => {
      // setEditBusinessId();
      await setEditBusinessName("");
      await setEditBusinessAddress("");
      await setEditBusinessPan("");
      await setEditBusinessWebsite("");
      await setEditBusinessShortcode("");
      await setEditBusinessIsActive("");

      await setnameEditErr();
      await setshortCodeEditErr();
      await setaddressEditErr();
      // await setwebsiteEditErr();
      await setpanEditErr();
    }

    const validateEditBusiness = () => {
      console.log("in validate func")
      let nameEditErr = '';
      let shortCodeEditErr = '';
      let addressEditErr = '';
      // let websiteEditErr = '';
      let panEditErr = '';

      if(!EditBusinessName)  {
        nameEditErr = 'Please Enter Name';
      }
      if(!EditBusinessShortcode)  {
        shortCodeEditErr = 'Please Enter Short Code';
      }
      if(!EditBusinessAddress)  {
        addressEditErr = 'Please Enter Address';
      }
      // if(!EditBusinessWebsite)  {
      //     websiteErr = 'Please Enter Website';
      // }

      if(!EditBusinessPan)  {
        panEditErr = 'Please Enter PAN';
          console.log("in blank pan")
      }
      else if (typeof EditBusinessPan !== "undefined") {
        var pattern = new RegExp(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/)
        if(EditBusinessPan.length !== 10){
          console.log("in != 10")
          panEditErr = "PAN Number is Not Valid";
        }
        else if (!pattern.test(EditBusinessPan)) {
          console.log("in string check")
          panEditErr = "PAN Number is Not Valid";
        } 
      }
      else{
            setpanEditErr();
      }

      if(nameEditErr || addressEditErr || panEditErr || shortCodeEditErr){
        setnameEditErr(nameEditErr);
        setshortCodeEditErr(shortCodeEditErr);
        setaddressEditErr(addressEditErr);
        // setwebsiteErr(websiteErr);
        setpanEditErr(panEditErr);
        return false;
      }
      return true;
    }

    
    const handelSubmitEditBusiness = () => {
      const isValid = validateEditBusiness();
      if(isValid){
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify({ 'business_id': EditBusinessId,'name': EditBusinessName,
            'shortcode': EditBusinessShortcode, 'address': EditBusinessAddress,'website': EditBusinessWebsite,
          'tan': EditBusinessPan, 
          'is_active': EditBusinessIsActive,
          'device_token': device_token, 'session_id': session_id })
        };
        return fetch( process.env.REACT_APP_CREATE_BUSINESS , requestOptions)
          .then((Response) => Response.json())
          .then( businessAdded => {
                console.log("businessAdded on same page",businessAdded.success);
                  console.log("businessAdded on same page",businessAdded);
                  if(businessAdded.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Business Edited successfully!",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    setopenEditBusiness(false);
                    setEditBusinessId();
                    setEditBusinessName();
                    setEditBusinessAddress();
                    setEditBusinessPan();
                    setEditBusinessWebsite();
                    setEditBusinessShortcode();
                    setEditBusinessIsActive();

                    setnameEditErr();
                    setshortCodeEditErr();
                    setaddressEditErr();
                    // setwebsiteEditErr();
                    setpanEditErr();
                  }
                  if(businessAdded.success == false){
                    setopenEditBusiness(true);
                    Swal.fire({
                      icon: 'error',
                      text: businessAdded.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                }
              setflag(false);
              refreshBusinesses();
            })
            .catch(err => {
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
    }

    const bodyEditBusiness = (
      <div className={classes.paper}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <Grid item xs={10} >
          <h2 style={{marginLeft:'42%'}}>Edit Business</h2>
        </Grid>
        {/* <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
          <MinimizeIcon onClick={()=>handelEditBusinessMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
        </Grid> */}
        <Grid item xs={1} style={{marginTop:'1%',}}>
          <CloseIcon onClick={()=>handelEditBusinessClose()} style={{fontSize: 20, cursor:'pointer'}}/>
        </Grid>
      </div>
      <div className={classes.modalBody}>
          <TextField
            //   variant="outlined"
              margin="normal"
              required
              fullWidth
              id="EditBusinessName"
              label="Edit Business Name"
              name="EditBusinessName"
              autoComplete="EditBusinessName"
              autoFocus
              value={EditBusinessName}
              onChange={(e) => setEditBusinessName(e.target.value)}
            />
          <div className={classes.validation}>{(EditBusinessName)?(<div></div>):(nameEditErr)}</div>

          <TextField
              margin="normal"
              required
              fullWidth
              id="EditBusinessPan"
              label="Edit Business Pan"
              name="EditBusinesPAN"
              autoComplete="EditBusinessPan"
              value={EditBusinessPan}
              placeholder="XXXXX0000X"
              inputProps={{ maxLength: 10, style: { textTransform: "uppercase" } }}
              onChange={(e) => setEditBusinessPan(e.target.value.toUpperCase())}
          />
          <div className={classes.validation}>{panEditErr}</div>

          <TextField
              margin="normal"
              required
              fullWidth
              id="EditBusinessAddress"
              label="Edit Business Address"
              name="EditBusinessAddress"
              autoComplete="EditBusinessAddress"
              value={EditBusinessAddress}
              onChange={(e) => setEditBusinessAddress(e.target.value)}
          />
          <div className={classes.validation}>{(EditBusinessAddress)?(<div></div>):(addressEditErr)}</div>

          <TextField
              margin="normal"
              required
              fullWidth
              id="EditBusinessShortcode"
              label="Edit Business Shortcode"
              name="EditBusinessShortcode"
              autoComplete="EditBusinessShortcode"
              value={EditBusinessShortcode}
              onChange={(e) => setEditBusinessShortcode(e.target.value)}
          />
          <div className={classes.validation}>{(EditBusinessShortcode)?(<div></div>):(shortCodeEditErr)}</div>

          <TextField
              margin="normal"
              // required
              fullWidth
              id="EditBusinessWebsite"
              label="Edit Business Website"
              name="EditBusinessWebsite"
              autoComplete="EditBusinessWebsite"
              value={EditBusinessWebsite}
              onChange={(e) => setEditBusinessWebsite(e.target.value)}
          />
          {/* <div className={classes.validation}>{(EditBusinessWebsite)?(<div></div>):(websiteEditErr)}</div> */}

          <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
              <Button
              type="submit"
              //   fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handelSubmitEditBusiness}
              >
                Update
              </Button>
              <div style={{marginLeft:'2%'}}></div>
              <Button
                  type="submit"
                  //   fullWidth
                  variant="contained"
                  className={classes.submit}
                  // onClick={handelEditBusinessReset}
                  onClick={handelEditBusinessClose}
              >
                Cancel
              </Button>
          </div>
      </div>
      </div>
  );

  // active inactive business
  const handleActiveInactiveBusiness = (item) => {
    console.log("handleActiveInactiveBusiness", item);

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({ 'business_id': item.business_id,'name': item.name,
        'shortcode': item.shortcode, 'address': item.address,
        'website': item.website,'tan': item.tan, 
        'is_active': !item.is_active,
        'device_token': device_token, 'session_id': session_id })
      };
    return fetch( process.env.REACT_APP_CREATE_BUSINESS , requestOptions)
      .then((Response) => Response.json())
      .then( businessAdded => {
            console.log("businessAdded on same page",businessAdded.success);
              console.log("businessAdded on same page",businessAdded);
              if(businessAdded.success == true){
                if(businessAdded.data.business_info.is_active == true){
                  Swal.fire({
                    icon: 'success',
                    text: "Business Activated",
                    confirmButtonColor: 'primary',
                    confirmButtonText: 'OK',
                    timer: 5000,
                  })
                }
                
                if(businessAdded.data.business_info.is_active == false){
                  Swal.fire({
                    icon: 'success',
                    text: "Business Inactivated",
                    confirmButtonColor: 'primary',
                    confirmButtonText: 'OK',
                    timer: 5000,
                  })
                }
              }
              if(businessAdded.success == false){
                setopenEditBusiness(true);
                Swal.fire({
                  icon: 'error',
                  text: businessAdded.errors,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
            }
          setflag(false);
          refreshBusinesses();
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
        });

  }

    return(
        // businessDetails = JSON.parse(localStorage.getItem('businessesDetails')),
        console.log("businessDetails on business index page",businessDetails),
        // console.log("businessDetails in show table index",JSON.parse(localStorage.getItem('businessesDetails'))),
        <div className={classes.root}>
        <div className={classes.wrapper}>
            <Grid container>
              <Grid item xs={8} className={classes.pageHeaderLabel} >
                <h2>Registered Businesses</h2>
              </Grid>
              <Grid item xs={4} className={classes.AddNewBusinessLabel}>
                {/* <h2>Add New Business</h2> */}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<AddIcon />}
                  // href="/newclient"
                  onClick={handelAddNewBusinessOpen}
                >
                  Register New Business
                </Button>
              </Grid>
              <Modal
                open={open}
                onClose={handelAddNewBusinessClose}
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                {body}
              </Modal>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
              {/* { businessDetails && <BusinessesList  businessDetails={businessDetails}/> } */}
              <TableContainer className={classes.tableWrapper} component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={businessDetails && businessDetails.length}
                    />
                    <TableBody>
                        {stableSort(businessDetails && businessDetails, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((businessDetails) => {
                          // console.log("row in table map",businessDetails)
                            return (
                            <TableRow 
                                key={businessDetails.business_id} 
                                hover={true} classes={{hover: classes.hover}}
                                // onRowClick={(event, rowData) => console.log("rowData",rowData)}
                                hover
                                tabIndex={-1}
                                align="center"
                            >
                                <TableCell 
                                    align="center"
                                    width="10%"
                                    component={Link} 
                                    to="/BusinessDashboard" 
                                    onClick={() => handleNameClick(businessDetails.business_id)}
                                >
                                {businessDetails.name}
                                </TableCell>
                                <TableCell width="10%" align="center">{businessDetails.shortcode}</TableCell>
                                <TableCell width="10%" align="center">{businessDetails.tan}</TableCell>
                                <TableCell width="5%" align="center" >
                                  <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => handleClickEditBusiness(businessDetails)}
                                    >
                                      Edit
                                  </Button>
                                  <AntSwitch 
                                    checked={businessDetails.is_active} 
                                    onChange={() => handleActiveInactiveBusiness(businessDetails)} 
                                    size="small" 
                                    name="item.is_active" 
                                    value="item.is_active"
                                    color="primary"
                                  />
                                </TableCell>
                                {/* <TableCell >{businessDetails.shortcode}</TableCell>
                                <TableCell >{businessDetails.address}</TableCell>
                                <TableCell >{businessDetails.website}</TableCell> */}
                                
                            </TableRow>
                            );
                        })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: businessDetails && businessDetails.length }]}
                                colSpan={8}
                                count={businessDetails && businessDetails.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                            </TableRow>
                        </TableFooter>
                </Table>
                </TableContainer>
              </Grid>
            </Grid>
        </div>
        <Modal
          open={openEditBusiness}
          onClose={handelEditBusinessClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyEditBusiness}
        </Modal>
        </div>
    )
}
