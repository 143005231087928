import React, { useState ,useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from "react-intl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from '@material-ui/core/Checkbox';
import { authHeader } from '../../helpers';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from "@material-ui/core/TextField";
import { PropagateLoader } from 'react-spinners';
import { Modal } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../../actions";
import useCheckSessionApi from "../../SessionValidity";
import Swal from 'sweetalert2';

import { addqsnListLevelThree } from "../../actions/updatecase.actions";

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    justifyContent: "flex-start",
    background: "#ffffff",
    padding: 10,
  },
  wrapper: {
    marginLeft: "3%",
    marginRight: "3%",
    marginBottom: "5%",
  },
  subHeadingLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.96)",
    marginTop: "25px",
    marginBottom: "10px",
    textAlign: "left",
  },
  marginRight: {
    marginRight: "4%",
  },
  textTransformNone: {
    textTransform: "none",
  },
  createStyle: {
    textTransform: "none",
    background: "#4D47DD",
    color: "#FFFFFF",
  },
  customRadioButton: {
    border: "1px solid rgba(0, 0, 0, 0.32)",
    borderRadius: "6px",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginRight: "20px",
  },
  label: {
    fontSize: "14px",
  },
  marginTop: {
    marginTop: "50px",
  },
  marginLeft: {
    marginLeft: "20px",
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  markQuesLineLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    // fontWeight: "600",
    fontSize: "10px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "right",
  },
}));

function EditCaseQuestionnaire3ClientCases({ handleDrawerClose, intl, handleClose, onNextQuestion, 
    onSelectQuestionLevelThree}) 
{
    const classes = useStyles();
    const [ ShowProcedure, setShowProcedure ] = React.useState(false);
    const [qsn_list,setQsn_list] = React.useState([]);
    const [options_list, setOptions_list] = useState([]);

    const [flag, setflag] = React.useState(false);

    // redux
    const dispatch = useDispatch();

    const newCaseCreatedCaseIdRedux = useSelector(state => state.updateCase.newCaseCreatedCaseId);
    const qsnListLevelThreeFromRedux = useSelector(state => state.updateCase.qsnListLevelThree);

    const addqsnListLevelThreeRedux = (qsnListLevelThree) => dispatch(addqsnListLevelThree(qsnListLevelThree));

    useEffect(() => {
        // setflag(true);
        dispatch(userActions.checkSessionValidity());
        // readItemFromStorage();
        setShowProcedure(true);
        let device_token = JSON.parse(localStorage.getItem('device_token'));
        let session_id = JSON.parse(localStorage.getItem('session_id'));
        // console.log("newCaseCreatedCaseIdRedux", newCaseCreatedCaseIdRedux);

        if(qsnListLevelThreeFromRedux !== undefined){
        //     console.log("length of qsnListLevelThreeFromRedux",qsnListLevelThreeFromRedux.length)
        //     console.log("Que_Details_for_set from redux fun",qsnListLevelThreeFromRedux)

        //     if( qsnListLevelThreeFromRedux.length == 0 ){
        //         const Que_Details_for_set_level_Two = {
        //             // case_id: JSON.parse(localStorage.getItem('created_case_case_id')),
        //             case_id: newCaseCreatedCaseIdRedux,
        //             level: 3,
        //             device_token: JSON.parse(localStorage.getItem('device_token')),
        //             session_id: JSON.parse(localStorage.getItem('session_id')),
        //         }
        //         console.log("Que_Details_for_set_level_Two",Que_Details_for_set_level_Two)
                
        //         const requestOptions = {
        //             method: 'POST',
        //             headers: authHeader(),
        //             body: JSON.stringify(Que_Details_for_set_level_Two)
        //         };

        //         fetch( process.env.REACT_APP_GET_CASE_QUESTIONS , requestOptions)
        //         .then((Response) => Response.json())
        //         .then( QuestionListLevelThreeDetails => {
        //             console.log("QuestionListLevelThreeDetails for list",QuestionListLevelThreeDetails);
        //             if(QuestionListLevelThreeDetails.success == true)
        //             {
        //                 setQsn_list(QuestionListLevelThreeDetails.data.question_info)
        //                 addqsnListLevelThreeRedux(qsn_list)
        //                 {navigateToQuestionSetTwo()};
        //                 // console.log("qsn_list is",qsn_list)
        //             }
        //             else{
        //                 setQsn_list([])
        //             }
        //         })
        //     }
        //     else if( qsnListLevelThreeFromRedux.length >= 1 ){
                console.log("Que_Details_for_set from redux fun length is greater than zero")
                setQsn_list(qsnListLevelThreeFromRedux)
                setShowProcedure(false);
                {navigateToQuestionSetTwo()};
                if(qsnListLevelThreeFromRedux.length == "0"){
                    onSelectQuestionLevelThree("4",qsnListLevelThreeFromRedux, "Yes Empty")
                }
            // }
        }
        else{
            const Que_Details_for_set_level_Two = {
                // case_id: JSON.parse(localStorage.getItem('created_case_case_id')),
                case_id: newCaseCreatedCaseIdRedux,
                level: 3,
                device_token: JSON.parse(localStorage.getItem('device_token')),
                session_id: JSON.parse(localStorage.getItem('session_id')),
                business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
            }
            console.log("Que_Details_for_set_level_Two",Que_Details_for_set_level_Two)
            
            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify(Que_Details_for_set_level_Two)
            };

            fetch( process.env.REACT_APP_GET_CASE_QUESTIONS , requestOptions)
            .then((Response) => Response.json())
            .then( QuestionListLevelThreeDetails => {
                setShowProcedure(false);
                console.log("QuestionListLevelThreeDetails for list",QuestionListLevelThreeDetails);
                if(QuestionListLevelThreeDetails.success == true)
                {
                    // setQsn_list(QuestionListLevelThreeDetails.data.question_info.filter(item => item.is_default == true ))
                    if(QuestionListLevelThreeDetails.data.question_info.length == "0"){
                        onSelectQuestionLevelThree("4",QuestionListLevelThreeDetails.data.question_info, "Yes Empty")
                    }
                    setQsn_list(QuestionListLevelThreeDetails.data.question_info)
                    addqsnListLevelThreeRedux(qsn_list)
                    {navigateToQuestionSetTwo()};
                    // console.log("qsn_list is",qsn_list)
                }
                else{
                    setQsn_list([])
                }
            })
            .catch(err => {
                setShowProcedure(false);
                Swal.fire({
                    icon: 'error',
                    //   text: "Something went wrong",
                    text: "Server Error. Please try again.",
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                })
              });
        }

    }, []);

    // const readItemFromStorage = async() => {
    //   try {
    //     setDeviceToken(deviceTokenFromRedux)
    //     setSessionId(sessionIdFromRedux)
    //     setflag(false)    
    //   } catch (error) {
    //       console.log("ERROR:",error);
    //   }
    // };

    const handleChangeBooleanAnswer = async(e, question_id, answer) => {
      console.log("e",e.target.value)
      console.log("question_id", question_id)
      console.log("answer", answer)

      await setQsn_list(
            qsn_list.map((f) => {
                if (f.question_id === question_id) 
                {
                    f.answer = JSON.parse(e.target.value)
                }
                return f;
            })
        );
        console.log("qsn_list",qsn_list);
        await addqsnListLevelThreeRedux(qsn_list)
        {navigateToQuestionSetTwo()};
    }

    const handleChangeSingleAnswerAnswer  = async(e, question_id, optionId) => {
      console.log("e",e.target.value)

      await setQsn_list(
            qsn_list.map((f) => {
            if (f.question_id === question_id)
            {
                // console.log("optin id : ",f.options); 
                for(var i = 0; i < f.options.length; i++){
                    if(f.options[i].option_id === optionId){
                        f.options[i].selected = true   
                        f.answer = f.options[i].option
                        f.single_answer = f.options[i].option              
                    }
                    else
                    {
                        f.options[i].selected = false
                    }
                }
            }
            return f;
        })
        );
        {navigateToQuestionSetTwo()};
    }

    const handleCheckSingleAnsw = async(queId, optionId) => {
    console.log("queId,checked:", queId,optionId);
    await setQsn_list(
        qsn_list.map((f) => {
            console.log("f : ",f);
            if (f.question_id === queId)
            {
                console.log("optin id : ",f.options); 
                for(var i = 0; i < f.options.length; i++){
                    if(f.options[i].option_id === optionId){
                        f.options[i].selected = true   
                        f.answer = optionId                 
                    }
                    else
                    {
                        f.options[i].selected = false
                    }
                }
            }
            return f;
        })
    );
    console.log("qsn_list",qsn_list);
    await addqsnListLevelThreeRedux(qsn_list)
    {navigateToQuestionSetTwo()};
  }

  const handleCheckSMultipleAnsw = async(queId, optionId, e) => {
      console.log("queId,checked:,e", queId,optionId,e.target.checked);
        await setQsn_list(
            qsn_list.map((f) => {
                if (f.question_id === queId)
                {
                    console.log("optin id : ",f.options); 
                    for(var i = 0; i < f.options.length; i++){
                        if(f.options[i].option_id === optionId){      
                            if( e.target.checked === true )   
                            {
                                console.log("in e.target.checked === true ")
                                f.options[i].selected = true
                                f.answer = "ok" 
                            } 
                            else if( e.target.checked === false )  
                            {
                                console.log("in e.target.checked === false ")
                                f.options[i].selected = false
                                f.answer = "NotOk" 
                            }    
                            // another logic for same if else as above  
                            // if(f.options[i].selected === true){
                            //     f.options[i].selected = false  
                            // }else {
                            //     f.options[i].selected = true 
                            // } 
                        }
                    }
                }
                return f;
                
            })
    );
    console.log("qsn_list",qsn_list);
    await addqsnListLevelThreeRedux(qsn_list)
    {navigateToQuestionSetTwo()};
  }

  const handleChangeTextAnswer = async( e, question_id ) => {
        console.log("e, question_id", e.target.value,question_id );
        await setQsn_list(
            qsn_list.map((f) => {
                if (f.question_id === question_id) {
                    f.answer = e.target.value
                }
                return f;
            })
        );
        console.log("qsn_list",qsn_list);
        await addqsnListLevelThreeRedux(qsn_list)
       {navigateToQuestionSetTwo()};
  }

  const navigateToQuestionSetTwo = () => {
        console.log("in a setQsn_Answer_list");
        console.log("qsn_list",qsn_list)
        addqsnListLevelThreeRedux(qsn_list)
        onSelectQuestionLevelThree("4",qsn_list,"")

  }

  return (
    <div className={classes.root}>
    {ShowProcedure ? <Modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
        </Modal>: null}
      <div className={classes.wrapper}>
            <Grid>
              <Grid className={classes.markQuesLineLabel}>
                All questions marked as star(*) are mandatory
              </Grid>
            </Grid>
        {
            addqsnListLevelThreeRedux(qsn_list),
            qsn_list.map((item, index) => (

                    ( item.question_type == "BOOLEAN" )?
                    (
                        <Grid container>
                            <Grid container item xs={12} className={classes.subHeadingLabel}>
                                {index+1}. {item.title} { item.is_optional == false ? <Grid>*</Grid> : null}
                            </Grid>
                            <Grid container item xs={12}>
                            <RadioGroup
                                row
                                aria-label="position"
                                name="position"
                                // value={item.answer}
                                defaultValue={JSON.stringify(item.answer)}
                                onChange={ (e) => handleChangeBooleanAnswer(e, item.question_id, item.answer)}
                            >                  
                                <div className={classes.customRadioButton}>
                                    <FormControlLabel
                                        value="true"
                                        classes={{ label: classes.label }}
                                        control={<Radio size="small" color="primary" />}
                                        // label= "True"
                                        label= "Yes"
                                    />
                                </div>
                                <div className={classes.customRadioButton}>
                                    <FormControlLabel
                                        value="false"
                                        classes={{ label: classes.label }}
                                        control={<Radio size="small" color="primary" />}
                                        // label= "False"
                                        label= "No"
                                    />
                                </div>
                            </RadioGroup>
                            </Grid>
                        </Grid> 
                    ):(
                        // (item.question_type == "SINGLE-ANSWER") ?
                        // (
                        //     <Grid container>
                        //         <Grid item xs={12} className={classes.subHeadingLabel}>
                        //             {item.title}
                        //         </Grid>
                        //         {                                
                        //             item.options.map(( o, j ) => (
                        //                 <Grid item xs={3}>                                          
                        //                     <FormControlLabel
                        //                         control={<Checkbox color="primary"/> }
                        //                         // checked={item.selected == true}
                        //                         checked={o.selected == true}  
                        //                         onChange={()=>handleCheckSingleAnsw(item.question_id, o.option_id)}                                                  
                        //                         name={o.option}                                                 
                        //                         label={o.option}
                        //                         value={o.option}
                        //                         labelPlacement="end"
                        //                     />                                          
                        //                 </Grid>
                        //             ))
                        //         }
                        //     </Grid> 
                        // ):
                        (item.question_type == "SINGLE-ANSWER") ?
                        (
                            <Grid container>
                                <Grid container item xs={12} className={classes.subHeadingLabel}>
                                    {index+1}. {item.title} { item.is_optional == false ? <Grid>*</Grid> : null}
                                </Grid>
                                <Grid container item xs={12}>
                                    {                                
                                        item.options.map(( o, j ) => (
                                            <RadioGroup
                                                row
                                                aria-label="position"
                                                name="position"
                                                // value={item.answer}
                                                // defaultValue={o.selected == true}
                                                // defaultValue={item.answer}
                                                // defaultValue={item.single_answer}
                                                value={o.selected == true ? o.option : null}
                                                onChange={ (e) => handleChangeSingleAnswerAnswer(e, item.question_id , o.option_id)}
                                            >   
                                                <div className={classes.customRadioButton}>
                                                    <FormControlLabel
                                                        value={o.option}     
                                                        classes={{ label: classes.label }}
                                                        control={<Radio size="small" color="primary" />}
                                                        label={o.option}     
                                                    />
                                                </div>
                                            </RadioGroup>
                                        ))
                                    }                                         
                                </Grid>
                            </Grid> 
                        ):
                        (
                            ( item.question_type == "MULTIPLE-ANSWER" )?
                            (
                                <Grid container>
                                <Grid container item xs={12} className={classes.subHeadingLabel}>
                                    {index+1}. {item.title} { item.is_optional == false ? <Grid>*</Grid> : null}
                                </Grid>
                                {                                
                                    item.options.map(( o, j ) => (
                                        <Grid item xs={3}>                                          
                                            <FormControlLabel
                                                control={<Checkbox color="primary"/> }
                                                // checked={item.selected == true}
                                                checked={o.selected == true}  
                                                onChange={(e)=>handleCheckSMultipleAnsw(item.question_id, o.option_id, e)}                                                  
                                                name={o.option}                                                 
                                                label={o.option}
                                                value={o.option}
                                                labelPlacement="end"
                                            />                                          
                                        </Grid>
                                    ))
                                }
                                </Grid> 
                            ):
                            (
                                ( item.question_type == "TEXT-ANSWER" )?
                                (
                                    <Grid container>
                                    <Grid container item xs={12} className={classes.subHeadingLabel}>
                                        {index+1}. {item.title} { item.is_optional == false ? <Grid>*</Grid> : null}
                                    </Grid>
                                    {                                
                                       <TextField
                                            // margin="normal"
                                            // required
                                            variant="outlined"
                                            fullWidth
                                            id="answer"
                                            // label="Answer"
                                            name="answer"
                                            autoComplete="answer"
                                            value={item.answer}
                                            onChange={(e) => handleChangeTextAnswer(e,item.question_id ) }
                                            // onChange={(e) => setQsn_list({...qsn_list, answer:e.target.value }) }
                                        />
                                    }
                                    </Grid>
                                ):
                                (<div></div>)
                            )
                        )
                    )
                
            ))
        }
        {/* */}
      </div>
    </div>
  );
}

export default injectIntl(EditCaseQuestionnaire3ClientCases);
